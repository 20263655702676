import { Grid } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { theme } from '@/theme';
import { styled } from '@mui/material/styles';
import { NAVIGATION_DRAWER_WIDTH } from '@components/layouts/DashboardLayout/NavigationDrawer/NavigationDrawer.style';

const OutletContainer = styled(Grid)`
  overflow: auto;
  width: calc(100% - ${NAVIGATION_DRAWER_WIDTH}px - ${theme.spacing(8)});
  position: relative;
  flex: 1;
`;

export const DashboardDetailsSublayout: FC<PropsWithChildren<unknown>> = () => {
  return (
    <OutletContainer item xs>
      <Outlet />
    </OutletContainer>
  );
};
