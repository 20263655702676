export enum Spacing {
  XXXLarge = 4, // 32px
  XXLarge = 3, // 24px
  XLarge = 2.5, // 20px
  Large = 2, // 16px
  Medium = 1.5, // 12px
  Default = 1, // 8px default
  Small = 0.5, // 4px
  None = 0, // 0px
}
