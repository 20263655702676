import { PASSWORD_COMPLEXITY_CONFIG } from '../../utils/constants';

export type TooltipTranslationKey =
  | 'tooltip.signup.password'
  | 'tooltip.signup.password-p1'
  | 'tooltip.signup.password-p2'
  | 'tooltip.signup.password-p3'
  | 'tooltip.signup.password-p4'
  | 'tooltip.signup.password-p5'
  | 'tooltip.chargers.smart-charge-enabled'
  | 'tooltip.chargers.charger-lock'
  | 'tooltip.charger-detail.connection-reliability'
  | 'tooltip.charger-detail.connection-reliability-0'
  | 'tooltip.charger-detail.connection-reliability-100'
  | 'tooltip.charger-detail.meter-reading'
  | 'tooltip.charger-detail.charge-current-rate'
  | 'tooltip.charger-detail.signal-strength'
  | 'tooltip.charger-detail.start-charging'
  | 'tooltip.charger-detail.stop-charging'
  | 'tooltip.charger-detail.reset'
  | 'tooltip.charger-detail.charging-sessions.start'
  | 'tooltip.charger-detail.charging-sessions.finish'
  | 'tooltip.charger-detail.command-logs.max-current'
  | 'tooltip.charger-detail.command-logs.always-enabled'
  | 'tooltip.charger-detail.command-logs.always-enabled-p1'
  | 'tooltip.charger-detail.command-logs.always-enabled-p2'
  | 'tooltip.charger-detail.command-logs.always-enabled-p3'
  | 'tooltip.charger-detail.command-logs.always-enabled-p4'
  | 'tooltip.charger-detail.command-logs.always-enabled-p5'
  | 'tooltip.charger-detail.ocpp-config.status'
  | 'tooltip.charger-detail.ocpp-config.current-value'
  | 'tooltip.charger-detail.ocpp-config.new-value'
  | 'tooltip.charger-detail.firmware-update-unavailable'
  | 'tooltip.vehicles.vehicle-data'
  | 'tooltip.users.organisation'
  | 'tooltip.users.permission'
  | 'tooltip.site-creation.max-current-value'
  | 'tooltip.site-creation.site-name'
  | 'tooltip.organisation-creation-name';

// based on https://www.notion.so/ev-energy/Feature-Tooltips-78e065f9b8d24deaa0fb71ef28eab086
export const TooltipTranslationEN: Record<TooltipTranslationKey, string> = {
  // Auth - signup
  'tooltip.signup.password': 'Password must:',
  'tooltip.signup.password-p1': `Be between ${PASSWORD_COMPLEXITY_CONFIG.min} and ${PASSWORD_COMPLEXITY_CONFIG.max} characters`,
  'tooltip.signup.password-p2': 'Include an uppercase character',
  'tooltip.signup.password-p3': 'Include a lowercase character',
  'tooltip.signup.password-p4': 'Include a number',
  'tooltip.signup.password-p5': 'Include a special character',

  // Chargers list
  'tooltip.chargers.smart-charge-enabled':
    'When enabled, ev.energy controls the charging to align with cheapest, least grid-congested, and greenest charging time.',
  'tooltip.chargers.charger-lock': 'The charger lock prevents unauthorized charger use.',

  // Charger detail
  'tooltip.charger-detail.connection-reliability':
    'Percentage of time the charger was seen online over the last 30 days. Connectivity is checked every minute.',
  'tooltip.charger-detail.connection-reliability-0': '0% - the charger was always offline during the last 30 days,',
  'tooltip.charger-detail.connection-reliability-100': '100% - the charger was always online the last 30 days.',
  'tooltip.charger-detail.meter-reading':
    'The total energy delivered over the charger’s lifetime. This value is calculated and retained by the charger.',
  'tooltip.charger-detail.charge-current-rate': 'The live charging rate of the charger also known as charging power displayed in kiloWatt (kW).',
  'tooltip.charger-detail.signal-strength': 'GSM signal strength in RSSI (Received Signal Strength Indicator).',
  'tooltip.charger-detail.start-charging':
    'Please be aware that start charging command might be overwritten or reversed by other charger controls in operation.',
  'tooltip.charger-detail.stop-charging':
    'Please be aware that stop charging command might be overwritten or reversed by other charger controls in operation.',
  'tooltip.charger-detail.reset': 'Please be aware that there could be a significant delay between pressing the button and the reset of the charger.',

  // Charger detail - logs
  'tooltip.charger-detail.charging-sessions.start': 'The timestamp when the vehicle was connected to the charger.',
  'tooltip.charger-detail.charging-sessions.finish': 'The timestamp when the vehicle was disconnected from the charger.',

  // Charger detail - logs
  'tooltip.charger-detail.command-logs.max-current': 'The maximum permitted charger amperage set by Pando.',
  'tooltip.charger-detail.command-logs.always-enabled': 'An EVSE is in "always enabled" mode if:',
  'tooltip.charger-detail.command-logs.always-enabled-p1': 'it is commissioned, but the user has not added a car through the app,',
  'tooltip.charger-detail.command-logs.always-enabled-p2': 'the user has one car and they have disabled the smart charge mode,',
  'tooltip.charger-detail.command-logs.always-enabled-p3': 'it is commissioned, but the user has not signed up for the app yet,',
  'tooltip.charger-detail.command-logs.always-enabled-p4': 'the user has multiple cars, but all of them have dynamic charging disabled,',
  'tooltip.charger-detail.command-logs.always-enabled-p5': 'the EVSE is a MUD one.',

  // Charger details - OCPP config
  'tooltip.charger-detail.ocpp-config.status':
    'The statuses of single attempts to update individual charger setting values. Possible values are pending, in progress, success, error.',
  'tooltip.charger-detail.ocpp-config.current-value': 'The current value the charger has set for an individual OCPP configuration.',
  'tooltip.charger-detail.ocpp-config.new-value': 'The target value Pando is sending to the charger for an individual OCPP configuration.',

  // Charger details - Firmware
  'tooltip.charger-detail.firmware-update-unavailable': 'An update is not possible now, because the charger firmware is up to date.',

  // Vehicles list
  'tooltip.vehicles.vehicle-data':
    'Indicates if ev.energy has been given access to the vehicle telemetry data. Vehicle data helps with smart charging accuracy.',

  // Users list
  'tooltip.users.organisation': 'The name of the organisation that the user belongs to.',
  'tooltip.users.permission': 'Pando User authorization level, access granted according to his/her role within organisation (Admin/Read/Write).',

  // Site creation
  'tooltip.site-creation.max-current-value':
    'Set a maximum allowed current that can be drawn across all chargers at the site. Leave blank if no limit shall be set.',
  'tooltip.site-creation.site-name': 'Set name for Site management across organizations. Once the name is set cannot be changed.',

  // Organisation
  'tooltip.organisation-creation-name':
    'Entering a name for a Organisation makes it easier for the user to locate and manage it later, and the name can be chosen freely.',
};
