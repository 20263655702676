import { useState } from 'react';
import type { LocalStorageItemKey, LocalStorageItemType } from '@utils/local-storage';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@utils/local-storage';
import { logger } from '@utils/logger';

export const useLocalStorage = <Type,>(type: LocalStorageItemType, key: LocalStorageItemKey | string, initialValue: Type) => {
  const [storedValue, setStoredValue] = useState<Type>(() => {
    try {
      const item = getLocalStorageItem<Type>(type, key);

      return item !== undefined && item !== null ? item : initialValue;
    } catch {
      return initialValue;
    }
  });

  const setValue = (value: Type) => {
    try {
      setStoredValue(value);
      setLocalStorageItem<Type>(type, key, value);
    } catch (error) {
      logger.error('Local storage save error', { type, key, value });
    }
  };

  const removeValue = () => {
    removeLocalStorageItem(type, key);
  };

  return [storedValue, setValue, removeValue] as const;
};
