import { ApiService } from '@services/api.service';
import { API_ROUTES } from '@shared/constants/api.constants';
import { Header } from '@shared/types/enums/header.enum';
import type { AuthTokens } from '@services/types/auth/auth.types';
import { config } from '@utils/config';
import type { AxiosResponse } from 'axios';
import type { AuthTokensApiResponse } from '@services/types/auth/auth.api-response.types';
import { AuthApiMapper } from '@services/mappers/auth.api.mapper';

interface RefreshTokenData {
  refreshToken: string;
}

export interface RefreshTokenRequestBody extends Record<string, string> {
  refresh_token: string;
  grant_type: 'refresh_token';
  client_id: string;
  client_secret: string;
}

export const refreshTokenCommand = async ({ refreshToken }: RefreshTokenData): Promise<AuthTokens> => {
  const data: RefreshTokenRequestBody = {
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
    client_id: config.auth.clientId,
    client_secret: config.auth.clientSecret,
  };

  const result = await ApiService.post<AuthTokensApiResponse, AxiosResponse<AuthTokensApiResponse>, URLSearchParams>(
    API_ROUTES.RefreshToken,
    new URLSearchParams(data),
    {
      headers: {
        [Header.ContentType]: 'application/x-www-form-urlencoded',
      },
    },
  );

  return AuthApiMapper.toAuthTokens(result.data);
};
