import type { FC } from 'react';
import { css } from './TermsAndConditions.style';

export const TermsAndConditions: FC = () => {
  return (
    <div>
      <style>{css}</style>
      <div className='c15'>
        <p className='c36'>
          <span className='c12 c5'>EV DOT ENERGY LIMITED (&ldquo;EV ENERGY&rdquo;) Terms v2.1 </span>
        </p>
        <p className='c36'>
          <span className='c9'>Date Last updated: 17-September-2023</span>
        </p>

        <p className='c42'>
          <span className='c12 c5'>PANDO PLATFORM</span>
        </p>
        <p className='c38 c42'>
          <span className='c12 c5'>GENERAL INFORMATION ABOUT US</span>
        </p>
        <p className='c27'>
          <span className='c9'>
            EV DOT ENERGY Limited, is a company incorporated in England and Wales under No. 11468310 whose registered office is at
          </span>
          <span className='c9 c33'>86-90 Paul Street, London, England, EC2A 4NE</span>
          <span className='c2'>.</span>
        </p>
        <p className='c7'>
          <span className='c9'>If there any questions, complaints or comments about the Platform or the Data Insights please contact us at</span>
          <span className='c9 c26'>&nbsp;</span>
          <a className='c9 c26 c30' href='mailto:pando@ev.energy'>
            pando@ev.energy
          </a>
          <span className='c9 c26 c39'>.</span>
        </p>
        <p className='c7'>
          <span className='c9'>
            EV DOT ENERGY Limited is registered with the Information Commissioner&#39;s Office and appears in the Data Protection Register under (No.
          </span>
          <span className='c9 c33'>&nbsp;ZA464860</span>
          <span className='c2'>).</span>
        </p>
        <p className='c20 c36'>
          <span className='c12 c5'></span>
        </p>
        <p className='c28'>
          <span className='c12 c5'>Terms of Use</span>
        </p>
        <p className='c10 c38'>
          <span className='c9'>These terms and conditions (&ldquo;</span>
          <span className='c5'>Terms</span>
          <span className='c2'>
            &rdquo;) are the terms on which we provide access to the Platform and/or the Data Insights to you. Please read these Terms carefully
            before using the Platform.
          </span>
        </p>
        <p className='c7'>
          <span className='c9'>These Terms are a binding contract between you as a user (&ldquo;</span>
          <span className='c5'>Customer</span>
          <span className='c9'>&rdquo;) and us (&ldquo;</span>
          <span className='c5'>ev.energy&rdquo;</span>
          <span className='c2'>
            ), and they set out your and our rights and obligations with respect to your User Licence and use of the Platform and/or the Data
            Insights. &nbsp;You should print and keep a copy of these Terms for future reference.
          </span>
        </p>
        <p className='c7'>
          <span className='c9'>
            We may change these Terms from time to time. If we choose to change these Terms in a manner that affects any of your substantive rights,
            we will notify you of any change at least 10 Business Days before it takes effect, either by email, when you log onto the Platform or by
            other means. If we provide you with such notice and you do not object to the change, you will be deemed to have agreed to it. If you
            object to a change this will constitute your notice of termination of your Licence in accordance with paragraph 13.
          </span>
        </p>
        <p className='c7'>
          <span className='c2'>These Terms are only available in the English language.</span>
        </p>
        <p className='c28'>
          <span className='c2'>&nbsp;</span>
        </p>
        <p className='c8 c28'>
          <span className='c5'>1.</span>
          <span className='c5'>&nbsp;Definitions</span>
        </p>
        <p className='c11'>
          <span className='c12 c5'></span>
        </p>
        <p className='c10'>
          <span className='c2'>1.1&nbsp;The definitions and rules of interpretation in this clause apply in this Agreement.</span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Additional Charges: </span>
          <span className='c2'>means all fees payable by the Customer to us in addition to the Licence Fees, each as set out in the Order Form.</span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Additional Services</span>
          <span className='c2'>: means those services provided in accordance with clause 4, as more particularly described in the Order Form.</span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Agreement: </span>
          <span className='c2'>
            means these Terms and conditions contained herein, together with a completed and signed Order Form. For the avoidance of doubt, each Order
            Form between Customer and ev.energy forms one separate and distinct Agreement.
          </span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Authorised Customer Employees: </span>
          <span className='c2'>
            means those employees, agents and independent contractors of the Customer or a Customer Affiliate who have been authorised by the Customer
            to use the Relevant Pando Dashboard In accordance with these Terms.
          </span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Business Day: </span>
          <span className='c2'>means any day that is not a Saturday, Sunday or public holiday in the United Kingdom.</span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Confidential Information: </span>
          <span className='c2'>
            means any information disclosed by or on behalf of a party (the &ldquo;Disclosing Party&rdquo;) to the other party (the &ldquo;Receiving
            Party&rdquo;) or its Representatives (as defined below) that would be regarded as confidential by a reasonable business person relating to
            the business, affairs, customers, clients, suppliers, plans, intentions, market opportunities, operations, processes, product information,
            know-how, designs, trade secrets or software of a party or any of its subsidiaries or affiliates (but not information that is publicly
            known through no fault of the Receiving Party). Information shall not constitute Confidential Information for the purposes of this
            Agreement to the extent that the information (a) is or becomes publicly available through no fault of the Receiving Party; (b) is already
            in the Receiving Party&rsquo;s lawful possession prior to the Disclosing Party&rsquo;s disclosure; (c) is received by the Receiving Party
            from a third party without any restriction and without breach of any confidentiality obligation; or (d) is developed independently without
            assistance of the Disclosing Party and without the use of any information disclosed by the Disclosing Party. To implement exchanges of
            Confidential Information pursuant to this Agreement, from time to time, either party may be the Disclosing Party and the other party shall
            be the Receiving Party.
          </span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Customer: </span>
          <span className='c2'>
            means any entity that purchases the ev.energy Platform and/or Data Services , as more particularly detailed in the Order Form.
          </span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c19'>
          <span className='c5'>Customer Affiliate</span>
          <span className='c2'>
            : means an entity which directly or indirectly controls, is controlled by, or is under common control by or with a Customer.
          </span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c19'>
          <span className='c5'>Customer Data </span>
          <span className='c2'>
            means (i) the data inputted by Customer or an Authorised Customer Employees for the purpose of using the Platform and/or Data Services
            &nbsp;or facilitating Customer&rsquo;s use of the Platform and/or Data Services , and (ii) data inputted by and collected from users of
            the Customer Site (as defined below.
          </span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Customer Site</span>
          <span className='c2'>
            : means those website URLs and mobile applications (as applicable) owned and operated by the Customer or a Customer Affiliate on which
            Customer elects, and ev.energy agrees, to implement the Script (as defined herein) and provide the Platform and/or Data Services , as such
            properties are more particularly detailed in the Order Form.
          </span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Data Protection Laws</span>
          <span className='c2'>
            : means the Data Protection Act 1998 implementing the Directive 95/46/EC on the protection of individuals with regard to the processing of
            personal data, the Privacy and Electronic Communications (EC Directive) Regulations 2003 implementing Directive 2002/58/EC concerning the
            processing of personal data and the protection of privacy in the electronic communications (or as applicable any similar legislation in
            any jurisdiction implementing Directive 95/46/EC, Directive 202/58/EC or similar or analogous legislation) as may be amended, modified or
            replaced from time to time, and including all related codes of practice. The parties acknowledge that with effect from 25 May 2018
            &ldquo;Data Protection Laws&rdquo; shall include Regulation 2016/679 (the General Data Protection Regulation).
          </span>
        </p>
        <p className='c10 c20'>
          <span className='c12 c5'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Effective Date: </span>
          <span className='c2'>is the date defined in the Order Form.</span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>End Users: </span>
          <span className='c2'>
            means the end user customers who have purchased the relevant User License from either Ev.energy or the Customer to use the relevant
            Platform and/or Data Services. &nbsp;
          </span>
        </p>
        <p className='c10 c20'>
          <span className='c12 c5'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Fees: </span>
          <span className='c2'>
            means the Licence Fees and the Additional Charges (if applicable) as such amounts are more particularly detailed in the Order Form.
          </span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Firmware: &nbsp;</span>
          <span className='c2'>
            means the software that runs on Hardware Devices to enable secure access to and sending of messages to and receipt of messages from
            Hardware Devices.
          </span>
        </p>
        <p className='c10 c20'>
          <span className='c12 c5'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Hardware Devices: </span>
          <span className='c2'>
            means the Customer&rsquo;s electric vehicle charger and/or other equipment which can be controlled to regulate and/or provide information
            on the flow of electricity and shall be deemed to include all Firmware.
          </span>
        </p>
        <p className='c10 c20'>
          <span className='c2 c37'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Initial Licence Term: </span>
          <span className='c2'>means the fixed period set out in the Order Form commencing on the Effective Date.</span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Intellectual Property Rights: </span>
          <span className='c2'>
            means all intellectual property rights in any part of the world, including patents, rights to inventions, utility models, copyright and
            related rights, trade and service marks, trade, business and domain names, rights in trade dress, rights to goodwill or to sue for passing
            off, unfair competition rights, rights in designs, rights in computer software, database rights, semiconductor and topography rights,
            moral rights, rights in confidential information (including know-how and trade secrets) and any other intellectual property rights, in
            each case whether registered or unregistered and including all applications for and renewals or extensions of such rights, and all similar
            or equivalent rights or forms of protection which subsist or will subsist now or in the future.
          </span>
        </p>
        <p className='c19 c20'>
          <span className='c2'></span>
        </p>
        <p className='c19'>
          <span className='c5'>Licence Fees: </span>
          <span className='c2'>
            means the fees payable to ev.energy for the Platform and/or Data Services&nbsp;by the Customer that are set out in the Order Form, and
            paid in accordance with clause 9 below.
          </span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c19'>
          <span className='c5'>Licence Term: </span>
          <span className='c2'>
            has the meaning given in clause 14.1(b) below (being the Initial Licence Term together with any subsequent Renewal Term(s)).
          </span>
        </p>
        <p className='c19 c20'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Normal Business Hours: </span>
          <span className='c2'>means the hours of 09.00hrs and 17.00hrs GMT.</span>
        </p>
        <p className='c19 c20'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Privacy Policy</span>
          <span className='c9'>: means the then current ev.energy privacy policy located at </span>
          <span className='c9 c39 c41'>
            <a target='_blank' className='c29' href='https://b2b.ev.energy/docs/privacy-policy' rel='noreferrer'>
              https://b2b.ev.energy/docs/privacy-policy
            </a>
          </span>
          <span className='c2'>&nbsp;(as ev.energy may update, modify, or change from time to time), which is incorporated herein by reference.</span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Pando Basic: </span>
          <span className='c2'>
            The dashboard developed by ev.energy for the Customer to have access to all Hardware Devices connected to ev.energy’s Smart Control
            Platform.
          </span>
        </p>
        <p className='c10 c20'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Pando Dashboard: </span>
          <span className='c2'>means Pando Basic and/or Pando for Shared Charging and/or Pando for Fleets.</span>
        </p>
        <p className='c10 c20'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Pando for Shared Charging: </span>
          <span className='c2'>
            includes Pando Basic plus the ability to group Hardware Devices into Sites, control charging access, manage site electric load, and
            monetise charging sessions. Pando for Shared Charging is also known as Pando for CPO or Pando for MUD.
          </span>
        </p>
        <p className='c10 c20'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Pando for Fleets: </span>
          <span className='c2'>
            means Pando Basic plus the ability to group connected electric vehicles into Fleets, list the status of connected vehicles, and list the
            charging sessions of electric vehicles.
          </span>
        </p>
        <p className='c10 c20'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Platform and/or Data Services: </span>
          <span className='c2'>
            means the Platform and/or Data Services ordered by Customer under this Agreement, which as a minimum shall include Pando Basic &nbsp;and
            any additional Services agreed as per the Order Form.
          </span>
        </p>
        <p className='c10 c20'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Renewal Term: </span>
          <span className='c2'>means the period described in clause 14.1 of this Agreement.</span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Order Form: </span>
          <span className='c2'>
            means one or more ordering documents for the Platform and/or Data Services, which together with these Terms and conditions form this
            Agreement.
          </span>
        </p>
        <p className='c10 c20'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>Script: </span>
          <span className='c2'>
            means the unique Javascript generated by ev.energy and provided to the Customer which, when implemented on a Customer Site, interacts with
            the Platform and/or Data Services &nbsp;and enables the Platform and/or Data Services &nbsp;to function.
          </span>
        </p>
        <p className='c19 c20'>
          <span className='c12 c5'></span>
        </p>
        <p className='c19'>
          <span className='c5'>Licence Fees: </span>
          <span className='c2'>
            means the fees payable to ev.energy for the Platform and/or Data Services &nbsp;by Customer that are set out in the Order Form, and paid
            in accordance with clause 9 below.
          </span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c19'>
          <span className='c5'>Licence Term: </span>
          <span className='c2'>
            has the meaning given in clause 14.1 below (being the Initial Licence Term together with any subsequent Renewal Term(s)).
          </span>
        </p>
        <p className='c10 c20'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c5'>User Licence: </span>
          <span className='c2'>
            means the Licence or Licences agreed with the Customer as defined in the Order Form, including but not limited to the Core App Licenses,
            Solar+ Licenses, Ev.energy+ Licenses, Pando for Shared Charging Licenses, and Pando for Fleet Licenses.
          </span>
        </p>
        <p className='c40'>
          <span className='c2'>&nbsp; &nbsp;</span>
        </p>
        <p className='c19'>
          <span className='c5'>Virus(es): </span>
          <span className='c2'>
            means anything or device (including any software, code, file or program) that may: (i) prevent, impair or otherwise adversely affect the
            operation of any computer software, hardware or network, any telecommunications service, equipment or network or any other service or
            device; (ii) prevent, impair or otherwise adversely affect access to or the operation of any program or data; or (iii) adversely affect
            the user experience, including worms, trojan horses, malware, viruses and other similar things or devices, now known or later developed.
          </span>
        </p>
        <p className='c11'>
          <span className='c2'></span>
        </p>
        <p className='c10'>
          <span className='c2'>
            1.2 Clause and schedule headings are for informational and organisational purposes only and shall not affect the interpretation of this
            Agreement.
          </span>
        </p>
        <p className='c22'>
          <span className='c2'>
            1.3 Where the words &quot;include&quot;, &quot;includes&quot;, &quot;including&quot; or &quot;in particular&quot; are used in an
            Agreement, they are deemed to have the words &ldquo;without limitation&rdquo; following them. Unless the context otherwise requires, words
            in the singular shall include the plural and in the plural shall include the singular.
          </span>
        </p>
        <p className='c22'>
          <span className='c2'>
            1.4 If any term in these Terms conflicts with any terms or conditions in an Order Form then the Order Form shall prevail in relation to
            the Platform and/or Data Services&nbsp;or Additional Services detailed in that Order Form.
          </span>
        </p>
        <p className='c6'>
          <span className='c5'>2.</span>
          <span className='c12 c5'>&nbsp;User Licences</span>
        </p>
        <p className='c4'>
          <span className='c2'>
            2.1&nbsp;Subject to the Customer purchasing the User Licences for any End Users, the restrictions set out in this clause 2 and the other
            terms and conditions, ev.energy hereby grant to the Customer a non-exclusive, non-transferable right and licence, without the right to
            grant sublicenses, to permit the&nbsp;Authorised Customer Employees to use the Platform and/or the Data Services during the Licence Term
            solely for the Customer&#39;s internal business operations.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>2.2 In relation to the End Users and Authorised Customer Employees, the Customer undertakes that:</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (a) each End User has the relevant User License to use the relevant Platform and/or Data Services and that the Authorised Customer
            Employee shall only have access to Pando Basic unless the relevant User License has been purchased for Pando for Shared Charging or Pando
            for Fleets for the relevant Authorised Customer Employees;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) it will not allow or suffer any User Licence to be used by more than one individual End User unless it has been reassigned in its
            entirety to another individual End User, in which case the prior End User shall no longer have any right to access or use the Platform
            and/or Data Services;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (c) each Authorised Customer Employee shall keep a secure password for their use of the Pando Dashboard, that such password shall be
            changed no less frequently than every 8 weeks and that each Authorised Customer Employee shall keep their password confidential;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (d) it shall maintain a written, up to date list of current Authorised Customer Employees and End Users and provide such list to ev.energy
            within 5 Business Days of ev.energy&#39;s written request at any time or times;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (e) it shall permit ev.energy or ev.energy&#39;s designated auditor to audit the Services in order to establish the name and password of
            each Authorised Customer Employee and the Customer&#39;s data processing facilities to audit compliance with this agreement. Each such
            audit may be conducted no more than once per quarter, at ev.energy&#39;s expense, and this right shall be exercised with reasonable prior
            notice, in such a manner as not to substantially interfere with the Customer&#39;s normal conduct of business;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (f) if any of the audits referred to in clause 2.2(e) reveal that any password has been provided to any individual who is not an
            Authorised Customer Employee, then without prejudice to ev.energy&#39;s other rights, the Customer shall promptly disable such passwords
            and ev.energy shall not issue any new passwords to any such individual; and
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (g) if any of the audits referred to in clause 2.2(e) reveal that the Customer has underpaid Licence Fees to ev.energy, then without
            prejudice to ev.energy&#39;s other rights, the Customer shall pay to ev.energy an amount equal to such underpayment as calculated in
            accordance with the prices set out in the Order Form within 10 Business Days of the date of the relevant audit.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            2.3 The Customer shall not access, store, distribute or transmit any Viruses, or any material during the course of its use of the Services
            that:
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (a) is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(b) facilitates illegal activity;</span>
        </p>
        <p className='c3'>
          <span className='c2'>(c) depicts sexually explicit images;</span>
        </p>
        <p className='c3'>
          <span className='c2'>(d) promotes unlawful violence;</span>
        </p>
        <p className='c3'>
          <span className='c2'>(e) is discriminatory based on race, gender, colour, religious belief, sexual orientation, disability; or</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (f) is otherwise illegal or causes damage or injury to any person or property; and ev.energy reserves the right, without liability or
            prejudice to its other rights to the Customer, to disable the Customer&#39;s access to any material that breaches the provisions of this
            clause.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>2.4 The Customer shall not:</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (a) except as may be allowed by any applicable law which is incapable of exclusion by agreement between the parties and except to the
            extent expressly permitted under this Agreement:
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (i) attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute
            all or any portion of the Platform and/or Data Services (as applicable) in any form or media or by any means; or
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (ii) attempt to de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part
            of the Platform and/or Data Services ; or
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) access all or any part of the Platform and/or Data Services in order to build a product or service which competes with the Platform
            and/or Data Services; or
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(c) use the Platform and/or Data Services to provide services to third parties; or</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (d) except as otherwise stated in these Terms, license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise
            commercially exploit, or otherwise make the Platform and/or Data Services available to any third party except the Authorised Customer
            Employees, or
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (e) attempt to obtain, or assist third parties in obtaining, access to the Platform and/or Data Services, other than as provided under
            this clause 2; or
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (f) introduce, or permit the introduction of, any Virus into the Platform and/or Data Services&#39; network and information systems.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            2.5 The Customer shall use all reasonable endeavours to prevent any unauthorised access to, or use of, the Platform and/or Data
            Services&nbsp;and, in the event of any such unauthorised access or use, promptly notify ev.energy.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            2.6 The rights provided under this clause 2 are granted to the Customer only, and shall not be considered granted to any subsidiary or
            holding company of the Customer unless otherwise agreed in the Order Form.
          </span>
        </p>
        <p className='c6'>
          <span className='c5'>3.</span>
          <span className='c9'>&nbsp;</span>
          <span className='c12 c5'>&nbsp;Additional User Licences</span>
        </p>
        <p className='c4'>
          <span className='c2'>
            3.1 Subject to clause 3.2 and clause 3.3, the Customer may, from time to time during any Licence Term, purchase additional User Licences
            in excess of the number set out in the Order Form and ev.energy shall grant access to the Platform and/or Data Services&nbsp;to such
            additional Authorised Customer Employees or End Users in accordance with the provisions of this agreement.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            3.2 If the Customer wishes to purchase additional User Licences, the Customer shall notify ev.energy in writing. ev.energy shall evaluate
            such requests for additional User Licences and respond to the Customer with approval or rejection of the request (such approval not to be
            unreasonably withheld). Where ev.energy approves the request, ev.energy shall activate the additional User Licences within 5 days of its
            approval of the Customer&#39;s request.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            3.3 Unless otherwise stated in the Order Form, if ev.energy approves the Customer&#39;s request to purchase additional User Licences, the
            Customer shall, within 15 days of the date of ev.energy&#39;s invoice, pay to ev.energy the relevant fees for such additional User
            Licences as set out in the Order Form and, if such additional User Licences are purchased by the Customer part way through the Initial
            Licence Term or any Renewal Period (as applicable), such fees shall be prorated from the date of activation by ev.energy for the remainder
            of the Initial Licence Term or then current Renewal Period (as applicable).
          </span>
        </p>
        <p className='c6'>
          <span className='c5'>4.</span>
          <span className='c9'>&nbsp;</span>
          <span className='c12 c5'>&nbsp;Services</span>
        </p>
        <p className='c4'>
          <span className='c2'>
            4.1 ev.energy shall, during the Licence Term, provide access to the Platform and/or Data Services subject to the terms of this Agreement.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            4.2 ev.energy shall use commercially reasonable endeavours to make the Services available 24 hours a day, seven days a week, except for:
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(a) planned maintenance carried out during the maintenance window of 10.00 am to 2.00 pm UK time; and</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) unscheduled maintenance performed outside Normal Business Hours, provided that ev.energy has used reasonable endeavours to give the
            Customer at least 6 Normal Business Hours notice in advance.
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            4.3 ev.energy will, as part of the Platform and/or Data Services and at no additional cost to the Customer, provide the Customer with
            ev.energy&#39;s standard customer support services, as set out in the appendix to these Terms during Normal Business Hours on a fair usage
            basis as determined at ev.energy&rsquo;s sole discretion. Additional levels of customer support can be purchased and will be as set out in
            &nbsp;the Order Form.
          </span>
        </p>
        <p className='c6'>
          <span className='c5'>5.</span>
          <span className='c12 c5'>&nbsp;Data Protection</span>
        </p>
        <p className='c0'>
          <span className='c2'>
            5.1 The Privacy Policy contains important information about how ev.energy processes information and is integral to and forms part of this
            Agreement.
          </span>
        </p>
        <p className='c8 c35'>
          <span className='c2'>
            5.2 ev.energy will comply with all applicable Data Protection Laws in relation to the processing of Personal Data in accordance with this
            Agreement and the Privacy Policy.
          </span>
        </p>
        <p className='c35'>
          <span className='c2'>
            5.3 In the event ev.energy proposes a material change to the Privacy Policy the customer will be given written notice of no less than 14
            days from which to submit their objections in writing before the proposed change becomes effective.
          </span>
        </p>
        <p className='c6'>
          <span className='c5'>6.</span>
          <span className='c12 c5'>&nbsp;Third party providers</span>
        </p>
        <p className='c4'>
          <span className='c2'>
            6.1 The Customer acknowledges that the Platform and/or Data Services may enable or assist it to access the website content of, correspond
            with, and purchase Platform and/or Data Services and services from, third parties via third-party websites and that it does so solely at
            its own risk. ev.energy makes no representation, warranty or commitment and shall have no liability or obligation whatsoever in relation
            to the content or use of, or correspondence with, any such third-party website, or any transactions completed, and any contract entered
            into by the Customer, with any such third party. &nbsp;Any contract entered into and any transaction completed via any third-party website
            is between the Customer and the relevant third party, and not ev.energy. &nbsp;ev.energy recommends that the Customer refers to the third
            party&#39;s website terms and conditions and privacy policy prior to using the relevant third-party website. &nbsp;ev.energy does not
            endorse or approve any third-party website nor the content of any of the third-party websites made available via the Services.
          </span>
        </p>
        <p className='c6'>
          <span className='c5'>7.</span>
          <span className='c12 c5'>&nbsp;ev.energy&#39;s obligations</span>
        </p>
        <p className='c4'>
          <span className='c2'>
            7.1 ev.energy undertakes that the Platform and/or Data Services will be performed substantially in accordance with the documentation and
            with reasonable skill and care.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            7.2 The undertaking at clause 7.1 shall not apply to the extent of any non-conformance which is caused by use of the Platform and/or Data
            Services contrary to ev.energy&#39;s instructions, or modification or alteration of the Platform and/or Data Services by any party other
            than ev.energy or ev.energy&#39;s duly authorised contractors or agents. If the Platform and/or Data Services do not conform with the
            foregoing undertaking, ev.energy will, at its expense, use all reasonable commercial endeavours to correct any such non-conformance
            promptly, or provide the Customer with an alternative means of accomplishing the desired performance. Such correction or substitution
            constitutes the Customer&#39;s sole and exclusive remedy for any breach of the undertaking set out in clause 7.1.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>7.3 ev.energy:</span>
        </p>
        <p className='c3'>
          <span className='c2'>(a) does not warrant that:</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (i) the Customer&#39;s or End User&rsquo;s use of the Platform and/or Data Services will be uninterrupted or error-free; or
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (ii) that the Platform and/or Data Services, documentation and/or the information obtained by the Customer through the Services will meet
            the Customer&#39;s requirements; or
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(iii) the Platform and/or Data Services will be free from Vulnerabilities or Viruses.</span>
        </p>
        <p className='c3 c20'>
          <span className='c2'></span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data over
            communications networks and facilities, including the internet, and the Customer acknowledges that the Platform and/or Data Services may
            be subject to limitations, delays and other problems inherent in the use of such communications facilities.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            7.4 This agreement shall not prevent ev.energy from entering into similar agreements with third parties, or from independently developing,
            using, selling or licensing documentation, the Platform and/or Data Services &nbsp;and/or services which are similar to those provided
            under this Agreement.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            7.5 ev.energy warrants that it has and will maintain all necessary licences, consents, and permissions necessary for the performance of
            its obligations under this agreement.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            7.6 ev.energy shall not be responsible for any loss, destruction, alteration or disclosure of Customer Data caused by any third party
            (except those third parties sub-contracted by ev.energy to perform services related to Customer Data maintenance and back-up for which it
            shall remain fully liable.
          </span>
        </p>
        <p className='c6'>
          <span className='c5'>8.</span>
          <span className='c12 c5'>&nbsp;Customer&#39;s obligations</span>
        </p>
        <p className='c4'>
          <span className='c2'>8.1 The Customer shall:</span>
        </p>
        <p className='c3'>
          <span className='c2'>(a) provide ev.energy with:</span>
        </p>
        <p className='c3'>
          <span className='c2'>(i) all necessary cooperation in relation to this Agreement; and</span>
        </p>
        <p className='c3'>
          <span className='c2'>(ii) all necessary access to such information as may be required by ev.energy;</span>
        </p>
        <p className='c13'>
          <span className='c2'>
            in order to provide the Platform and/or Data Services, including but not limited to Customer Data, security access information and
            configuration services;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) without affecting its other obligations under this Agreement, comply with all applicable laws and regulations with respect to its
            activities under this Agreement including but not limited to ensuring it has proper legal basis (as defined in the the Data Protection
            Laws) which relate to the processing, sharing and storage of personal information;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (c) carry out all other Customer responsibilities set out in this agreement in a timely and efficient manner. In the event of any delays
            in the Customer&#39;s provision of such assistance as agreed by the parties, ev.energy may adjust any agreed timetable or delivery
            schedule as reasonably necessary;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (d) ensure that the Authorised Customer Employees use the Platform and/or Data Services &nbsp;in accordance with the terms and conditions
            of this Agreement and shall be responsible for any Authorised Customer Employees breach of this Agreement;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (e) obtain and shall maintain all necessary licences, consents, and permissions necessary for ev.energy, its contractors and agents to
            perform their obligations under this Agreement, including without limitation the Platform and/or Data Services;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (f) ensure that its network and systems comply with the relevant specifications provided by ev.energy from time to time; and
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (g) be, to the extent permitted by law and except as otherwise expressly provided in this Agreement, solely responsible for procuring,
            maintaining and securing its network connections and telecommunications links from its systems and/or Hardware Devices to ev.energy&#39;s
            data centres and/or software (e.g. using Transport Layer Security (TLS) encryption or otherwise specified industry grade encryption), and
            all problems, conditions, delays, delivery failures and all other loss or damage arising from or relating to the Customer&#39;s network
            connections or telecommunications links or caused by the internet.
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (h) ensure that all Hardware Devices meet certain functionality levels by implementing Open Charge Point Protocol (OCPP 1.6 JSON or OCPP
            2.0 JSON), as defined by the specification available from the Open Charge Alliance,{' '}
            <a target='_blank' href='https://www.openchargealliance.org/protocols' rel='noreferrer'>
              https://www.openchargealliance.org/protocols
            </a>
            .
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (i) ensure that all Hardware Devices and Firmware versions meet the required standards to comply with any and all regulations that may be
            in place from time to time in the country of sale for example including, if the country of sale is the UK, the UK government&rsquo;s
            Electric Vehicles Smart Charge Points Regulations (as updated from time to time).
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            8.2 Where the Customer is a hardware manufacturer or an installer, they will agree to comply with the following obligations.
          </span>
        </p>
        <p className='c34'>
          <span className='c2'>
            (a) ensure that support is available by telephone and e-mail between 9am and 5pm London time to provide assistance to ev.energy in respect
            of remedying defects in the Supported Software and providing advice on the use and operation of the Hardware Device;
          </span>
        </p>
        <p className='c34'>
          <span className='c2'>
            (b) promptly notify ev.energy of all defects to the Hardware Device and / or Firmware. Where such notification is made orally, the
            Customer shall provide written confirmation (which may be sent by e-mail) of the notification within two working days;
          </span>
        </p>
        <p className='c34'>
          <span className='c2'>
            (c) co-operate with ev.energy to provide End User support, including provision of information and data, making available suitably
            qualified employees and contractors and provide any assistance or information as may reasonably be required by ev.energy, including in
            relation to the diagnosis of any faults;
          </span>
        </p>
        <p className='c34'>
          <span className='c2'>(d) comply, as soon as reasonably practicable, with all reasonable requests for information or assistance;</span>
        </p>
        <p className='c34'>
          <span className='c2'>
            (e) ensure that appropriate environmental conditions are maintained for the Hardware Device and shall take all reasonable steps to ensure
            that the Supported Software is operated in a proper manner by the End Users;
          </span>
        </p>
        <p className='c34'>
          <span className='c2'>(f) co-operate with ev.energy in providing the Platform and/or Data Services; and</span>
        </p>
        <p className='c34'>
          <span className='c2'>(g) keep full backup copies of all data.</span>
        </p>
        <p className='c4'>
          <span className='c2'>
            8.3 Where the Customer is not a hardware manufacturer or an installer, they will agree to comply with the following obligations:
          </span>
        </p>
        <p className='c34'>
          <span className='c2'>
            (a) promptly notify ev.energy of all defects to the Hardware Device and / or Firmware. Where such notification is made orally, the
            Customer shall provide written confirmation (which may be sent by e-mail) of the notification within two working days;
          </span>
        </p>
        <p className='c34'>
          <span className='c2'>
            (b) co-operate with ev.energy to provide End User support, including provision of information and data, making available suitably
            qualified employees and contractors and provide any assistance or information as may reasonably be required by ev.energy, including in
            relation to the diagnosis of any faults;
          </span>
        </p>
        <p className='c8 c34'>
          <span className='c2'>(c) comply, as soon as reasonably practicable, with all reasonable requests for information or assistance;</span>
        </p>
        <p className='c34'>
          <span className='c2'>
            (d) take all reasonable steps to ensure that the Supported Software is operated in a proper manner by the End Users; and
          </span>
        </p>
        <p className='c34'>
          <span className='c2'>(e) keep full backup copies of all data.</span>
        </p>
        <p className='c4'>
          <span className='c2'>
            8.4 The Customer shall own all right, title and interest in and to all of the Customer Data that is not personal data and shall have sole
            responsibility for the legality, reliability, integrity, accuracy and quality of all such Customer Data.
          </span>
        </p>
        <p className='c6'>
          <span className='c5'>9.</span>
          <span className='c12 c5'>&nbsp;Charges and payment</span>
        </p>
        <p className='c4'>
          <span className='c2'>
            9.1 The Customer shall pay the Licence Fees to ev.energy for the User Licences and any Additional Charges in accordance with this clause 9
            and the Order Form.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            9.2 The Customer shall on the Effective Date provide to ev.energy valid, up-to-date and complete credit card details or approved purchase
            order information acceptable to ev.energy and any other relevant valid, up-to-date and complete contact and billing details and, if the
            Customer provides:
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(a) its credit card details to ev.energy, the Customer hereby authorises ev.energy to bill such credit card:</span>
        </p>
        <p className='c3'>
          <span className='c2'>(i) on the Effective Date for the Licence Fees payable in respect of the Initial Licence Term; and</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (ii) subject to clause 14.1, on each anniversary of the Effective Date for the Licence Fees payable in respect of the next Renewal Period;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(b) its approved purchase order information to ev.energy, ev.energy shall invoice the Customer:</span>
        </p>
        <p className='c3'>
          <span className='c2'>(i) on the Effective Date for the Licence Fees payable in respect of the Initial Licence Term; and</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (ii) subject to clause 14.1, at least 30 days prior to each anniversary of the the Effective Date for the Licence Fees payable in respect
            of the next Renewal Period, and the Customer shall pay each invoice within 30 days after the date of such invoice.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            9.3 If ev.energy has not received payment within 30 days after the due date, and without prejudice to any other rights and remedies of
            ev.energy:
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (a) ev.energy may, without liability to the Customer, disable the Customer&#39;s password, account and access to all or part of the
            Services and ev.energy shall be under no obligation to provide any or all of the Services while the invoice(s) concerned remain unpaid;
            and
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) interest shall accrue on a daily basis on such due amounts at an annual rate equal to 4% over the then current base lending rate of
            Barclays Bank Plc from time to time, commencing on the due date and continuing until fully paid, whether before or after judgement.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>9.4 All amounts and fees stated or referred to in this agreement:</span>
        </p>
        <p className='c3'>
          <span className='c2'>(a) shall be payable in pounds sterling or any other currency as agreed in the Order Form;</span>
        </p>
        <p className='c3'>
          <span className='c2'>(b) are, subject to clause 14.3(b), non-cancellable and non-refundable;</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (c) are exclusive of value added tax, which shall be added to ev.energy&#39;s invoice(s) at the appropriate rate.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            9.5 ev.energy shall be entitled to increase the Licence Fees, the fees payable in respect of the additional User Licences purchased
            pursuant to clause 3.3, and/or the support fees payable pursuant to clause 4.3 at the start of each Renewal Period upon 90 days&#39; prior
            notice to the Customer and the relevant Order Form shall be deemed to have been amended accordingly.
          </span>
        </p>
        <p className='c6'>
          <span className='c5'>10.</span>
          <span className='c9'>&nbsp;&nbsp;</span>
          <span className='c12 c5'>Proprietary rights</span>
        </p>
        <p className='c4'>
          <span className='c2'>
            10.1 The Customer acknowledges and agrees that ev.energy and/or its licensors own all intellectual property rights in the Platform and/or
            Data Services. Except as expressly stated herein, this Agreement does not grant the Customer any rights to, under or in, any patents,
            copyright, database right, trade secrets, trade names, trade marks (whether registered or unregistered), or any other rights or licences
            in respect of the Platform and/or Data Services .
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            10.2 ev.energy confirms that it has all the rights in relation to the Platform and/or Data Services &nbsp;that are necessary to grant all
            the rights it purports to grant under, and in accordance with, the terms of this Agreement.
          </span>
        </p>
        <p className='c6'>
          <span className='c5'>11.</span>
          <span className='c12 c5'>&nbsp;Confidentiality</span>
        </p>
        <p className='c4'>
          <span className='c5'>11.1</span>
          <span className='c9'>&nbsp;</span>
          <span className='c5'>Confidential Information</span>
          <span className='c2'>
            &nbsp;means all confidential information (however recorded or preserved) disclosed by a party or its Representatives (as defined below) to
            the other party and that party&#39;s Representatives whether before or in connection with using the Platform and/or Data Services,
            including but not limited to:
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(a) the existence and terms of this Agreement or any agreement entered into in connection with this Agreement;</span>
        </p>
        <p className='c3'>
          <span className='c2'>(b) any information that would be regarded as confidential by a reasonable business person relating to:</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (i) the business, assets, affairs, customers, clients, suppliers, or plans, intentions, or market opportunities of the disclosing party
            (or of any member of the group of companies to which the disclosing party belongs); and
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (ii) the operations, processes, product information, know-how, designs, trade secrets or software of the disclosing party (or of any
            member of the group of companies to which the disclosing party belongs).
          </span>
        </p>
        <p className='c18'>
          <span className='c5'>Representatives</span>
          <span className='c2'>
            &nbsp;means, in relation to a party, its employees, officers, contractors, subcontractors, representatives and advisers.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>11.2 The provisions of this clause shall not apply to any Confidential Information that:</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (a) is or becomes generally available to the public (other than as a result of its disclosure by the receiving party or its
            Representatives in breach of this clause);
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(b) was available to the receiving party on a non-confidential basis before disclosure by the disclosing party;</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (c) was, is or becomes available to the receiving party on a non-confidential basis from a person who, to the receiving party&#39;s
            knowledge, is not bound by a confidentiality agreement with the disclosing party or otherwise prohibited from disclosing the information
            to the receiving party; or
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(d) the parties agree in writing that it is not confidential or may be disclosed.</span>
        </p>
        <p className='c4'>
          <span className='c2'>11.3 Each party shall keep the other party&#39;s Confidential Information secret and confidential and shall not:</span>
        </p>
        <p className='c3'>
          <span className='c9'>
            (a) use such Confidential Information except for [the purpose of exercising or performing its rights and obligations under or in
            connection with this agreement (
          </span>
          <span className='c5'>Permitted Purpose</span>
          <span className='c2'>); or</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) disclose such Confidential Information in whole or in part to any third party, except as expressly permitted by this clause 11.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            11.4 A party may disclose the other party&#39;s Confidential Information to those of its Representatives who need to know such
            Confidential Information for the Permitted Purpose, provided that:
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (a) it informs such Representatives of the confidential nature of the Confidential Information before disclosure; and
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) at all times, it is responsible for such Representatives&#39; compliance with the confidentiality obligations set out in this clause.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            11.5 A party may disclose Confidential Information to the extent such Confidential Information is required to be disclosed by law, by any
            governmental or other regulatory authority or by a court or other authority of competent jurisdiction provided that, to the extent it is
            legally permitted to do so, it gives the other party as much notice of such disclosure as possible and, where notice of disclosure is not
            prohibited and is given in accordance with this clause 11.5, it takes into account the reasonable requests of the other party in relation
            to the content of such disclosure.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            11.6 A party may, provided that it has reasonable grounds to believe that the other party is involved in activity that may constitute a
            criminal offence under the Bribery Act 2010, disclose Confidential Information to the Serious Fraud Office without first informing the
            other party of such disclosure.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            11.7 Each party reserves all rights in its Confidential Information. No rights or obligations in respect of a party&#39;s Confidential
            Information other than those expressly stated in this agreement are granted to the other party, or to be implied from this agreement.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>11.8 On termination or expiry of this agreement, each party shall:</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (a) destroy or return to the other party all documents and materials (and any copies) containing, reflecting, incorporating or based on
            the other party&#39;s Confidential Information;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) erase all the other party&#39;s Confidential Information from computer and communications systems and devices used by it, including
            such systems and data storage services provided by third parties (to the extent technically and legally practicable); and
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (c) certify in writing to the other party that it has complied with the requirements of this clause, provided that a recipient party may
            retain documents and materials containing, reflecting, incorporating or based on the other party&#39;s Confidential Information to the
            extent required by law or any applicable governmental or regulatory authority. The provisions of this clause shall continue to apply to
            any such documents and materials retained by a recipient party, subject to clause 13 (Termination).
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            11.9 No party shall make, or permit any person to make, any public announcement concerning this agreement without the prior written
            consent of the other parties (such consent not to be unreasonably withheld or delayed), except as required by law, any governmental or
            regulatory authority (including, without limitation, any relevant securities exchange), any court or other authority of competent
            jurisdiction.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            11.10 Except as expressly stated in this agreement, no party makes any express or implied warranty or representation concerning its
            Confidential Information.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            11.11 The above provisions of this clause 11 shall survive for a period of five years from termination of this Agreement.
          </span>
        </p>
        <p className='c6'>
          <span className='c5'>12.</span>
          <span className='c5 c12'>&nbsp;Indemnity</span>
        </p>
        <p className='c4'>
          <span className='c2'>
            12.1 The Customer shall defend, indemnify and hold harmless ev.energy against claims, actions, proceedings, losses, damages, expenses and
            costs (including without limitation court costs and reasonable legal fees) arising out of or in connection with the Customer&#39;s use of
            the Platform and/or Data Services, provided that:
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(a) the Customer is given prompt notice of any such claim;</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) ev.energy provides reasonable co-operation to the Customer in the defence and settlement of such claim, at the Customer&#39;s expense;
            and
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(c) the Customer is given sole authority to defend or settle the claim.</span>
        </p>
        <p className='c4'>
          <span className='c2'>
            12.2 ev.energy shall defend the Customer, its officers, directors and employees against any claim that the Customer&#39;s use of the
            Platform and/or Data Services in accordance with this agreement infringes any United Kingdom patent effective as of the Effective Date,
            copyright, trade mark, database right or right of confidentiality, and shall indemnify the Customer for any amounts awarded against the
            Customer in judgement or settlement of such claims, provided that:
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(a) ev.energy is given prompt notice of any such claim;</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) the Customer does not make any admission, or otherwise attempt to compromise or settle the claim and provides reasonable co-operation
            to ev.energy in the defence and settlement of such claim, at ev.energy&#39;s expense; and
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(c) ev.energy is given sole authority to defend or settle the claim.</span>
        </p>
        <p className='c4'>
          <span className='c2'>
            12.3 In the defence or settlement of any claim, ev.energy may procure the right for the Customer to continue using the Platform and/or
            Data Services, replace or modify the Platform and/or Data Services so that they become non-infringing or, if such remedies are not
            reasonably available, terminate this agreement on 2 Business Days&#39; notice to the Customer without any additional liability or
            obligation to pay liquidated damages or other additional costs to the Customer.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            12.4 In no event shall ev.energy, its employees, agents and subcontractors be liable to the Customer to the extent that the alleged
            infringement is based on:
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(a) a modification of the Platform and/or Data Services by anyone other than ev.energy; or</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) the Customer&#39;s use of the Platform and/or Data Services in a manner contrary to the instructions given to the Customer by
            ev.energy; or
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (c) the Customer&#39;s use of the Platform and/or Data Services after notice of the alleged or actual infringement from ev.energy or any
            appropriate authority.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            12.5 The foregoing&nbsp;and clause 13.3(b) states the Customer&#39;s sole and exclusive rights and remedies, and ev.energy&#39;s
            (including ev.energy&#39;s employees&#39;, agents&#39; and subcontractors&#39;) entire obligations and liability, for infringement of any
            patent, copyright, trade mark, database right or right of confidentiality.
          </span>
        </p>
        <p className='c6'>
          <span className='c5'>13.</span>
          <span className='c12 c5'>&nbsp;Limitation of liability</span>
        </p>
        <p className='c4'>
          <span className='c2'>13.1 Except as expressly and specifically provided in this agreement:</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (a) the Customer assumes sole responsibility for results obtained from the use of the Platform and/or Data Services &nbsp;by the Customer,
            and for conclusions drawn from such use. ev.energy shall have no liability for any damage caused by errors or omissions in any
            information, instructions or scripts provided to ev.energy by the Customer in connection with the Services, or any actions taken by
            ev.energy at the Customer&#39;s direction;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) all warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the
            fullest extent permitted by applicable law, excluded from this agreement; and
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(c) the Platform and/or Data Services &nbsp;are provided to the Customer on an &quot;as is&quot; basis.</span>
        </p>
        <p className='c4'>
          <span className='c2'>13.2Nothing in this agreement excludes the liability of ev.energy:</span>
        </p>
        <p className='c3'>
          <span className='c2'>(a) for death or personal injury caused by ev.energy&#39;s negligence; or</span>
        </p>
        <p className='c3'>
          <span className='c2'>(b) for fraud or fraudulent misrepresentation.</span>
        </p>
        <p className='c4'>
          <span className='c2'>13.3&nbsp;Subject to clause 13.1 and clause 13.2:</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (a) ev.energy shall not be liable whether in tort (including for [negligence or] breach of statutory duty), contract, misrepresentation,
            restitution or otherwise for any loss of profits, loss of business, depletion of goodwill and/or similar losses or loss or corruption of
            data or information, or pure economic loss, or for any special, indirect or consequential loss, costs, damages, charges or expenses
            however arising under this agreement; and
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) ev.energy&#39;s total aggregate liability in contract (including in respect of the indemnity at clause 13.2), tort (including
            negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in connection with the performance or
            contemplated performance of this agreement shall be limited to the total Licence Fees paid for the User Licences during the 12 months
            immediately preceding the date on which the claim arose.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            13.4 Nothing in this agreement excludes the liability of the Customer for any breach, infringement or misappropriation of
            ev.energy&rsquo;s Intellectual Property Rights.
          </span>
        </p>
        <p className='c6'>
          <span className='c5'>14.</span>
          <span className='c12 c5'>&nbsp;Term and termination</span>
        </p>
        <p className='c4'>
          <span className='c9'>
            14.1 This Agreement shall, unless otherwise terminated as provided in this clause 14, commence on the Effective Date and shall continue
            for the Initial Licence Term and, thereafter, unless otherwise stated in the Order Form, this Agreement shall be automatically renewed for
            successive periods of 12 months (each a
          </span>
          <span className='c5'>&nbsp;Renewal Term</span>
          <span className='c2'>), unless:</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (a) either party notifies the other party of termination, in writing, at least 60 days before the end of the Initial Licence Term or any
            Renewal Period, in which case this agreement shall terminate upon the expiry of the applicable Initial Licence Term or Renewal Period; or
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) otherwise terminated in accordance with the provisions of this agreement;&nbsp;and the Initial Licence Term together with any
            subsequent Renewal Periods shall constitute the{' '}
          </span>
          <span className='c5'>Licence Term</span>
          <span className='c2'>.</span>
        </p>
        <p className='c4'>
          <span className='c2'>
            14.2 Where at the sole discretion of ev.energy, it is determined that the contractual relationship between the parties is no longer
            financially viable, ev.energy may terminate for convenience, with no liability, by providing the User with no less than seven (7) days
            written notice.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>
            14.3 Without affecting any other right or remedy available to it, either party may terminate this agreement with immediate effect by
            giving written notice to the other party if:
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (a) the other party fails to pay any amount due under this agreement on the due date for payment and remains in default not less than 10
            days after being notified in writing to make such payment;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) the other party commits a material breach of any other term of this agreement and (if such breach is remediable) fails to remedy that
            breach within a period of 10 days after being notified in writing to do so;
          </span>
        </p>
        <p className='c3'>
          <span className='c9'>
            (c) the other party suspends, or threatens to suspend, payment of its debts or is unable to pay its debts as they fall due or admits
            inability to pay its debts or is deemed unable to pay its debts within the meaning of section 123 of the Insolvency Act 1986 (
          </span>
          <span className='c5'>IA 1986</span>
          <span className='c2'>
            ) as if the words &quot;it is proved to the satisfaction of the court&quot; did not appear in sections 123(1)(e) or 123(2) of the IA 1986;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (d) the other party commences negotiations with all or any class of its creditors with a view to rescheduling any of its debts, or makes a
            proposal for or enters into any compromise or arrangement with its creditors other than for the sole purpose of a scheme for a solvent
            amalgamation of that other party with one or more other companies or the solvent reconstruction of that other party;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(e) the other party applies to court for, or obtains, a moratorium under Part A1 of the Insolvency Act 1986;</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (f) a petition is filed, a notice is given, a resolution is passed, or an order is made, for or in connection with the winding up of that
            other party other than for the sole purpose of a scheme for a solvent amalgamation of that other party with one or more other companies or
            the solvent reconstruction of that other party;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (g) an application is made to court, or an order is made, for the appointment of an administrator, or if a notice of intention to appoint
            an administrator is given or if an administrator is appointed, over the other party (being a company, partnership or limited liability
            partnership);
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (h) the holder of a qualifying floating charge over the assets of that other party (being a company or limited liability partnership) has
            become entitled to appoint or has appointed an administrative receiver;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (i) a person becomes entitled to appoint a receiver over the assets of the other party or a receiver is appointed over the assets of the
            other party;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (j) a creditor or encumbrancer of the other party attaches or takes possession of, or a distress, execution, sequestration or other such
            process is levied or enforced on or sued against, the whole or any part of the other party&#39;s assets and such attachment or process is
            not discharged within 14 days;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (k) any event occurs, or proceeding is taken, with respect to the other party in any jurisdiction to which it is subject that has an
            effect equivalent or similar to any of the events mentioned in clause 14.3(c) to clause 14.3(j) (inclusive);
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (l) the other party suspends or ceases, or threatens to suspend or cease, carrying on all or a substantial part of its business; or
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (m) the other party&#39;s financial position deteriorates so far as to reasonably justify the opinion that its ability to give effect to
            the terms of this agreement is in jeopardy.
          </span>
        </p>
        <p className='c4'>
          <span className='c2'>14.4 On termination of this agreement for any reason:</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (a) all access and licences for Authorised Customer Employees granted under this Agreement shall immediately terminate and the Customer
            shall immediately cease all use of the Platform and/or Data Services;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) each party shall return and make no further use of any equipment, property, documentation and other items (and all copies of them)
            belonging to the other party;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (c) ev.energy may destroy or otherwise dispose of any of the Customer Data in its possession unless ev.energy receives, no later than ten
            days after the effective date of the termination of this agreement, a written request for the delivery to the Customer of the then most
            recent back-up of the Customer Data. ev.energy shall use reasonable commercial endeavours to deliver the back-up to the Customer within 30
            days of its receipt of such a written request, provided that the Customer has, at that time, paid all fees and charges outstanding at and
            resulting from termination (whether or not due at the date of termination). The Customer shall pay all reasonable expenses incurred by
            ev.energy in returning or disposing of Customer Data; and
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (d) any rights, remedies, obligations or liabilities of the parties that have accrued up to the date of termination, including the right
            to claim damages in respect of any breach of the agreement which existed at or before the date of termination shall not be affected or
            prejudiced.
          </span>
        </p>
        <p className='c6'>
          <span className='c5'>15.</span>
          <span className='c12 c5'>&nbsp;General</span>
        </p>
        <p className='c0'>
          <span className='c9'>
            15.1 Neither party shall have any liability to the other under or in connection with this Agreement if it is prevented from, or delayed in
            performing, its obligations under this Agreement or from carrying on its business by acts, events, omissions or accidents beyond its
            reasonable control (a &ldquo;
          </span>
          <span className='c5'>Force Majeure Event</span>
          <span className='c2'>
            &rdquo;), including strikes, lock-outs or other industrial disputes (whether involving the workforce of either party to this Agreement or
            any other party), failure of a utility service or transport network, act of God, war, riot, internet interruptions, civil commotion,
            malicious damage, compliance with any law or governmental order, rule, change in law, regulation or direction, accident, breakdown of
            plant or machinery, fire, flood, storm or default of suppliers or subcontractors. If a Force Majeure Event prevents, hinders, or delays a
            party&rsquo;s performance of its obligations under this Agreement for a continuous period of more than 6 months, either party may
            terminate this Agreement immediately on written notice to the other party.
          </span>
        </p>
        <p className='c0 c20'>
          <span className='c2'></span>
        </p>
        <p className='c0'>
          <span className='c2'>
            15.2 ev.energy shall be permitted to identify the Customer as a ev.energy Customer on its website or other marketing materials and
            accordingly, Customer hereby grants to ev.energy the right to use and display Customer&rsquo;s name, logo and/or any other identifying
            words or marks associated with Customer, in whole or in part, and in any media for the sole purposes of identifying Customer as a customer
            of ev.energy.
          </span>
        </p>
        <p className='c14'>
          <span className='c2'></span>
        </p>
        <p className='c0'>
          <span className='c2'>
            15.3 The parties are independent contracting parties and owe no fiduciary or other duties to each other except as set forth in this
            Agreement and any Order Form. Neither party has, or will hold itself out as having, any right, title or authority to incur any obligation
            on behalf of the other party, unless expressly authorised in writing to do so. The parties relationship in this Agreement shall not be
            construed as a joint venture, partnership, franchise, employment or agency relationship, or as imposing any liability upon either party
            that otherwise might result from such a relationship.
          </span>
        </p>
        <p className='c14'>
          <span className='c2'></span>
        </p>
        <p className='c0'>
          <span className='c2'>
            15.4 The Customer recognises that ev.energy always seeks to innovate and find ways to improve the Platform and/or Data Services &nbsp;with
            new features and functions. Customer agrees that ev.energy may therefore change the Platform and/or Data Services &nbsp;(i) without notice
            provided such changes do not materially adversely affect the nature or quality of the Platform and/or Data Services , or (ii) on written
            notice to Customer where such changes will materially adversely affect the nature or quality of the Platform and/or Data Services ,
            provided that the Customer shall have the right to terminate the Agreement on giving written notice to ev.energy not more than one (1)
            month following any such material change for point (ii) taking effect. Subject to the preceding sentence, no variation of this Agreement
            shall be valid unless it is in writing and signed by or on behalf of each of the parties.
          </span>
        </p>
        <p className='c8 c14'>
          <span className='c2'></span>
        </p>
        <p className='c0'>
          <span className='c9'>
            15.5 The Customer may submit feedback or ideas about the Platform and/or Data Services , including how to improve the Platform and/or Data
            Services &nbsp;or any other service offered by ev.energy (&ldquo;
          </span>
          <span className='c5'>Feedback</span>
          <span className='c2'>
            &rdquo;). Customer acknowledges that no further consideration is payable as a result of such Feedback, and that ev.energy is free (but not
            obligated) to use the Feedback on a non-exclusive and non-confidential basis for any business purpose, during or after the Licence Term.
          </span>
        </p>
        <p className='c14'>
          <span className='c2'></span>
        </p>
        <p className='c0'>
          <span className='c2'>
            15.6 A waiver of any right under this Agreement is effective only if it is in writing and it applies only to the circumstances for which
            it is given. No failure or delay by a party in exercising any right or remedy under this Agreement or by law shall constitute a waiver of
            that (or any other) right or remedy, nor preclude or restrict its further exercise. No single or partial exercise of such right or remedy
            shall preclude or restrict the further exercise of that (or any other) right or remedy. Unless specifically provided otherwise, rights
            arising under this Agreement are cumulative and do not exclude rights provided by law.
          </span>
        </p>
        <p className='c14'>
          <span className='c2'></span>
        </p>
        <p className='c0'>
          <span className='c2'>
            15.7 If any provision of this Agreement (or part of any provision) is found by any court or other authority of competent jurisdiction to
            be invalid, illegal or unenforceable, that provision or part-provision shall, to the extent required, be deemed not to form part of this
            Agreement, and the validity and enforceability of the other provisions of this Agreement shall not be affected. If a provision of this
            Agreement (or part of any provision) is found illegal, invalid or unenforceable, the provision shall apply with the minimum modification
            necessary to make it legal, valid and enforceable.
          </span>
        </p>
        <p className='c21 c17'>
          <span className='c2'></span>
        </p>
        <p className='c0'>
          <span className='c9'>
            15.8 This Agreement, and any documents referred to in it, constitutes the whole agreement between the parties and supersedes all previous
            agreements between the parties relating to its subject matter (including any Customer purchase orders) save for where the parties have
            entered into a formal bespoke executed contract. Each party acknowledges that, in entering into this Agreement, it has not relied on, and
            shall have no right or remedy in respect of, any statement, representation, assurance or warranty (whether made negligently or innocently)
            (other than for breach of contract), as expressly provided in this Agreement. Each party represents and warrants that in entering into
            this Agreement it has not relied upon any oral or written statements, collateral or other warranties, assurances, representations or
            undertakings (or the failure or omission of the other party to make statements, assurances, representations or undertakings) (together
          </span>
          <span className='c5'>&ldquo;Pre-Contractual Statements&rdquo;</span>
          <span className='c2'>
            ) other than what is expressly set forth in this Agreement. Each party waives all rights and remedies which might otherwise be available
            to it in relation to such Pre-Contractual Statements, including any claim it was induced into entering into this Agreement or accepting
            its terms based on any Pre-Contractual Statements.
          </span>
        </p>
        <p className='c21 c17'>
          <span className='c2'></span>
        </p>
        <p className='c0'>
          <span className='c2'>
            15.9 Neither party may assign any of its rights or obligations under this Agreement without the prior written consent of the other party,
            except that either party may assign this Agreement as a whole without such consent to an entity of good standing (other than any direct
            competitor of the other party) capable of complying with the rights and obligations under this Agreement succeeding to all or
            substantially all of such assigning party&rsquo;s assets or business.
          </span>
        </p>
        <p className='c21 c17'>
          <span className='c2'></span>
        </p>
        <p className='c0'>
          <span className='c2'>
            15.10 A person who is not a party to this Agreement shall not have any rights under or in connection with it. No third party beneficiaries
            are created by this Agreement.
          </span>
        </p>
        <p className='c17 c21'>
          <span className='c2'></span>
        </p>
        <p className='c0'>
          <span className='c9'>15.11 All notices must be in English, in writing, addressed (a) in the case of ev.energy to</span>
          <span className='c9 c26'>&nbsp;</span>
          <a className='c9 c26 c30' href='mailto:pando@ev.energy'>
            pando@ev.energy
          </a>
          <span className='c2'>
            , and (b) in the case of Customer to the postal address or email address detailed in the Order Form, or such other address as either party
            has notified the other in accordance with this clause. All notices shall be deemed to have been given on receipt as verified by written or
            automated receipt or electronic log (as applicable).
          </span>
        </p>
        <p className='c21 c17'>
          <span className='c2'></span>
        </p>
        <p className='c0'>
          <span className='c2'>
            15.12 This Agreement, and any dispute or claim arising out of or in connection with it or its subject matter or formation (including
            non-contractual disputes or claims), shall be governed by, and construed in accordance with, the law of England and Wales. The parties
            irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim that arises out
            of, or in connection with, the Agreement or its subject matter or formation (including non-contractual disputes or claims).
          </span>
        </p>
        <p className='c21 c17'>
          <span className='c2'></span>
        </p>
        <p className='c4'>
          <span className='c2'>
            15.13 The Platform and/or Data Services &nbsp;and Additional Services ev.energy makes available, and derivatives thereof may be subject to
            export laws and regulations of the United Kingdom and other jurisdictions. Each party represents that it is not named on any U.K.
            government denied-party list. The Customer shall not permit Authorized Customer Employees or End Usersto access or use the Platform and/or
            Data Services or Additional Services in a U.K. embargoed country (currently Cuba, Iran, North Korea, Sudan, Syria, Russia or Crimea) or in
            violation of any U.K. export law or regulation.
          </span>
        </p>
        <p className='c6'>
          <span className='c5'>Appendix 1 - SLAs</span>
        </p>
        <p className='c0'>
          <span className='c2'>
            1.1 Ev.energy shall support End Users to use the Core App Monday to Friday 9am to 5pm (London time) (“Standard Customer Support Service”).
          </span>
        </p>
        <p className='c21 c17'>
          <span className='c2'></span>
        </p>
        <p className='c4'>
          <span className='c2'>1.2 The support provided by ev.energy shall comprise:</span>
        </p>
        <p className='c3'>
          <span className='c2'>(a) an email help desk to provide first-line technical support to End Users of the Core App;</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (b) a telephone help desk to provide first-line technical support to the Customer and their agents installing Hardware Devices;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (c) remote diagnosis and, where possible, correction of bugs and failures of the Core App, but <span className='c5'>not</span> where this
            relates to the Hardware Device or Firmware;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (d) promptly notify the Customer of any issues with the Hardware Device and/or Firmware that affect the delivery of the functionality of
            the Platform and/or Data Services; and
          </span>
        </p>
        <p className='c0'>
          <span className='c2'>
            (e) responding to the Customer, as soon as reasonably practicable, with all of the Customer’s reasonable requests for information or
            assistance.
          </span>
        </p>
        <p className='c21 c17'>
          <span className='c2'></span>
        </p>
        <p className='c3'>
          <span className='c2'>1.3 The Standard Customer Support Service shall meet the following service levels:</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (a) respond to all queries raised to{' '}
            <a className='c9 c26 c30' href='mailto:support@ev.energy'>
              support@ev.energy
            </a>{' '}
            within 3 business days, and
          </span>
        </p>
        <p className='c0'>
          <span className='c2'>(b) respond to all inbound telephone calls within 4 business hours.</span>
        </p>
        <p className='c21 c17'>
          <span className='c2'></span>
        </p>
        <p className='c3'>
          <span className='c2'>
            1.4 Ev.energy shall have no obligation to provide the Standard Customer Support Services where faults arise from:
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (a) misuse, incorrect use of or damage to the Platform and/or Data Services from whatever cause (other than any act or omission by the
            eMSP), including failure or fluctuation of electrical power;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(b) failure to maintain the environmental conditions to use the Platform and/or Data Services;</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (c) use of the Platform and/or Data Services in combination with any equipment or software not provided by ev.energy or not designated by
            ev.energy for use with any part of the Platform and/or Data Services, or any fault in any such equipment or software;
          </span>
        </p>
        <p className='c3'>
          <span className='c2'>(d) any breach of the Customer’s obligations under this agreement howsoever arising;</span>
        </p>
        <p className='c3'>
          <span className='c2'>
            (e) any modification to the Platform and/or Data Services or Hardware Device or Firmware not authorised by ev.energy; or
          </span>
        </p>
        <p className='c0'>
          <span className='c2'>(f) any error failure or fault caused by or because of the Hardware Device and / or its Firmware.</span>
        </p>
        <p className='c21 c17'>
          <span className='c2'></span>
        </p>
        <p className='c0'>
          <span className='c2'>
            1.5 Ev.energy may make changes to the Standard Customer Support Services, provided such changes do not have a material adverse effect on
            the Customer’s business operations.
          </span>
        </p>
        <p className='c21 c17'>
          <span className='c2'></span>
        </p>
        <p className='c4'>
          <span className='c2'>1.6 Ev.energy will not provide or be under an obligation to provide on-site support services.</span>
        </p>
      </div>
    </div>
  );
};
