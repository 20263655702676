import type { ReactNode, SyntheticEvent, JSX } from 'react';
import { useCallback } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm, FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import type { FieldValues } from 'react-hook-form/dist/types/fields';
import type { ObjectSchema } from 'joi';
import { config } from '@utils/config';
import type { DefaultValues, Mode, SubmitErrorHandler } from 'react-hook-form/dist/types/form';
import { joiResolver } from '@hookform/resolvers/joi';

interface Props<SubmitData extends FieldValues> {
  children: ReactNode | ReactNode[];
  defaultValues?: DefaultValues<SubmitData> | undefined;
  onSubmit: SubmitHandler<SubmitData>;
  onInvalid?: SubmitErrorHandler<SubmitData>;
  resetOnSubmit?: boolean;
  schema: ObjectSchema<SubmitData>;
  reValidateMode?: Exclude<Mode, 'onTouched' | 'all'>;
}

export const Form = <FormData extends FieldValues>({
  defaultValues,
  children,
  onSubmit,
  onInvalid,
  resetOnSubmit = false,
  schema,
  reValidateMode = 'onChange',
}: Props<FormData>): JSX.Element => {
  const methods = useForm<FormData>({ defaultValues, reValidateMode, resolver: joiResolver(schema) });

  const handleSubmit = useCallback(
    async (event: SyntheticEvent) => {
      await methods.handleSubmit(
        data => {
          onSubmit(data, event);
          resetOnSubmit && methods.reset();
        },
        errors => onInvalid && onInvalid(errors, event),
      )(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resetOnSubmit],
  );

  const handleReset = useCallback(() => {
    methods.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        {config.isDev && <DevTool control={methods.control} />}
        {children}
      </form>
    </FormProvider>
  );
};
