// using relative path to avoid running unit tests issues
import { ChargerFirmwareStatus, ChargerFirmwareTempStatus } from '../../services/types/charger/charger.api-response.types';

export type ChargerFirmwareTranslationKey =
  | 'charger-firmware.update-firmware'
  | 'charger-firmware.press-button-to-update'
  | 'charger-firmware.new-version-available'
  | 'charger-firmware.current-firmware-version'
  | 'charger-firmware.status'
  | 'charger-firmware.status-description'
  | 'charger-firmware.charger-charging'
  | 'charger-firmware.charger-offline'
  | 'charger-firmware.charger-cant-manage-firmware'
  | 'charger-firmware.last-update'
  | 'charger-firmware.firmware-up-to-date'
  | 'charger-firmware.firmware-status-description'
  | ChargerFirmwareStatus
  | ChargerFirmwareTempStatus;

export const ChargerFirmwareTranslationEN: Record<
  ChargerFirmwareTranslationKey,
  string | Record<ChargerFirmwareStatus | ChargerFirmwareTempStatus, string>
> = {
  'charger-firmware.current-firmware-version': 'Current Firmware Version',
  'charger-firmware.last-update': 'Last Update',
  'charger-firmware.new-version-available': 'New Firmware Version Available:',
  'charger-firmware.status-description': 'Status Description',
  'charger-firmware.press-button-to-update': 'Press the button below to update the Firmware',
  'charger-firmware.status': 'Status',
  'charger-firmware.update-firmware': 'Update Firmware',
  'charger-firmware.firmware-up-to-date': 'Firmware is up to date',
  'charger-firmware.charger-offline': 'Charger is offline',
  'charger-firmware.charger-charging': 'Charging',
  'charger-firmware.charger-cant-manage-firmware': "Can't manage charger firmware",
  [ChargerFirmwareTempStatus.Idle]: 'Idle',
  [ChargerFirmwareTempStatus.Scheduled]: 'Scheduled',
  [ChargerFirmwareTempStatus.InProgress]: 'In progress',
  [ChargerFirmwareTempStatus.Success]: 'Success',
  [ChargerFirmwareTempStatus.Failure]: 'Failure',
  [ChargerFirmwareTempStatus['EVSE Retrying']]: 'Retrying',
  [ChargerFirmwareStatus.DownloadFailed]: 'Download Failed',
  [ChargerFirmwareStatus.DownloadPaused]: 'Download Paused',
  [ChargerFirmwareStatus.DownloadScheduled]: 'Download Scheduled',
  [ChargerFirmwareStatus.InstallRebooting]: 'Install Rebooting',
  [ChargerFirmwareStatus.InstallScheduled]: 'Install Scheduled',
  [ChargerFirmwareStatus.InstallVerificationFailed]: 'Install Verification Failed',
  [ChargerFirmwareStatus.InstallationFailed]: 'Installation Failed',
  [ChargerFirmwareStatus.InvalidSignature]: 'Invalid Signature',
  [ChargerFirmwareStatus.SignatureVerified]: 'Signature Verified',
  [ChargerFirmwareStatus.Downloaded]: 'Downloaded',
  [ChargerFirmwareStatus.Downloading]: 'Downloading',
  [ChargerFirmwareStatus.Installed]: 'Installed',
  [ChargerFirmwareStatus.Installing]: 'Installing',
  [ChargerFirmwareStatus.Idle]: 'Idle',
  'charger-firmware.firmware-status-description': {
    [ChargerFirmwareTempStatus.Idle]: 'Charge Point is not performing firmware update related tasks.',
    [ChargerFirmwareTempStatus.Scheduled]: 'Update of a new firmware has been scheduled.',
    [ChargerFirmwareTempStatus.InProgress]: 'Firmware is being installed.',
    [ChargerFirmwareTempStatus.Success]: 'New firmware has successfully been installed in Charge Point.',
    [ChargerFirmwareTempStatus.Failure]: 'Update of a new firmware has failed.',
    [ChargerFirmwareTempStatus['EVSE Retrying']]: 'Firmware update retrying.',
    [ChargerFirmwareStatus.DownloadFailed]: 'Charge Point failed to download firmware.',
    [ChargerFirmwareStatus.DownloadPaused]: 'Downloading has been paused.',
    [ChargerFirmwareStatus.DownloadScheduled]: 'Downloading of new firmware has been scheduled.',
    [ChargerFirmwareStatus.InstallRebooting]:
      'Charge Point is about to reboot to activate new firmware. This status MAY be omitted if a reboot is an integral part of the installation and cannot be reported separately.',
    [ChargerFirmwareStatus.InstallScheduled]: 'Installation of the downloaded firmware is scheduled.',
    [ChargerFirmwareStatus.InstallVerificationFailed]:
      'Verification of the new firmware (e.g. using a checksum or some other means) has failed and installation will not proceed.',
    [ChargerFirmwareStatus.InstallationFailed]: 'Installation of new firmware has failed.',
    [ChargerFirmwareStatus.InvalidSignature]: 'The firmware signature is not valid.',
    [ChargerFirmwareStatus.SignatureVerified]: 'Provide signature successfully verified.',
    [ChargerFirmwareStatus.Downloaded]: 'New firmware has been downloaded by Charge Point.',
    [ChargerFirmwareStatus.Downloading]: 'Firmware is being downloaded.',
    [ChargerFirmwareStatus.Installed]: 'New firmware has successfully been installed in Charge Point.',
    [ChargerFirmwareStatus.Installing]: 'Firmware is being installed.',
    [ChargerFirmwareStatus.Idle]: 'Charge Point is not performing firmware update related tasks.',
  },
};
