import type { CircularProgressProps } from '@mui/material/CircularProgress';
import CircularProgress from '@mui/material/CircularProgress';
import type { CSSProperties, FC } from 'react';
import { LoaderContainer } from '@components/core/Loader/Loader.style';
import { useTranslation } from 'react-i18next';

export interface Props extends CircularProgressProps {
  position?: CSSProperties['position'];
  size?: number;
}

export const Loader: FC<Props> = ({ position, ...props }) => {
  const { t } = useTranslation();

  return (
    <LoaderContainer position={position}>
      <CircularProgress {...props} aria-label={t('action.loading')!} />
    </LoaderContainer>
  );
};
