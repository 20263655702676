export enum FontSize {
  XXXXXXXL = '4rem', // 64px
  XXXXXXL = '3.5rem', // 56px
  XXXXXL = '3rem', // 48px
  XXXXL = '2.5rem', // 40px
  XXXL = '2.125rem', // 34px
  XXL = '1.875rem', // 30px h1
  XL = '1.5rem', // 24px h2
  L = '1.25rem', // 20px h3
  M = '1.125rem', // 18px h4
  Default = '1rem', // 16px default
  S = '0.875rem', // 14px h5
  XS = '0.8125rem', // 13px h6
  XXS = '0.6875rem', // 11px
  XXXS = '0.5rem', // 8px
}
