import { styled } from '@mui/material/styles';
import MuiDialog from '@mui/material/Dialog';
import { theme } from '@/theme';
import { Color } from '@shared/styles/color.enum';

export const StyledDialog = styled(MuiDialog)`
  .MuiPaper-root {
    padding: ${theme.spacing(4)};
    box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1);
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    ${theme.breakpoints.down('sm')} {
      margin: 0;
      max-height: 100%;
      border-radius: 0;
      width: 100vw;
      height: 100vh;
      padding: ${theme.spacing(5)} ${theme.spacing(3)};
      overflow: auto;
    }
  }

  .MuiBackdrop-root {
    background: ${Color.BackdropBackground};
  }
`;
