import { useLocalStorage } from '@hooks/useLocalStorage';
import { LocalStorageItemKey, LocalStorageItemType } from '@utils/local-storage';
import type { Language } from '@i18n/language.enum';
import { currentLanguage } from '@/i18n';
import type { TimeZone } from '@shared/data/timezones';
import { getTimeZoneByUtc, getTimeZoneByUniqueId, getUniqueTimeZoneId } from '@shared/data/timezones';
import { useEffect, useState } from 'react';

export const useUserPreferences = () => {
  const [lang, setLang] = useLocalStorage<Language>(LocalStorageItemType.Preferences, LocalStorageItemKey.Language, currentLanguage as Language);

  const initialTimeZone = getTimeZoneByUtc(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [timeZoneUniqueId, setTimeZoneUniqueId] = useLocalStorage<string | undefined>(
    LocalStorageItemType.Preferences,
    LocalStorageItemKey.TimeZone,
    initialTimeZone ? getUniqueTimeZoneId(initialTimeZone) : undefined,
  );

  const [timeZone, setTimeZone] = useState<TimeZone | undefined>(timeZoneUniqueId ? getTimeZoneByUniqueId(timeZoneUniqueId) : undefined);

  useEffect(() => {
    if (timeZone) {
      setTimeZoneUniqueId(getUniqueTimeZoneId(timeZone));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeZone]);

  return { lang, setLang, timeZone, setTimeZone };
};
