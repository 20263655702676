export enum ChargerConnectivity {
  Online = 'Online',
  Offline = 'Offline',
}

export enum ChargerLockMode {
  UNLOCKED = 'UNLOCKED',
  SMART = 'SMART',
  LOCKED = 'LOCKED',
}

export enum ChargerStage {
  CREATED_LINKED = 'CREATED_LINKED',
  INSTALLED = 'INSTALLED',
  COMMISSIONED = 'COMMISSIONED',
  DECOMMISSIONED = 'DECOMMISSIONED',
  UNKNOWN = 'UNKNOWN',
  PROBABLY_INSTALLED = 'PROBABLY_INSTALLED',
  UNPAIRED = 'UNPAIRED',
}

export enum ChargerStatus {
  CONNECTED = 'CONNECTED',
  CHARGING = 'CHARGING',
  DISCONNECTED = 'DISCONNECTED',
  ERROR = 'ERROR',
  UNPROVISIONED = 'UNPROVISIONED',
  OFFLINE = 'OFFLINE',
}

export enum SignalStrengthStatus {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export interface ChargerVehicleApiResponse {
  carDataAvailability?: boolean | null;
  id: string;
  lastLogged?: string | null;
  lastSampled?: string | null;
  make?: string | null;
  makeIcon?: string | null;
  model?: string | null;
  readyByTime?: string | null;
  smartChargingEnabled?: boolean | null;
  stateOfCharge: number | null;
  trimId?: string | null;
}

export interface Location {
  latitude: string;
  longitude: string;
}

export interface LatestLiveData {
  sampledAt: string; // iso date
  meterReading?: number;
  chargeRateWatts?: number;
  signalStrength?: number;
  signalStrengthStatus?: SignalStrengthStatus;
}

export interface ChargerApiResponse {
  // email: string | null;
  address?: string | null;
  chargerStage: {
    status: ChargerStage | null;
    date: string | null; // iso date
  } | null;
  chargerStatus?: ChargerStatus | null;
  connectivity: ChargerConnectivity;
  controllable: boolean;
  firmware?: string | null;
  firstName?: string | null;
  id: string;
  isOCPP: boolean;
  lastName?: string | null;
  location: Location;
  lockMode?: ChargerLockMode | null;
  make?: string;
  model?: string;
  modelId: string;
  postcode?: string | null;
  secondaryTariffId?: string | null;
  serialNumber?: string;
  siteId?: number;
  tariffId: string;
  userId: string;
  username?: string;
  vehicles?: ChargerVehicleApiResponse[];
}

export interface ChargerDetailsApiResponse extends Omit<ChargerApiResponse, 'firstName' | 'lastName' | 'username'> {
  latestLiveData?: LatestLiveData | null;
}

export interface ChargerConnectionReliabilityApiResponse {
  connectionReliability?: number;
}

export interface ChargerFirmwareDetailsApiResponse {
  currentFirmware?: ChargerFirmwareApiResponse;
  latestFirmware?: ChargerFirmwareApiResponse;
  updateAvailable?: boolean;
  canUpdateNow?: boolean; // evse online & not charging
  cannotUpdateReason?: ChargerCannotUpdateFirmwareReason;
  updateStatus?: ChargerFirmwareUpdateStatusApiResponse;
  canRetry?: boolean;
  lastSuccessfulUpdate?: string;
}

export interface ChargerFirmwareApiResponse {
  id?: number;
  version?: string;
  publicVersion?: string;
  supportUrl?: string;
  changeLog?: string;
}

export interface ChargerFirmwareUpdateStatusApiResponse {
  id: number;
  status?: ChargerFirmwareStatus | ChargerFirmwareTempStatus;
  started: string;
  firmware: ChargerFirmwareApiResponse;
}

export enum ChargerCannotUpdateFirmwareReason {
  CANT_MANAGE_FIRMWARE = 'CANT_MANAGE_FIRMWARE',
  EVSE_IS_OFFLINE = 'EVSE_IS_OFFLINE',
  EVSE_IN_CHARGING_STATE = 'EVSE_IN_CHARGING_STATE',
}

export enum ChargerFirmwareStatus {
  Idle = 'Idle',

  // Success
  Installed = 'Installed',

  // Intermediate
  Downloaded = 'Downloaded',
  DownloadFailed = 'Download Failed',
  Downloading = 'Downloading',
  DownloadScheduled = 'Download Scheduled',
  DownloadPaused = 'Download Paused',
  Installing = 'Installing',
  InstallRebooting = 'Install Rebooting',
  InstallScheduled = 'Install Scheduled',
  SignatureVerified = 'Signature Verified',

  // Failure
  InstallationFailed = 'Installation Failed',
  InstallVerificationFailed = 'Install Verification Failed',
  InvalidSignature = 'Invalid Signature',
}

export enum ChargerFirmwareTempStatus {
  Idle = 'IDLE',

  // Success
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',

  // Intermediate
  Scheduled = 'SCHEDULED',
  'EVSE Retrying' = 'EVSE_RETRYING',

  // Failure
  Failure = 'FAILURE',
}

export enum ChargerCommandFailureReason {
  EVSE_NOT_FOUND = 'EVSE_NOT_FOUND',
  EVSE_IS_OFFLINE = 'EVSE_IS_OFFLINE',
  COMMAND_NOT_SUPPORTED = 'COMMAND_NOT_SUPPORTED',
  VEHICLE_IS_NOT_CONNECTED = 'VEHICLE_IS_NOT_CONNECTED',
  COMMAND_DISALLOWED_FOR_EVSE_STATE = 'COMMAND_DISALLOWED_FOR_EVSE_STATE',
}

export interface ChargerCommandFailureResponse {
  failureReason: ChargerCommandFailureReason;
}

export interface ChargerMakeApiResponse {
  id: number;
  make: string;
  icon: string;
}

export interface ChargerModelApiResponse {
  id: number;
  name: string;
  manufacturer: string;
  outputPower?: number;
}
