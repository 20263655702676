import type { FC } from 'react';
import { StyledDialog } from '@components/core/Dialog/Dialog.style';
import { useDialogStore } from '@stores/dialog.store';

export const Dialog: FC = () => {
  const { dialog, opened, hideDialog } = useDialogStore();

  return (
    <StyledDialog open={opened} onClose={hideDialog} data-testid={`ev-dialog-${dialog?.name}`}>
      {dialog?.content}
    </StyledDialog>
  );
};
