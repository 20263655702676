// don't use alias here to be able to generate translations JSON
import { Language } from '../language.enum';
import type { Resource } from 'i18next';
import type { ValidationTranslationKey } from './validation';
import { ValidationTranslationEN } from './validation';
import type { ActionTranslationKey } from './action';
import { ActionTranslationEN } from './action';
import type { DataAttributesTranslationKey } from './data-attributes';
import { DataAttributesTranslationEN } from './data-attributes';
import type { AuthLayoutTranslationKey } from './auth-layout';
import { AuthLayoutTranslationEN } from './auth-layout';
import type { InputAttributesTranslationKey } from './input-attributes';
import { InputAttributesTranslationEN } from './input-attributes';
import type { DashboardLayoutTranslationKey } from './dashboard-layout';
import { DashboardLayoutTranslationEN } from './dashboard-layout';
import type { ToastTranslationKey } from './toast';
import { ToastTranslationEN } from './toast';
import type { DashboardBreadcrumbsTranslationKey } from './dashboard-breadcrumbs';
import { DashboardBreadcrumbsTranslationEN } from './dashboard-breadcrumbs';
import type { DataValueTranslationKey } from './value';
import { DataValueTranslationEN } from './value';
import type { DashboardExpandableRowTranslationKey } from './dashboard-expandable-row';
import { DashboardExpandableRowTranslationEN } from './dashboard-expandable-row';
import type { ChargerAttributesTranslationKey } from './charger-attributes';
import { ChargerAttributesTranslationEN } from './charger-attributes';
import type { ChargerDetailsTranslationKey } from './charger-details';
import { ChargerDetailsTranslationEN } from './charger-details';
import type { ChargingSessionsAttributesTranslationKey } from './charging-session-attributes';
import { ChargingSessionAttributesTranslationEN } from './charging-session-attributes';
import type { DescriptionTranslationKey } from './description';
import { DescriptionTranslationEN } from './description';
import type { OrganisationAttributesTranslationKey } from './organisation-attributes';
import { OrganisationAttributesTranslationEN } from './organisation-attributes';
import type { UsersInvitationTranslationKey } from './users-invitation';
import { UsersInvitationTranslationEN } from './users-invitation';
import type { ChargerSettingAttributesTranslationKey } from './charger-setting-attributes';
import { ChargerSettingAttributesTranslationEN } from './charger-setting-attributes';
import type { UsersListTranslationKey } from './users-list';
import { UsersListTranslationEN } from './users-list';
import type { CommissioningLayoutTranslationKey } from './commissioning-layout';
import { CommissioningLayoutTranslationEN } from './commissioning-layout';
import type { SettingsTranslationKey } from './settings';
import { SettingsTranslationEN } from './settings';
import type { SiteDetailsTranslationKey } from './site-details';
import { SiteDetailsTranslationEN } from './site-details';
import type { DriverDetailsTranslationKey } from './driver-details';
import { DriverDetailsTranslationEN } from './driver-details';
import type { ReleaseNotesTranslationKey } from './release-notes';
import { ReleaseNotesTranslationEN } from './release-notes';
import type { VehiclesListTranslationKey } from './vehicles-list';
import { VehiclesListTranslationEN } from './vehicles-list';
import type { DriversInvitationTranslationKey } from './drivers-invitation';
import { DriversInvitationTranslationEN } from './drivers-invitation';
import type { SiteCreationTranslationKey } from './site-creation';
import { SiteCreationTranslationEN } from './site-creation';
import type { DownloadCSVTranslationKey } from './download.csv';
import { DownloadCSVTranslationEN } from './download.csv';
import type { TooltipTranslationKey } from './tooltip';
import { TooltipTranslationEN } from './tooltip';
import type { ChargerFirmwareTranslationKey } from './charger-firmware';
import { ChargerFirmwareTranslationEN } from './charger-firmware';
import type { DriverActivateRfidTranslationKey } from './driver-activate-rfid';
import { DriverActivateRfidTranslationEN } from './driver-activate-rfid';
import type { ChargersListTranslationKey } from './chargers-list';
import { ChargersListTranslationEN } from './chargers-list';
import type { ChargerTestingLayoutTranslationKey } from './charger-testing-layout';
import { ChargerTestingLayoutTranslationEN } from './charger-testing-layout';
import type { SiteSharingTranslationKey } from './site-sharing';
import { SiteSharingTranslationEN } from './site-sharing';
import type { OrganisationTranslationKey } from './organisation';
import { OrganisationTranslationEN } from './organisation';
import type { AddChargersTranslationKey } from './add-chargers';
import { AddChargersTranslationEN } from './add-chargers';
import type { LanguageTranslationKey } from './language';
import { LanguageTranslationEN } from './language';
import type { VehiclesOnboardingTranslationKey } from './vehicles-onboarding';
import { VehiclesOnboardingTranslationEN } from './vehicles-onboarding';

import enJson from '../en.json';
import itJson from '../it.json';
import frJson from '../fr.json';
import esJson from '../es.json';
import nlJson from '../nl.json';

export type TranslationKey =
  | ActionTranslationKey
  | AuthLayoutTranslationKey
  | ChargerAttributesTranslationKey
  | ChargerDetailsTranslationKey
  | ChargerSettingAttributesTranslationKey
  | ChargingSessionsAttributesTranslationKey
  | DashboardBreadcrumbsTranslationKey
  | DashboardExpandableRowTranslationKey
  | DashboardLayoutTranslationKey
  | DataAttributesTranslationKey
  | DataValueTranslationKey
  | DescriptionTranslationKey
  | InputAttributesTranslationKey
  | OrganisationAttributesTranslationKey
  | ToastTranslationKey
  | UsersInvitationTranslationKey
  | UsersListTranslationKey
  | ValidationTranslationKey
  | CommissioningLayoutTranslationKey
  | SettingsTranslationKey
  | SiteDetailsTranslationKey
  | DriverDetailsTranslationKey
  | ReleaseNotesTranslationKey
  | DriversInvitationTranslationKey
  | SiteCreationTranslationKey
  | DownloadCSVTranslationKey
  | VehiclesListTranslationKey
  | DriverActivateRfidTranslationKey
  | TooltipTranslationKey
  | ChargerFirmwareTranslationKey
  | ChargersListTranslationKey
  | SiteSharingTranslationKey
  | ChargerTestingLayoutTranslationKey
  | AddChargersTranslationKey
  | OrganisationTranslationKey
  | LanguageTranslationKey
  | VehiclesOnboardingTranslationKey;

export const TranslationEN: Record<TranslationKey, string | string[] | Record<string, string>> = {
  ...ActionTranslationEN,
  ...AuthLayoutTranslationEN,
  ...ChargerAttributesTranslationEN,
  ...ChargerDetailsTranslationEN,
  ...ChargerSettingAttributesTranslationEN,
  ...ChargingSessionAttributesTranslationEN,
  ...DashboardBreadcrumbsTranslationEN,
  ...DashboardExpandableRowTranslationEN,
  ...DashboardLayoutTranslationEN,
  ...DataAttributesTranslationEN,
  ...DataValueTranslationEN,
  ...DescriptionTranslationEN,
  ...InputAttributesTranslationEN,
  ...OrganisationAttributesTranslationEN,
  ...ToastTranslationEN,
  ...UsersInvitationTranslationEN,
  ...UsersListTranslationEN,
  ...ValidationTranslationEN,
  ...CommissioningLayoutTranslationEN,
  ...SettingsTranslationEN,
  ...SiteDetailsTranslationEN,
  ...DriverDetailsTranslationEN,
  ...ReleaseNotesTranslationEN,
  ...VehiclesListTranslationEN,
  ...DriversInvitationTranslationEN,
  ...SiteCreationTranslationEN,
  ...DownloadCSVTranslationEN,
  ...TooltipTranslationEN,
  ...ChargerFirmwareTranslationEN,
  ...DriversInvitationTranslationEN,
  ...DriverActivateRfidTranslationEN,
  ...ChargersListTranslationEN,
  ...ChargerTestingLayoutTranslationEN,
  ...SiteSharingTranslationEN,
  ...OrganisationTranslationEN,
  ...AddChargersTranslationEN,
  ...LanguageTranslationEN,
  ...VehiclesOnboardingTranslationEN,
};

export interface Locales {
  translation: Record<TranslationKey, string>;
}

export const resources: Resource = {
  [Language.EN]: {
    translation: enJson,
  },
  [Language.ES]: {
    translation: esJson,
  },
  [Language.FR]: {
    translation: frJson,
  },
  [Language.IT]: {
    translation: itJson,
  },
  [Language.NL]: {
    translation: nlJson,
  },
};
