import type { OrganisationApiResponse } from '@services/types/organisation/organisation.api-response.types';
import type { Money } from '@shared/types/interfaces/api-types.interface';

export interface SiteApiResponse {
  address?: string;
  id: number;
  modifiedAt?: string;
  name: string;
  numberOfEVSEs?: number;
  organisation: SiteOrganisation;
}

interface SiteOrganisation extends Pick<OrganisationApiResponse, 'id' | 'name'> {
  contactEmail?: string;
}

export interface SiteChargingSessionStatisticsApiResponse {
  // totalRevenue?: Money; // TODO uncomment when API implemented
  totalRevenue?: number | Money;
  totalCount?: number;
  totalEnergykWh?: number;
}

export interface SiteDetailsApiResponse extends Pick<SiteApiResponse, 'id' | 'name' | 'organisation' | 'numberOfEVSEs' | 'modifiedAt' | 'address'> {}

export type SiteShareApiPermission = 1 | 2 | 3;
export enum SiteSharePermission {
  Maintainer = '1',
  Write = '2',
  Read = '3',
}

export const mapSiteSharePermission = (value: SiteShareApiPermission): SiteSharePermission => {
  switch (value) {
    case 1:
      return SiteSharePermission.Maintainer;
    case 2:
      return SiteSharePermission.Write;
    case 3:
      return SiteSharePermission.Read;
  }
};

export const getSiteSharePermissionKeyByValue = (value: SiteSharePermission) => {
  const indexOfS = Object.values(SiteSharePermission).indexOf(value);

  return Object.keys(SiteSharePermission)[indexOfS];
};

export interface SiteShareApiResponse {
  id: number;
  objectId: string;
  invitedOrganisation?: string;
  email: string;
  invitationDate: string;
  permission: SiteShareApiPermission;
}
