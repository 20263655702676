import { stripHtml } from 'string-strip-html';
import { renderToString } from 'react-dom/server';
import type { JSX } from 'react';

export const getPlainTextFromJSX = (node: JSX.Element) => {
  return stripHtml(renderToString(node)).result;
};

export const firstLetterCase = (str: string) =>
  str
    .toLowerCase()
    .replace(/\b(\w)/g, s => s.toUpperCase())
    .replace(/[-_]/g, ' ');

export const buildFullName = (firstName?: string | null, lastName?: string | null): string | null =>
  !firstName && !lastName ? null : `${firstName || ''} ${lastName || ''}`;

export const generateHashCode = (str: string): string =>
  str
    .split('')
    .reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0)
    .toString();

export const generateRandomString = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

const special = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
];
const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

export const stringifyNumber = (n: number) => {
  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + 'ieth';

  return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
};
