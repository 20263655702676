import { useSearchParams } from 'react-router-dom';
import { decodeJwtPayload } from '@utils/jwt';

export type SearchParams = {
  token: string;
};

interface SsoExistingUserRedirectPayload extends Record<string, string> {
  refresh_token: string;
  exp: string; // iso date
}

export const useSsoLoginPayload = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token' as keyof SearchParams)!;
  const isSsoRedirect = Boolean(token);
  if (!isSsoRedirect) {
    return null;
  }

  const payload = decodeJwtPayload<SsoExistingUserRedirectPayload>(token);
  if (!payload) {
    return null;
  }

  return payload;
};
