import { styled } from '@mui/material/styles';
import { theme } from '@/theme';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing(8)} auto 0 auto;
  max-width: 400px;
  align-items: center;
  ${theme.breakpoints.down('sm')} {
    margin: ${theme.spacing(2)} auto 0 auto;
  }
`;

export const GoBackButtonContainer = styled('div')`
  display: flex;
`;
