import Grid from '@mui/material/Grid/Grid';
import type { FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { theme } from '@/theme';
import { styled } from '@mui/material/styles';
import { NAVIGATION_DRAWER_WIDTH } from '@components/layouts/DashboardLayout/NavigationDrawer/NavigationDrawer.style';

const OutletContainer = styled(Grid)<{ component: 'main' }>`
  padding: ${theme.spacing(5)} ${theme.spacing(7)};
  width: calc(100% - ${NAVIGATION_DRAWER_WIDTH}px - ${theme.spacing(8)});
  position: relative;
  flex: 1;
  min-height: 100vh;
  ${theme.breakpoints.down('sm')} {
    min-height: auto;
    padding: ${theme.spacing(1.5)};
    overflow: auto;
  }
`;

export const DashboardTableSublayout: FC<PropsWithChildren<unknown>> = () => {
  return (
    <OutletContainer item xs component='main'>
      <Outlet />
    </OutletContainer>
  );
};
