import type { FetchErrorResponse } from '@services/api.service';
import { ApiService } from '@services/api.service';
import { API_ROUTES, ApiMutationKey } from '@shared/constants/api.constants';
import { useMutation } from 'react-query';
import { Header } from '@shared/types/enums/header.enum';
import type { UserStoreState } from '@stores/user.store';
import { useUserStore } from '@stores/user.store';
import { findUserShort } from '@services/queries/auth/find-user-short.query';
import type { AuthTokens } from '@services/types/auth/auth.types';
import { config } from '@utils/config';
import type { AuthTokensApiResponse } from '@services/types/auth/auth.api-response.types';
import type { AxiosResponse } from 'axios';
import { AuthApiMapper } from '@services/mappers/auth.api.mapper';
import { logger } from '@utils/logger';
import { useAuthTokensLocalStorageData } from '@hooks/auth/useAuthTokensLocalStorageData';

interface LoginData {
  username: string;
  password: string;
}

export interface LoginRequestBody extends Record<string, string>, LoginData {
  grant_type: 'password';
  client_id: string;
  client_secret: string;
}

export const loginCommand = async (loginData: LoginData): Promise<AuthTokens> => {
  const data: LoginRequestBody = {
    ...loginData,
    grant_type: 'password',
    client_id: config.auth.clientId,
    client_secret: config.auth.clientSecret,
  };

  const result = await ApiService.post<AuthTokensApiResponse, AxiosResponse<AuthTokensApiResponse>, URLSearchParams>(
    API_ROUTES.Login,
    new URLSearchParams(data),
    {
      headers: {
        [Header.ContentType]: 'application/x-www-form-urlencoded',
      },
    },
  );

  return AuthApiMapper.toAuthTokens(result.data);
};

export const useLogin = (shouldSaveRefreshToken: boolean) => {
  const userStore = useUserStore();
  const { setAccessToken, setRefreshToken, removeRefreshToken } = useAuthTokensLocalStorageData();

  return useMutation<AuthTokens, FetchErrorResponse, LoginData>(ApiMutationKey.Login, (data: LoginData) => loginCommand(data), {
    onSuccess: handleAuthTokensResponse(userStore, setAccessToken, setRefreshToken, removeRefreshToken, shouldSaveRefreshToken),
  });
};

type TokenSetter = (token: string) => void;

export const handleAuthTokensResponse =
  (
    userStore: UserStoreState,
    setAccessToken: TokenSetter,
    setRefreshToken: TokenSetter,
    removeRefreshToken: () => void,
    shouldSaveRefreshToken: boolean,
  ) =>
  async ({ refreshToken, accessToken }: AuthTokens) => {
    setAccessToken(accessToken);
    if (shouldSaveRefreshToken) {
      setRefreshToken(refreshToken);
    } else {
      removeRefreshToken();
    }

    ApiService.defaults.headers.common[Header.Authorization] = `Bearer ${accessToken}`;

    userStore.setIsUserLoading(true);
    const user = await findUserShort()
      .catch(error => {
        logger.error('Getting logged in user auth info error', { error });
      })
      .finally(() => userStore.setIsUserLoading(false));

    if (!user) {
      throw new Error('Missing user auth info');
    }

    userStore.setUser(user);
  };
