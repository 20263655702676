import { subscribeWithSelector } from 'zustand/middleware';
import { create } from 'zustand';
import type { UserShort } from '@services/types/user/user.types';

export interface UserStoreState {
  isUserLoading: boolean;
  user: UserShort | null;
  setIsUserLoading: (isUserLoading: boolean) => void;
  setUser: (user: UserShort | null) => void;
}

export const useUserStore = create<UserStoreState, [['zustand/subscribeWithSelector', never]]>(
  subscribeWithSelector(set => ({
    isUserLoading: true,
    user: null,
    setUser: user => set({ user }),
    setIsUserLoading: isUserLoading => set({ isUserLoading }),
  })),
);
