import { create } from 'zustand';
import type { JSX } from 'react';
import type { TranslationKey } from '@i18n/locales';

export interface RightDrawer {
  id: number;
  name: string;
  title: TranslationKey | null;
  content?: JSX.Element;
  width?: string | number;
}

interface RightDrawerStoreState {
  opened: boolean;
  drawer: RightDrawer | null;
  showRightDrawer: (drawer: Omit<RightDrawer, 'id'> | null) => void;
  hideRightDrawer: () => void;
}

export const useRightDrawerStore = create<RightDrawerStoreState>(set => ({
  opened: false,
  drawer: null,
  showRightDrawer: drawer => {
    set({ drawer: drawer ? { ...drawer, id: new Date().valueOf() } : null, opened: true });
  },
  hideRightDrawer: () => {
    set({ opened: false });
    setTimeout(() => set({ drawer: null }), 200);
  },
}));
