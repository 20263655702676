import { useCallback, useState } from 'react';
import { config } from '@utils/config';
import Hotjar from '@hotjar/browser';

export const useInitHotjar = () => {
  const hotjarConfig = config.analytics.hotjar;
  const [isInitialized, setIsInitialized] = useState(false);

  // github.com/hotjar/hotjar-js
  const initSentry = useCallback(() => {
    if (isInitialized) {
      return;
    }

    if (hotjarConfig.enabled && hotjarConfig.siteId && hotjarConfig.version) {
      Hotjar.init(hotjarConfig.siteId, hotjarConfig.version);
    }

    setIsInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isInitialized, initSentry] as const;
};
