export enum LocalStorageItemType {
  Auth = 'ev-energy-auth',
  NavigationDrawer = 'ev-energy-nav-drawer',
  CommissioningForm = 'ev-energy-commissioning',
  CreateOrganisationForm = 'ev-energy-create-organisation',
  Preferences = 'ev-energy-preferences',
}

export enum LocalStorageItemKey {
  AccessToken = 'access_token',
  RefreshToken = 'refresh_token',
  DesktopNavigationDrawerOpened = 'desktop_nav_drawer_opened',
  CommissioningFormData = 'data',
  SsoLoginUsed = 'sso-login-used',
  SsoLoginPartner = 'sso-login-partner',
  Displayed = 'displayed',
  OAuthState = 'oauth-state',
  Language = 'lang',
  TimeZone = 'time-zone',
}

export const getLocalStorageItemId = (type: LocalStorageItemType, key: LocalStorageItemKey | string) => `${type}_${key}`;

export const setLocalStorageItem = <Type>(type: LocalStorageItemType, key: LocalStorageItemKey | string, value: Type): void => {
  localStorage.setItem(getLocalStorageItemId(type, key), JSON.stringify(value));
};

export const getLocalStorageItem = <Type>(type: LocalStorageItemType, key: LocalStorageItemKey | string): Type | null => {
  try {
    const item = localStorage.getItem(getLocalStorageItemId(type, key));

    return item ? JSON.parse(item) : item;
  } catch {
    return null;
  }
};

export const removeLocalStorageItem = (type: LocalStorageItemType, key: LocalStorageItemKey | string): void =>
  localStorage.removeItem(getLocalStorageItemId(type, key));
