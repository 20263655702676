import type { OutlinedInputProps } from '@mui/material/OutlinedInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import type { FC, HTMLInputTypeAttribute, ReactNode, RefObject, FocusEventHandler } from 'react';
import { forwardRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { TranslationKey } from '@i18n/locales';
import { cx } from '@emotion/css';
import type { PaletteMode } from '@mui/material';
import { sharedStyles } from '@shared/styles/shared-classes';
import { EyeClosedIcon, EyeOpenedIcon } from '@assets/icons';
import type { Props as InfoTooltipProps } from '@components/core/Tooltip/variants/InfoTooltip/InfoTooltip';
import { InfoTooltip } from '@components/core/Tooltip/variants/InfoTooltip/InfoTooltip';
import { LabelContainer, styles } from '@components/core/TextField/TextField.style';

export interface Props
  extends Pick<
    OutlinedInputProps,
    | 'value'
    | 'onChange'
    | 'onBlur'
    | 'onFocus'
    | 'color'
    | 'size'
    | 'onClick'
    | 'onKeyDown'
    | 'disabled'
    | 'inputComponent'
    | 'autoFocus'
    | 'onMouseDown'
  > {
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  hasError?: boolean;
  helperTextId?: string;
  label?: TranslationKey;
  marginBottom?: number;
  name: string;
  paletteMode?: PaletteMode;
  placeholder?: TranslationKey;
  tooltip?: InfoTooltipProps;
  type?: HTMLInputTypeAttribute;
  forwardedRef?: RefObject<any>;
  className?: string;
}

export const TextField: FC<Props> = forwardRef(
  (
    {
      label,
      name,
      placeholder,
      helperTextId,
      paletteMode = 'light',
      hasError,
      startIcon,
      marginBottom,
      endIcon,
      tooltip,
      forwardedRef,
      className,
      ...props
    },
    ref,
  ) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);
    const { t } = useTranslation();
    const isPassword = props.type === 'password';
    const isDarkPaletteMode = paletteMode === 'dark';
    const id = `ev-input-${name}`;

    const endIconClassName = cx({
      [sharedStyles.icon]: true,
      [styles.icon(isDarkPaletteMode)]: true,
      [styles.iconActive]: passwordVisible,
      [styles.iconFocused(isDarkPaletteMode)]: hasFocus,
    });

    const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(e => {
      props.onFocus && props.onFocus(e);
      setHasFocus(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(e => {
      props.onBlur && props.onBlur(e);
      setHasFocus(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEndIcon = () => {
      switch (true) {
        case isPassword && !passwordVisible:
          return <EyeOpenedIcon className={endIconClassName} onClick={() => setPasswordVisible(true)} />;
        case isPassword && passwordVisible:
          return <EyeClosedIcon className={endIconClassName} onClick={() => setPasswordVisible(false)} />;
        default:
          return endIcon;
      }
    };

    const classNames = cx(className, {
      [isDarkPaletteMode ? styles.inputDark : styles.inputLight]: true,
      [isDarkPaletteMode && props.disabled ? styles.inputDarkDisabled : '']: true,
      [!isDarkPaletteMode && props.disabled ? styles.inputLightDisabled : '']: true,
      [hasError ? styles.inputError : styles.input(isDarkPaletteMode, marginBottom)]: true,
    });

    return (
      <>
        {label && (
          <LabelContainer>
            <InputLabel className={styles.label(isDarkPaletteMode)} htmlFor={id} data-testid={`${id}-label`}>
              {t(label)}
            </InputLabel>
            {tooltip && <InfoTooltip {...tooltip} hasFocus={hasFocus} />}
          </LabelContainer>
        )}
        <OutlinedInput
          {...props}
          ref={forwardedRef || ref}
          data-testid={id}
          aria-describedby={helperTextId}
          placeholder={placeholder ? t(placeholder)! : undefined}
          endAdornment={getEndIcon()}
          startAdornment={startIcon}
          type={isPassword && passwordVisible ? 'text' : props.type}
          className={classNames}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </>
    );
  },
);

TextField.displayName = 'TextField';
