import type { FallbackRender } from '@sentry/react/types/errorboundary';
import { NotFoundPage } from '@views/PageNotFound/PageNotFound';

export const GlobalErrorFallback: FallbackRender = () => {
  return (
    <div role='alert'>
      <NotFoundPage />
    </div>
  );
};
