import type { FC } from 'react';
import type { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';
import { useFindSiteDetails } from '@services/queries/site/find-site-details.query';

interface Props extends BreadcrumbComponentProps<'id'> {}

export const SiteDetailsBreadcrumbValue: FC<Props> = ({ match }) => {
  const { data } = useFindSiteDetails({ siteId: match.params.id });

  return <>{data ? data.name : '-'}</>;
};
