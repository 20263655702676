import { styled } from '@mui/material/styles';
import type { TooltipProps } from '@mui/material/Tooltip';
import MuiTooltip from '@mui/material/Tooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import { Color } from '@shared/styles/color.enum';
import type { Props } from './Tooltip';
import { FontSize } from '@shared/styles/font-size.enum';
import type { CSSProperties } from 'react';
import { theme } from '@/theme';

type StyledTooltipType = Omit<Props, 'title'> & Pick<TooltipProps, 'title' | 'arrow'>;

export const StyledTooltip = styled(({ className, ...props }: StyledTooltipType) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))<StyledTooltipType>(props => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: props.color === 'secondary' ? Color.SuccessBackground : Color.JungleGreen,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    background: props.color === 'secondary' ? Color.SuccessBackground : Color.JungleGreen,
    color: props.color === 'secondary' ? Color.JungleGreen : Color.White,
    ...getTooltipSizeRelatedProps(props.size),
  },
}));

const getTooltipSizeRelatedProps = (size: Props['size']): CSSProperties => {
  switch (size) {
    case 'small': {
      return {
        fontSize: FontSize.XXS,
        padding: theme.spacing(1.5),
      };
    }
    case 'large': {
      return {
        fontSize: FontSize.S,
        padding: theme.spacing(2),
      };
    }
    case 'medium':
    default: {
      return {
        fontSize: FontSize.XS,
        padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
      };
    }
  }
};
