import { useCallback, useState } from 'react';
import { findUserShort } from '@services/queries/auth/find-user-short.query';
import { ApiService } from '@services/api.service';
import { Header } from '@shared/types/enums/header.enum';
import { logger } from '@utils/logger';
import { useUserStore } from '@stores/user.store';
import type { UserShort } from '@services/types/user/user.types';
import { useAuthTokensLocalStorageData } from '@hooks/auth/useAuthTokensLocalStorageData';

export const useSetupAuth = () => {
  const userStore = useUserStore();
  const { accessToken } = useAuthTokensLocalStorageData();
  const [isSetup, setIsSetup] = useState(false);

  const setupAuth = useCallback(async () => {
    let user: UserShort | null = null;

    try {
      if (accessToken) {
        ApiService.defaults.headers.common[Header.Authorization] = `Bearer ${accessToken}`;
        user = await findUserShort();
      }
    } catch (error) {
      logger.error('App bootstrap error', { error });
    } finally {
      userStore.setUser(user);
      userStore.setIsUserLoading(false);
      setIsSetup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isSetup, setupAuth] as const;
};
