export const API_ROUTES = {
  // Auth
  Login: 'o/token/',
  SignUp: 'api/v1/b2busers/password-signup/',
  SignUpConfirm: 'api/v1/b2busers/create/',
  RefreshToken: 'o/token/',
  ResetPassword: 'rest-auth/b2b_password/reset/',
  ResetPasswordConfirm: 'rest-auth/password/reset/confirm/',
  FindUserInvitationTokenInfo: 'api/v1/b2busers/token-info/',
  VerifyOAuth: 'social-login/',

  // User
  FindUserShort: 'rest-auth/user/',
  FindB2BUserInfo: 'api/v1/b2busers/current-user-info/',
  FindB2BUsers: 'api/v1/b2busers/',
  FindB2BPendingUsersInvitations: 'api/v1/b2busers/invite',
  InviteUser: 'api/v1/b2busers/invite/',
  ResendUserInvitation: {
    1: 'api/v1/b2busers/invite/', // + userId
    2: '/resend/',
  },
  FindUsers: 'api/v1/users/',
  UpdateUserPermission: 'api/v1/b2busers/', // + userId

  // Organisation
  FindOrganisations: 'api/v1/organisations/',
  CreateOrganisation: 'api/v1/organisations/',
  UpdateOrganisation: 'api/v1/organisations/', // + orgId

  // Fleets
  FindFleets: 'api/v1/vehicle-management/fleets/',

  // Sites
  FindSites: 'api/v1/evse-management/sites/',
  FindSiteChargingSessionsStatistics: 'api/v1/charging-sessions/statistics/',
  CreateSite: 'api/v1/evse-management/sites/',
  AssignChargersToSite: {
    1: 'api/v1/evse-management/sites/', // + siteId
    2: '/add_evses/',
  },
  ShareSite: 'api/v1/grant-permission/',
  RevokeSiteShare: 'api/v1/grant-permission/', // + shareId
  ChangeSiteShareAccess: {
    1: 'api/v1/grant-permission/', // + shareId
    2: '/update_permission',
  },
  FindSiteShare: 'api/v1/grant-permission/',

  // Diver
  FindDrivers: {
    1: 'api/v1/sites/', // + siteId
    2: '/drivers/charging-sessions-summary',
  },
  FindDriverDetails: {
    1: 'api/v1/sites/', // + siteId
    2: '/drivers/', // + driverId
    3: '/charging-sessions-summary',
  },
  InviteDriver: 'api/v1/evse-management/site/rfid/',
  ActivateDriverRfid: 'api/v1/activate-rfid/', // + rfid
  FindPendingDriverInvitations: {
    1: 'api/v1/sites/', // + siteId
    2: '/drivers/invite/',
  },

  // Fleet Onboarding
  VehicleOnboardingRedirectUrl: '/api/v1/vehicle-management/authorization-requests/',

  // Chargers
  FindChargersExtra: 'api/v1/evse-management/evses-extra/',
  FindChargers: 'api/v1/evse-management/evses/',
  FindChargersConnectionReliability: {
    1: 'api/v1/evse-management/evses/', // + chargerId
    2: '/connection_reliability/',
  },
  FindChargerVehicles: 'api/v1/vehicle-management/vehicles/',
  FindChargingSessions: 'api/v1/charging-sessions/',
  FindChargerLogsCommands: {
    1: 'api/v1/evse-management/', // + chargerId
    2: 'command-logs/',
  },
  FindChargerLogsReceivedData: {
    1: 'api/v1/evse-management/', // + chargerId
    2: 'data-logs/',
  },
  FindChargerLogsLifecycle: {
    1: 'api/v1/evse-management/', // + chargerId
    2: 'lifecycle-logs/',
  },
  FindChargerLogsOcpp: {
    1: 'api/v1/evse-management/', // + chargerId
    2: '/ocpp-logs/',
  },
  FindChargerLogsOcppActions: 'api/v1/evse-management/ocpp-logs/actions/',
  FindChargerLogsOcppErrorTypes: 'api/v1/evse-management/ocpp-logs/error-codes/',
  FindChargerSettings: {
    1: 'api/v1/evse-management/', // + chargerId
    2: '/config/get/',
  },
  FindChargerSettingStatus: {
    1: 'api/v1/evse-management/', // + chargerId
    2: '/config/command-status/',
  },
  UpdateChargerSetting: {
    1: 'api/v1/evse-management/', // + chargerId
    2: '/config/set/',
  },
  FindChargerFirmwareDetails: 'api/v1/evse-management/firmware/', // + chargerId
  UpdateChargerFirmware: {
    1: 'api/v1/evse-management/firmware/', // + chargerId
    2: '/start/',
  },
  RebootCharger: {
    1: 'api/v1/evse-management/', // + chargerId
    2: '/commands/reboot/',
  },
  StartCharging: {
    1: 'api/v1/evse-management/', // + chargerId
    2: '/commands/start-charge/',
  },
  StopCharging: {
    1: 'api/v1/evse-management/', // + chargerId
    2: '/commands/stop-charge/',
  },
  AddChargers: 'api/v1/evse-management/evses/',
  CheckChargersCpids: 'api/v1/evse-management/evses/check_cpid/',
  FindChargersModels: 'api/v1/evse-management/models/',
  FindChargersMakes: 'api/v1/evse-management/makes/',
  OnboardCharger: 'api/v1/evse-management/authorization-requests/',

  // Commissioning
  FindCommissioningManufacturers: 'api/v1/evse-management/commissioning/manufacturers/',
  FindCommissionedChargerStatus: {
    1: 'api/v1/evse-management/commissioning/', // + manufacturer / cpid
    2: '/status/',
  },
  CommissioningCheckCharger: {
    1: 'api/v1/evse-management/commissioning/', // + manufacturer / cpid
    2: '/check/',
  },
  CommissioningSendCommandToCharger: {
    1: 'api/v1/evse-management/commissioning/', // + manufacturer / cpid
    2: '/test/',
  },
  CommissioningSetChargerCustomerDetails: {
    1: 'api/v1/evse-management/commissioning/', // + manufacturer / cpid
    2: '/commission/',
  },
  CommissioningSetChargerMpan: {
    1: 'api/v1/evse-management/commissioning/', // + manufacturer / cpid
    2: '/mpan/',
  },

  // Vehicles
  FindVehicles: 'api/v1/fleet-management/vehicles/',
  FindVehiclesChargingSessions: 'api/v1/fleets/charging-sessions/',
  FindVehiclesMakes: 'api/v1/vehicle-management/makes/',

  // Address
  FindAddressAutocomplete: 'hereapi/autocomplete/',
  FindCountries: 'countries/',

  // Charger Testing Platform
  CreateTestingSession: 'api/v1/ctp/create-session',
} as const;

export enum ApiQueryKey {
  FindUsers = 'FindUsers',
  FindUser = 'FindUser',
  FindB2BUserInfo = 'FindB2BUserInfo',
  FindPendingUsersInvitations = 'FindPendingUsersInvitations',
  FindUserInvitationTokenInfo = 'FindUserInvitationTokenInfo',
  FindOrganisations = 'FindOrganisations',
  FindChargers = 'FindChargers',
  FindChargerDetails = 'FindChargerDetails',
  FindChargerConnectionReliability = 'FindChargerConnectionReliability',
  FindChargerVehicles = 'FindChargerVehicles',
  FindChargingSessions = 'FindChargingSessions',
  FindChargerLogsCommand = 'FindChargerLogsCommand',
  FindChargerLogsReceivedData = 'FindChargerLogsReceivedData',
  FindChargerLogsLifecycle = 'FindChargerLogsLifecycle',
  FindChargerLogsOcpp = 'FindChargerLogsOcpp',
  FindChargerLogsOcppActions = 'FindChargerLogsOcppActions',
  FindChargerLogsOcppErrorTypes = 'FindChargerLogsOcppErrorTypes',
  FindChargerSettings = 'FindChargerSettings',
  FindChargerFirmwareDetails = 'FindChargerFirmwareDetails',
  FindSites = 'FindSites',
  FindSiteDetails = 'FindSiteDetails',
  FindSiteChargingSessionsStatistics = 'FindSiteChargingSessionsStatistics',
  FindSiteShare = 'FindSiteShare',
  FindDrivers = 'FindDrivers',
  FindDriverDetails = 'FindDriverDetails',
  FindCommissionedChargerStatus = 'FindCommissionedChargerStatus',
  FindCommissioningManufacturers = 'FindCommissioningManufacturers',
  FindVehicles = 'FindVehicles',
  FindVehiclesChargingSessions = 'FindVehiclesChargingSessions',
  FindVehiclesMakes = 'FindVehiclesMakes',
  FindAddressAutocomplete = 'FindAddressAutocomplete',
  FindCountries = 'FindCountries',
  FindFleets = 'FindFleets',
  FindFleetDetails = 'FindFleetDetails',
  FindChargerMakes = 'FindChargerMakes',
  FindChargerModels = 'FindChargerModels',
}

export enum ApiMutationKey {
  Login = 'Login',
  SignUp = 'SignUp',
  SignUpInvitation = 'SignUpInvitation',
  SignUpConfirm = 'SignUpConfirm',
  ResetPassword = 'ResetPassword',
  VerifyOAuth = 'VerifyOAuth',
  InviteUser = 'InviteUser',
  InviteDriver = 'InviteDriver',
  ActivateDriverRfid = 'ActivateDriverRfid',
  ResendUserInvitation = 'ResendUserInvitation',
  UpdateChargerSetting = 'UpdateChargerSetting',
  UpdateChargerFirmware = 'UpdateChargerFirmware',
  RebootCharger = 'RebootCharger',
  StartCharging = 'StartCharging',
  StopCharging = 'StopCharging',
  CommissioningCheckCharger = 'CommissioningCheckCharger',
  CommissioningSendCommandToCharger = 'CommissioningSendCommandToCharger',
  CommissioningSetChargerCustomerDetails = 'CommissioningSetChargerCustomerDetails',
  CommissioningSetChargerMpan = 'CommissioningSetChargerMpan',
  CreateSite = 'CreateSite',
  AssignChargersToSite = 'AssignChargersToSite',
  ShareSite = 'ShareSite',
  RevokeSiteShareAccess = 'RevokeSiteShareAccess',
  ChangeSiteShareAccess = 'ChangeSiteShareAccess',
  SendTestResults = 'SendTestResults',
  CreateTestingSession = 'CreateTestingSession',
  CreateOrganisation = 'CreateOrganisation',
  UpdateUserPermission = 'UpdateUserPermission',
  UpdateOrganisation = 'UpdateOrganisation',
  AddChargers = 'AddChargers',
  OnboardCharger = 'OnboardCharger',
  CheckChargersCpids = 'CheckChargersCpids',
}

export const DEFAULT_ROWS_PER_DESKTOP_PAGE = 20;
export const DEFAULT_ROWS_PER_MOBILE_PAGE = 10;
export const MAX_ROWS = 1_000;
export const TEMP_HUGE_LIMIT = 50_000; // TODO should be removed, currently used to fetch large number of table rows for CSV download
export const DEFAULT_DATE_RANGE_DAYS = 14;
export const ZAP_WEBHOOK_URL = 'https://hooks.zapier.com/hooks/catch/11400768/3mz76o2/';
