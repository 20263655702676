import type { FetchErrorResponse } from '@services/api.service';
import { ApiService } from '@services/api.service';
import { API_ROUTES, ApiQueryKey } from '@shared/constants/api.constants';
import { useQuery } from 'react-query';
import type { SiteDetailsApiResponse } from '@services/types/site/site.api-response.types';
import type { SiteDetails } from '@services/types/site/site.types';
import { SiteApiMapper } from '@services/mappers/site.api.mapper';

export interface FindSiteDetailsParams {
  siteId: string | undefined;
}

export const findSiteDetails = async ({ siteId }: FindSiteDetailsParams): Promise<SiteDetails> => {
  const result = await ApiService.get<SiteDetailsApiResponse>(`${API_ROUTES.FindSites}${siteId}/`);

  return SiteApiMapper.toSiteDetails(result.data);
};

export const useFindSiteDetails = (params: FindSiteDetailsParams) =>
  useQuery<SiteDetails, FetchErrorResponse, SiteDetails>([ApiQueryKey.FindSiteDetails, params], () => findSiteDetails(params), {
    enabled: Boolean(params.siteId),
  });
