export enum Color {
  White = '#FFFFFF',
  Black = '#000000',
  Transparent = 'rgba(255, 255, 255, 0.06)',

  // Primaries
  JungleGreen = '#0E2B2E',
  JungleGreenLight = 'rgba(14, 43, 46, 0.85)',
  ElectricGreen = '#50FB60',
  SeaGreen = '#285F54',
  SeaGreenLight = 'rgba(40, 95, 84, 0.25)',
  SeaGreenLighter = 'rgba(40, 95, 84, 0.1)',
  SeaGreenLightest = 'rgba(40, 95, 84, 0.05)',

  // Alerts
  SuccessBackground = '#F4F7F7',
  SuccessBackgroundLight = 'rgba(92, 159, 71, 0.05)',
  ErrorBackground = '#FCE7E8',
  ErrorBackgroundLight = 'rgba(228, 17, 28, 0.1)',

  // Backdrop
  BackdropBackground = 'rgba(0,0,0,0.6)',

  // Gray
  Gray0 = '#f9f9f9',
  Gray1 = '#ededee',
  Gray2 = '#e0e1e1',
  Gray3 = '#d2d3d3',
  Gray4 = '#c2c4c4',
  Gray5 = '#b1b3b4',
  Gray6 = '#9da0a1',
  Gray7 = '#868a8a',
  Gray8 = '#686d6e',
  Gray9 = '#3a4041',

  // Error
  Error1 = '#E4111C',
  Error2 = '#FBD0D2',
  Error4 = '#F47177',
  Error5 = '#F1414A',
  Error6 = '#B40D16',
  Error7 = '#850A10',
  Error8 = '#55060A',
  Error9 = '#260305',

  // Success
  Success0 = '#5C9F47',
  Success7 = '#04AF14',
  Success8 = '#037D0E',

  // Warning
  Warn0 = '#FFF7E6',
  Warn1 = '#FFDA8F',
  Warn2 = '#FFC652',
  Warn3 = '#FFBC33',
  Warn4 = '#FFB31A',
  Warn5 = '#F6A500',
  Warn6 = '#CC8900',

  // Neutral
  Neutral500 = '#6E6E6E',
}
