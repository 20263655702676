export const css = `
        @import url('https://themes.googleusercontent.com/fonts/css?kit=-lTUqgJg2dxbe4D7B5DEIA3jn2WilaVUapNOYl4762s');

        .c6 {
            padding-top: 12pt;
            padding-bottom: 12pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c3 {
            padding-bottom: 6pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c25 {
            padding-top: 14pt;
            padding-bottom: 6pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c34 {
            
            padding-top: 0pt;
            padding-bottom: 6pt;
            line-height: 1.0666666666666667;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c13 {
            
            padding-top: 0pt;
            padding-bottom: 12pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c2 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 10pt;
            font-family: "Avenir";
            font-style: normal
        }

        .c4 {
            padding-bottom: 6pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c16 {
            
            padding-top: 14pt;
            padding-bottom: 6pt;
            line-height: 1.0666666666666667;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c36 {
            padding-top: 3pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c31 {
            padding-top: 0pt;
            padding-bottom: 6pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c18 {
            padding-top: 12pt;
            padding-bottom: 6pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c7 {
            padding-top: 6pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c27 {
            padding-top: 9pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c1 {
            
            padding-top: 0pt;
            
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            text-align: justify
        }

        .c35 {
            padding-top: 6pt;
            padding-bottom: 0pt;
            line-height: 1.2375;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c32 {
            padding-top: 14pt;
            padding-bottom: 6pt;
            line-height: 1.0666666666666667;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c0 {
            
            padding-top: 0pt;
            
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            text-align: justify
        }

        .c14 {
            
            padding-top: 0.5pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            text-align: left;
            height: 11pt
        }

        .c28 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c11 {
            padding-top: 0.5pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            text-align: justify;
            height: 11pt
        }

        .c42 {
            padding-top: 24pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            text-align: left
        }

        .c40 {
            padding-top: 0.5pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            text-align: justify
        }

        .c10 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            text-align: justify
        }

        .c12 {
            color: #000000;
            text-decoration: none;
            vertical-align: baseline;
            font-style: normal
        }

        .c22 {
            padding-top: 7.5pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            text-align: justify
        }

        .c21 {
            padding-top: 0.5pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            text-align: left
        }

        .c19 {
            padding-top: 0.1pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            text-align: justify
        }

        .c9 {
            font-size: 10pt;
            font-family: "Avenir";
            font-weight: 400
        }

        .c15 {
            background-color: #ffffff;
        }

        .c39 {
            -webkit-text-decoration-skip: none;
            text-decoration: underline;
            text-decoration-skip-ink: none
        }

        .c5 {
            font-size: 10pt;
            font-family: "Avenir";
            font-weight: 700
        }

        .c38 {
            orphans: 2;
            widows: 2
        }

        .c29 {
            color: inherit;
            text-decoration: inherit
        }

        .c17 {
            
            height: 11pt
        }

        .c41 {
            color: #1155cc
        }

        .c26 {
            color: #0000ff
        }

        .c30 {
            font-style: italic
        }

        .c20 {
            height: 11pt
        }

        .c33 {
            background-color: #ffffff
        }

        .c37 {
            background-color: #ffff00
        }

        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.1500000000000001;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.1500000000000001;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            color: #000000;
            font-size: 11pt;
            font-family: "Avenir";
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Avenir";
        }
`;
