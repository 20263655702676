import { Language } from './language.enum';
import { resources } from './locales';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLocalStorageItem, LocalStorageItemKey, LocalStorageItemType } from '@utils/local-storage';

const browserLanguage =
  (() => {
    try {
      const lang = navigator.language.slice(0, 2)?.toLowerCase();
      if (Object.values(Language).includes(lang as Language)) {
        return lang;
      }

      return Language.EN;
    } catch {
      return null;
    }
  })() || Language.EN;

const localStorageLang = getLocalStorageItem(LocalStorageItemType.Preferences, LocalStorageItemKey.Language) as Language;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorageLang || browserLanguage,
    fallbackLng: Language.EN,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export const currentLanguage = i18n.language;

export default i18n;
