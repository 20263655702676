import { css } from '@emotion/css';
import { theme } from '@/theme';
import type { Spacing } from '@shared/styles/spacing.enum';
import { FontSize } from '@shared/styles/font-size.enum';
import { Color } from '@shared/styles/color.enum';

const spacingStyles = {
  marginBottom: (spacing: Spacing) => css`
    margin-bottom: ${theme.spacing(spacing)};
  `,
  marginTop: (spacing: Spacing) => css`
    margin-top: ${theme.spacing(spacing)};
  `,
  marginRight: (spacing: Spacing) => css`
    margin-right: ${theme.spacing(spacing)};
  `,
  marginLeft: (spacing: Spacing) => css`
    margin-left: ${theme.spacing(spacing)};
  `,
};

const elementsStyles = {
  icon: css`
    width: ${FontSize.M};
    height: ${FontSize.M};
    stroke: ${Color.White};
  `,
};

export const sharedStyles = {
  fullwidth: css`
    width: 100%;
  `,
  flexCentered: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  flexSpaceBetween: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  flexStart: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
  ...spacingStyles,
  ...elementsStyles,
};
