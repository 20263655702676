import { Loader } from '@components/core/Loader/Loader';
import { useEffect, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { RoutePath } from '@shared/constants/routing.constants';
import { logger } from '@utils/logger';
import { getApiErrorBody } from '@utils/api';
import { handleAuthTokensResponse } from '@services/commands/auth/login.command';
import { useUserStore } from '@stores/user.store';
import { useAuthTokensLocalStorageData } from '@hooks/auth/useAuthTokensLocalStorageData';
import type { OAuthVerificationData } from '@services/commands/auth/verify-oauth.command';
import { useVerifyOAuth } from '@services/commands/auth/verify-oauth.command';
import type { OAuthParams } from '@shared/types/interfaces/oauth-params.interface';
import { useOAuthStateLocalStorageData } from '@hooks/auth/useOAuthStateLocalStorageData';

export type SearchParams = { code: string } & Pick<OAuthParams, 'state'>;

export type RouteParams = {
  provider: OAuthVerificationData['provider'];
};

export const SsoRedirect = () => {
  const userStore = useUserStore();
  const { setAccessToken, setRefreshToken, removeRefreshToken } = useAuthTokensLocalStorageData();
  const oAuthStateLocalStorageData = useOAuthStateLocalStorageData();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code' as keyof SearchParams)!;
  const state = searchParams.get('state' as keyof SearchParams)!;
  const { provider } = useParams<RouteParams>();
  const [hasError, setHasError] = useState(false);
  const { mutateAsync: verifyOAuth } = useVerifyOAuth();

  useEffect(() => {
    if (code && provider && state === oAuthStateLocalStorageData.state) {
      verifyOAuth({ code, provider, state })
        .then(response => {
          if (!response) {
            setHasError(true);
          } else {
            handleAuthTokensResponse(userStore, setAccessToken, setRefreshToken, removeRefreshToken, true)(response);
          }
        })
        .catch(error => {
          logger.error('OAuth error', { error: getApiErrorBody(error) });
          setHasError(true);
        });
    } else {
      setHasError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, oAuthStateLocalStorageData.state, provider, state]);

  if (!code || hasError) {
    return <Navigate to={`${RoutePath.Auth}/${RoutePath.AuthLogin}`} replace />;
  }

  return <Loader position='relative' color='secondary' />;
};
