import type { ButtonProps } from '@mui/material/Button';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import type { FC, PropsWithChildren, JSX } from 'react';
import { theme } from '@/theme';
import { Color } from '@shared/styles/color.enum';
import { css, cx } from '@emotion/css';
import type { Props as TypographyProps } from '@components/core/Typography/Typography';
import { Typography } from '@components/core/Typography/Typography';
import { FontWeight } from '@shared/styles/font-weight.enum';
import type { FontSize } from '@shared/styles/font-size.enum';

export interface PandoButtonProps
  extends Pick<
      ButtonProps,
      | 'color'
      | 'onClick'
      | 'onFocus'
      | 'onBlur'
      | 'variant'
      | 'disabled'
      | 'type'
      | 'startIcon'
      | 'endIcon'
      | 'fullWidth'
      | 'href'
      | 'size'
      | 'className'
    >,
    Pick<TypographyProps, 'translationKey' | 'translationData'> {
  name: string;
  fontWeight?: FontWeight;
  fontSize?: FontSize;
  isLoading?: boolean;
  children?: JSX.Element | JSX.Element[];
  underline?: boolean;
}

const styles = {
  primaryDisabled: css`
    border: 2px solid ${Color.JungleGreen} !important;
    background: ${Color.JungleGreenLight} !important;
    color: ${Color.White} !important;
  `,
  secondary: css`
    background: ${Color.JungleGreen} !important;
    color: ${Color.White} !important;
    &:hover {
      background: ${Color.JungleGreenLight} !important;
    }
  `,
  secondaryDisabled: css`
    background: ${Color.JungleGreenLight} !important;
  `,
  outlinedDisabled: css`
    opacity: 0.35;
  `,
  text: css`
    color: ${Color.White} !important;
  `,
  underline: css`
    text-decoration: underline !important;
  `,
  outlined: css`
    height: 44px;
    color: ${Color.JungleGreen} !important;
    border: 2px solid ${Color.JungleGreen} !important;
  `,
};

export const BTN_TEST_ID_PREFIX = 'ev-btn-';

export const Button: FC<PropsWithChildren<PandoButtonProps>> = ({
  translationKey,
  translationData,
  name,
  fontWeight = FontWeight.SemiBold,
  isLoading,
  color,
  children,
  underline,
  size = 'large',
  startIcon,
  endIcon,
  fontSize,
  ...props
}) => {
  const disabled = props.disabled || isLoading;
  const isPrimaryColor = color === 'primary';
  const isSecondaryColor = color === 'secondary';
  const isTextVariant = props.variant === 'text';
  const isOutlinedVariant = props.variant === 'outlined';
  const id = `${BTN_TEST_ID_PREFIX}${name}`;

  const className = cx({
    [styles.primaryDisabled]: isPrimaryColor && disabled,
    [styles.secondaryDisabled]: isSecondaryColor && disabled,
    [styles.outlinedDisabled]: isOutlinedVariant && disabled,
    [styles.text]: isTextVariant && !color,
    [styles.underline]: isTextVariant && underline,
    [styles.outlined]: isOutlinedVariant && !color,
    [styles.secondary]: isSecondaryColor,
    [props.className || '']: Boolean(props.className),
  });

  const content = translationKey ? (
    <Typography
      translationKey={translationKey}
      translationData={translationData}
      variant='button'
      marginBottom={0}
      fontWeight={fontWeight}
      fontSize={fontSize}
    />
  ) : (
    children
  );

  return (
    <MuiButton
      {...props}
      data-testid={id}
      name={name}
      aria-label={name}
      color={color}
      disabled={disabled}
      aria-disabled={disabled}
      className={className}
      disableTouchRipple={isTextVariant}
      size={size}
      startIcon={isLoading ? null : startIcon}
      endIcon={isLoading ? null : endIcon}
    >
      {isLoading ? <CircularProgress size={theme.typography.fontSize * 1.5} color={isSecondaryColor ? 'secondary' : 'primary'} /> : content}
    </MuiButton>
  );
};
