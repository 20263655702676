import { NewPassword } from '@views/Auth/NewPassword/NewPassword';
import { PrivateRoute } from '@components/routing/PrivateRoute/PrivateRoute';
import { NotFoundPage } from '@views/PageNotFound/PageNotFound';
import { lazy } from 'react';
import { RoutePath } from '@shared/constants/routing.constants';
import { withSuspense } from '@hocs/withSuspense';
import type { RouteObject } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router-dom';
import type { BreadcrumbComponentType } from 'use-react-router-breadcrumbs';
import { BreadcrumbValue } from '@components/routing/Breadcrumbs/components/BreadcrumbValue';
import { DashboardTableSublayout } from '@components/layouts/DashboardLayout/dashboardSublayouts/DashboardTableSublayout';
import { DashboardDetailsSublayout } from '@components/layouts/DashboardLayout/dashboardSublayouts/DashboardDetailsSublayout';
import { DashboardSettingsSublayout } from '@components/layouts/DashboardLayout/dashboardSublayouts/DashboardSettingsSublayout';
import { TermsAndConditions } from '@views/Auth/shared/TermsAndConditions/components/TermsAndConditions/TermsAndConditions';
import { PrivacyPolicy } from '@views/Auth/shared/PrivacyPolicy/PrivacyPolicy';
import { SiteDetailsBreadcrumbValue } from '@components/routing/Breadcrumbs/components/SiteDetailsBreadcrumbValue';
import { LoginSso } from '@views/Auth/LoginSso/LoginSso';
import { ChargerTestingSessionBreadcrumbValue } from '../Breadcrumbs/components/ChargerTestingSessionBreadcrumbValue';
import { SsoRedirect } from '@views/Auth/SsoRedirect/SsoRedirect';
import { DashboardBase } from '@views/Dashboard/DashboardBase';
import { FleetDetailsBreadcrumbValue } from '@components/routing/Breadcrumbs/components/FleetDetailsBreadcrumbValue';

const AuthLayout = lazy(() => import('@components/layouts/AuthLayout/AuthLayout'));
const Login = lazy(() => import('@views/Auth/Login/Login'));
const SignUpInvitation = lazy(() => import('@views/Auth/SignUpInvitation/SignUpInvitation'));
const SignUp = lazy(() => import('@views/Auth/SignUp/SignUp'));
const SignUpConfirm = lazy(() => import('@views/Auth/SignUpConfirm/SignUpConfirm'));
const ResetPassword = lazy(() => import('@views/Auth/ResetPassword/ResetPassword'));
const DashboardLayout = lazy(() => import('@components/layouts/DashboardLayout/DashboardLayout'));
const CommissioningLayout = lazy(() => import('@components/layouts/CommissioningLayout/CommissioningLayout'));
const InfoLayout = lazy(() => import('@components/layouts/InfoLayout/InfoLayout'));
const Users = lazy(() => import('@views/Dashboard/Users/Users'));
const Chargers = lazy(() => import('@views/Dashboard/Chargers/Chargers'));
const Sites = lazy(() => import('@views/Dashboard/Sites/Sites'));
const Vehicles = lazy(() => import('@views/Dashboard/Vehicles/Vehicles'));
const Fleets = lazy(() => import('@views/Dashboard/Fleets/Fleets'));
const ChargerDetails = lazy(() => import('@views/Dashboard/Chargers/ChargerDetails/ChargerDetails'));
const SiteDetails = lazy(() => import('@views/Dashboard/Sites/SiteDetails/SiteDetails'));
const DriverDetails = lazy(() => import('@views/Dashboard/Drivers/DriverDetails/DriverDetails'));
const Organisations = lazy(() => import('@views/Dashboard/Organisations/Organisations'));
const Settings = lazy(() => import('@views/Dashboard/Settings/Settings'));
const ReleaseNotes = lazy(() => import('@views/Dashboard/ReleaseNotes/ReleaseNotes'));
const Commissioning = lazy(() => import('@views/Commissioning/Commissioning'));
const DriverActivateRfid = lazy(() => import('@views/DriverActivateRfid/DriverActivateRfid'));
const ChargerTesting = lazy(() => import('@views/ChargerTesting/ChargerTesting'));
const ChargerTestingSession = lazy(() => import('@views/ChargerTesting/ChargerTestingSession/ChargerTestingSession'));

export type RoutePathDefinition = {
  breadcrumb: BreadcrumbComponentType | null;
  children?: RoutePathDefinition[];
  path?: RoutePath | '';
  index?: true;
} & RouteObject;

export const routes: RoutePathDefinition[] = [
  {
    path: RoutePath.OAuthRedirectProvider,
    element: (
      <AuthLayout>
        <SsoRedirect />
      </AuthLayout>
    ),
    breadcrumb: null,
  },
  {
    path: RoutePath.Auth,
    element: <AuthLayout />,
    breadcrumb: null,
    children: [
      {
        path: RoutePath.AuthSignUp,
        element: <SignUp />,
        breadcrumb: null,
      },
      {
        path: RoutePath.AuthSignUpInvitation,
        element: <SignUpInvitation />,
        breadcrumb: null,
      },
      {
        path: RoutePath.AuthLogin,
        element: <Login />,
        breadcrumb: null,
      },
      {
        path: RoutePath.AuthLoginSso,
        element: <LoginSso />,
        breadcrumb: null,
      },
      {
        path: RoutePath.AuthResetPassword,
        element: <ResetPassword />,
        breadcrumb: null,
      },
      {
        path: RoutePath.AuthNewPassword,
        element: <NewPassword />,
        breadcrumb: null,
      },
      {
        path: RoutePath.AuthSignUpConfirmation,
        element: <SignUpConfirm />,
        breadcrumb: null,
      },
      {
        path: '',
        element: <Navigate to={RoutePath.AuthLogin} />,
        breadcrumb: null,
      },
    ],
  },
  {
    path: RoutePath.Commissioning,
    element: <CommissioningLayout />,
    breadcrumb: null,
    children: [
      {
        path: RoutePath.CommissioningManufacturer,
        element: withSuspense(Commissioning),
        breadcrumb: null,
      },
      {
        path: '',
        element: withSuspense(Commissioning),
        breadcrumb: null,
      },
    ],
  },
  {
    path: RoutePath.DriverActivateRfid,
    element: <InfoLayout />,
    breadcrumb: null,
    children: [
      {
        path: '',
        element: withSuspense(DriverActivateRfid),
        breadcrumb: null,
      },
    ],
  },
  {
    path: RoutePath.Dashboard,
    element: (
      <PrivateRoute>
        <DashboardLayout />
      </PrivateRoute>
    ),
    breadcrumb: null,
    children: [
      {
        path: '',
        element: (
          <DashboardTableSublayout>
            <Outlet />
          </DashboardTableSublayout>
        ),
        children: [
          {
            index: true,
            path: '',
            element: <DashboardBase />,
            breadcrumb: null,
          },
          {
            path: RoutePath.DashboardUsers,
            element: withSuspense(Users),
            breadcrumb: props => <BreadcrumbValue translationKey='dashboard-breadcrumbs.users' {...props} />,
          },
          {
            path: RoutePath.DashboardOrganisations,
            element: withSuspense(Organisations),
            breadcrumb: props => <BreadcrumbValue translationKey='dashboard-breadcrumbs.organisations' {...props} />,
          },
          {
            path: RoutePath.DashboardChargers,
            element: withSuspense(Chargers),
            breadcrumb: props => <BreadcrumbValue translationKey='dashboard-breadcrumbs.chargers' {...props} />,
          },
          {
            path: RoutePath.DashboardSites,
            element: withSuspense(Sites),
            breadcrumb: props => <BreadcrumbValue translationKey='dashboard-breadcrumbs.sites' {...props} />,
          },
          {
            path: RoutePath.DashboardFleets,
            element: withSuspense(Fleets),
            breadcrumb: props => <BreadcrumbValue translationKey='dashboard-breadcrumbs.fleets' {...props} />,
          },
        ],
        breadcrumb: null,
      },
      {
        path: '',
        element: (
          <DashboardDetailsSublayout>
            <Outlet />
          </DashboardDetailsSublayout>
        ),
        children: [
          {
            path: RoutePath.DashboardChargerDetails,
            element: withSuspense(ChargerDetails),
            breadcrumb: null,
          },
          {
            path: RoutePath.DashboardSiteDetails,
            element: withSuspense(SiteDetails),
            breadcrumb: props => <SiteDetailsBreadcrumbValue {...props} />,
          },
          {
            path: RoutePath.DashboardFleetDetails,
            element: withSuspense(Vehicles),
            breadcrumb: props => <FleetDetailsBreadcrumbValue {...props} />,
          },
          {
            path: RoutePath.DashboardSiteDriverDetails,
            element: withSuspense(DriverDetails),
            breadcrumb: props => <BreadcrumbValue translationKey='dashboard-breadcrumbs.driver-detail' {...props} />,
          },
        ],
        breadcrumb: null,
      },
      {
        path: '',
        element: (
          <DashboardSettingsSublayout>
            <Outlet />
          </DashboardSettingsSublayout>
        ),
        children: [
          {
            path: RoutePath.DashboardSettingsSection,
            element: withSuspense(Settings),
            breadcrumb: props => <BreadcrumbValue translationKey='dashboard-breadcrumbs.settings' {...props} />,
          },
          {
            path: RoutePath.DashboardReleaseNotes,
            element: withSuspense(ReleaseNotes),
            breadcrumb: props => <BreadcrumbValue translationKey='dashboard-breadcrumbs.release-notes' {...props} />,
          },
          {
            path: RoutePath.DashboardSettingsBase,
            element: <Navigate to={RoutePath.DashboardSettingsGeneral} />,
            breadcrumb: null,
          },
        ],
        breadcrumb: null,
      },
      {
        path: RoutePath.All,
        element: <NotFoundPage />,
        breadcrumb: null,
      },
    ],
  },
  {
    path: RoutePath.Base,
    element: <Navigate to={RoutePath.Dashboard} />,
    breadcrumb: null,
  },
  {
    path: RoutePath.Docs,
    element: <InfoLayout />,
    breadcrumb: null,
    children: [
      {
        path: RoutePath.TermsAndConditions,
        element: <TermsAndConditions />,
        breadcrumb: null,
      },
      {
        path: RoutePath.PrivacyPolicy,
        element: <PrivacyPolicy />,
        breadcrumb: null,
      },
    ],
  },
  {
    path: RoutePath.ChargerTestingPlatform,
    element: <Outlet />,
    breadcrumb: props => <BreadcrumbValue translationKey='dashboard-breadcrumbs.charger-testing' {...props} />,
    children: [
      {
        path: '',
        element: (
          <InfoLayout>
            <ChargerTesting />
          </InfoLayout>
        ),
        breadcrumb: props => <BreadcrumbValue translationKey='dashboard-breadcrumbs.charger-testing' {...props} />,
      },
      {
        path: RoutePath.ChargerTestingSession,
        element: withSuspense(ChargerTestingSession),
        breadcrumb: props => <ChargerTestingSessionBreadcrumbValue {...props} />,
      },
    ],
  },
  {
    path: RoutePath.All,
    element: <NotFoundPage />,
    breadcrumb: null,
  },
];
