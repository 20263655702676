import { styled } from '@mui/material/styles';
import { css } from '@emotion/css';
import { theme } from '@/theme';
import { Color } from '@shared/styles/color.enum';

export const LabelContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const styles = {
  inputError: css`
    border-radius: ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px !important;
    border: 2px solid ${Color.Error1};
  `,
  inputLight: css`
    min-width: 80px;
    background: ${Color.Gray0};
    border-radius: ${theme.shape.borderRadius}px;
  `,
  inputDark: css`
    min-width: 80px;
    background: ${Color.JungleGreen};
    color: ${Color.White} !important;
  `,
  inputDarkDisabled: css`
    background: transparent;
    border: 1px solid ${Color.JungleGreen};
  `,
  inputLightDisabled: css`
    opacity: 0.5;
  `,
  input: (isDarkPaletteMode: boolean, marginBottom = 1) => css`
    margin-bottom: ${theme.spacing(marginBottom)};
    :focus-within {
      box-shadow: 0 0 0 2px ${isDarkPaletteMode ? Color.ElectricGreen : Color.SeaGreen};
    }
  `,
  icon: (isDarkPaletteMode: boolean) => css`
    cursor: pointer;
    stroke: ${isDarkPaletteMode ? Color.White : Color.SeaGreen};
  `,
  iconActive: css`
    stroke: ${Color.SeaGreen};
  `,
  iconFocused: (isDarkPaletteMode: boolean) => css`
    stroke: ${isDarkPaletteMode ? Color.ElectricGreen : Color.SeaGreen};
  `,
  label: (isDarkPaletteMode: boolean) => css`
    color: ${isDarkPaletteMode ? Color.White : theme.palette.text.primary} !important;
  `,
};
