import Joi from 'joi';
import type { ValidationTranslationKey } from '@i18n/locales/validation';
import passwordComplexity from 'joi-password-complexity';
import { PASSWORD_COMPLEXITY_CONFIG } from '@utils/constants';

type MaxLength = 512 | 256 | 72 | 36 | number;

export const STRING_MAX_LENGTH_DEFAULT: MaxLength = 256;

export const stringMaxLengthSchema = (maxLength = STRING_MAX_LENGTH_DEFAULT) => {
  return Joi.string()
    .trim(true)
    .max(maxLength)
    .custom((value, helpers) => {
      try {
        const isLengthInBounds = value.length <= maxLength;
        if (isLengthInBounds) {
          return value;
        } else {
          return helpers.error(`string.max.${maxLength}`);
        }
      } catch {}
    });
};

export const passwordSchema = passwordComplexity(PASSWORD_COMPLEXITY_CONFIG);

export const emailSchema = Joi.string().email({ tlds: false });

export const booleanTruthySchema = Joi.boolean()
  .allow(true)
  .custom((value: boolean, helpers) => {
    try {
      if (value !== true) {
        return helpers.error(`validation.required` as ValidationTranslationKey);
      }

      return value;
    } catch {}
  });
