import type { AxiosRequestConfig } from 'axios';
import { AxiosError } from 'axios';
import { HttpStatus } from '@shared/types/enums/http-status.enum';
import { UserApiErrorMessage } from '@services/types/user/user.api-response.types';
import type { ValidationTranslationKey } from '@i18n/locales/validation';
import type { ToastTranslationKey } from '@i18n/locales/toast';
import { ApiService } from '@services/api.service';
import { Header } from '@shared/types/enums/header.enum';

export const isInvalidCredentialsError = (error: AxiosError) => {
  return error?.response?.status === HttpStatus.UNAUTHORIZED;
};

export const isUnexpectedError = (error: AxiosError) => {
  return error?.response?.status === HttpStatus.INTERNAL_SERVER_ERROR;
};

export const getApiErrorBody = (error: AxiosError | Error | unknown) => {
  const isAxiosError = error instanceof AxiosError;
  if (isAxiosError) {
    return error.response?.data;
  }

  return error;
};

export const getApiErrorMessage = (error: AxiosError | unknown): string | null => {
  const isAxiosError = error instanceof AxiosError;
  if (isAxiosError) {
    const apiErrorMessage = error.response?.data;
    const canApiErrorBeShown = typeof apiErrorMessage === 'string' && !apiErrorMessage.includes('<!DOCTYPE html>');

    return canApiErrorBeShown ? apiErrorMessage : null;
  }

  return null;
};

export interface ApiNonFieldErrorsResponse {
  non_field_errors: string[];
}

export interface ApiDetailErrorResponse {
  detail: string;
}

export const getUserInvitationErrorType = (
  error: AxiosError<ApiNonFieldErrorsResponse[] | ApiDetailErrorResponse>,
): (ValidationTranslationKey | null)[] | null => {
  const flattenErrors = Array.isArray(error.response?.data)
    ? error.response?.data.map(error => {
        if (error.non_field_errors) {
          return error.non_field_errors;
        }

        return error;
      })
    : [error.response?.data?.detail];

  return (
    flattenErrors?.map(error => {
      for (const [key, value] of Object.entries(UserApiErrorMessage)) {
        if ((error as string[]).includes(value)) {
          return key as ValidationTranslationKey;
        }
      }

      return null;
    }) || null
  );
};

export enum SignupErrorMessage {
  USER_ALREADY_EXISTS = 'User already exists.',
  TOKEN_EXPIRED = 'Token has expired.',
  PASSWORD_TOO_SIMILAR_TO_EMAIL = 'The password is too similar to the username.',
}

export const getSignupToastErrorMessage = (error: AxiosError<ApiDetailErrorResponse>): ToastTranslationKey => {
  const isDetailsArray = error.response?.data?.detail && Array.isArray(error.response?.data?.detail);
  const errorMessage = isDetailsArray ? error.response?.data?.detail?.[0] : error.response?.data?.detail;

  switch (true) {
    case errorMessage === SignupErrorMessage.USER_ALREADY_EXISTS: {
      return 'toast.user-already-exists';
    }
    case errorMessage === SignupErrorMessage.TOKEN_EXPIRED: {
      return 'toast.token-expired';
    }
    case errorMessage === SignupErrorMessage.PASSWORD_TOO_SIMILAR_TO_EMAIL: {
      return 'toast.password-too-similar-to-email';
    }
    default: {
      return 'toast.account-creation-failure';
    }
  }
};

export const getCsvApiResponse = async (url: string, config?: AxiosRequestConfig) => {
  const result = await ApiService.get<Blob>(url, {
    ...config,
    responseType: 'blob',
    headers: {
      ...config?.headers,
      [Header.Accept]: 'text/csv',
    },
  });

  return result.data as Blob;
};
