import CURRENCIES from 'world-currencies';
import { getUserLocale } from './browser';
import { logger } from '@utils/logger';
import type { Money } from '@shared/types/interfaces/api-types.interface';

export interface CurrencyData {
  symbol: string;
  symbolMinor: string;
  code: string;
  majorValueInMinorUnits: number;
}

// https://stripe.com/docs/currencies
// Some currencies do not have minor units or the minor units are so small that they are not
// in daily use. For display accuracy we set decimal places to zero for these.
const ZERO_DECIMAL_CURRENCIES: string[] = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];

export function getCurrency(isoCode: string): CurrencyData | null {
  const currencyData = CURRENCIES[isoCode];
  if (!currencyData) {
    logger.error('Getting currency data error', { isoCode });

    return null;
  }

  return {
    code: currencyData.iso.code,
    symbol: currencyData.units.major.symbol,
    symbolMinor: currencyData.units.minor.symbol || currencyData.units.minor.name,
    majorValueInMinorUnits: 1 / (currencyData.units.minor.majorValue || 1),
  };
}

export function formatCurrency(money: Money, hideCurrencySymbol = false): string {
  const isoCode = money.currency as string;
  const value = +money.value!;

  // Out of 153 currencies, 142 have 100 majorValueInMinorUnits, 5 have 1000, 5 have 10 and 1 has 5
  const currencyData = getCurrency(isoCode);
  if (!currencyData) {
    return new Intl.NumberFormat(getUserLocale(), {
      style: hideCurrencySymbol ? 'decimal' : 'currency',
      currency: isoCode,
    }).format(value);
  }

  const { majorValueInMinorUnits } = currencyData;
  // i.e. 1 GBP = 100 pence, log10(100) = 2
  const decimalPlaces = ZERO_DECIMAL_CURRENCIES.includes(isoCode) ? 0 : Math.ceil(Math.log10(majorValueInMinorUnits));

  return new Intl.NumberFormat(getUserLocale(), {
    style: hideCurrencySymbol ? 'decimal' : 'currency',
    currency: isoCode,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(value);
}
