import { useCallback, useState } from 'react';
import { init, setUser } from '@sentry/react';
import { config } from '@utils/config';
import { BrowserTracing } from '@sentry/tracing';
import { useUserStore } from '@stores/user.store';

export const useInitSentry = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const userStore = useUserStore();

  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/
  const initSentry = useCallback(() => {
    if (isInitialized) {
      return;
    }

    if (config.logging.enabled) {
      init({
        dsn: config.logging.sentryDsn,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.5,
        debug: config.isDev,
        release: `web-frontend@${config.version}`,
        environment: config.instanceName,
        attachStacktrace: config.isDev,
        enabled: config.logging.enabled,
        initialScope: {
          user: {
            username: userStore.user?.email,
          },
        },
      });

      useUserStore.subscribe(
        state => state.user,
        user => {
          setUser({
            username: user?.email,
          });
        },
      );
    }

    setIsInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isInitialized, initSentry] as const;
};
