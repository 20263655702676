import type { FC } from 'react';
import { NotFound } from '@components/core/NotFound/NotFound';
import { Container, GoBackButtonContainer } from '@views/PageNotFound/PageNotFound.style';
import { Button } from '@components/core/Button/Button';
import { RoutePath } from '@shared/constants/routing.constants';

export const NotFoundPage: FC = () => {
  return (
    <Container>
      <NotFound />
      <GoBackButtonContainer>
        <Button name='back-to-home' translationKey='action.back-to-home' fullWidth={false} href={RoutePath.Base} />
      </GoBackButtonContainer>
    </Container>
  );
};
