import { Typography } from '@components/core/Typography/Typography';
import { FontWeight } from '@shared/styles/font-weight.enum';
import { Spacing } from '@shared/styles/spacing.enum';
import type { FC } from 'react';
import type { Toast, ToastWithElement } from '@stores/toast.store';
import type { TranslationKey } from '@i18n/locales';

interface Props {
  toast: Toast;
}

export const hasToastElement = (toast: Toast): toast is ToastWithElement => 'element' in toast;

export const ToastBody: FC<Props> = ({ toast }) => {
  if (hasToastElement(toast)) {
    return toast.element;
  } else {
    return (
      <>
        <Typography
          translationKey={toast.title!}
          variant='subtitle2'
          fontWeight={FontWeight.Bold}
          marginBottom={theme => theme.spacing(Spacing.Small)}
          textOverflow='ellipsis'
          overflow='hidden'
          maxWidth={400}
        />
        <Typography translationKey={toast!.message! as TranslationKey} variant='body1' marginBottom={0} maxWidth={400} />
      </>
    );
  }

  throw new Error('Invalid toast body');
};
