import type { FC, PropsWithChildren } from 'react';
import { useCallback, Children, isValidElement, cloneElement } from 'react';
import type { ValidationTranslationKey } from '@i18n/locales/validation';
import { FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { TranslationKey } from '@i18n/locales';
import { css, cx } from '@emotion/css';
import type { FieldError } from 'react-hook-form/dist/types/errors';
import { Color } from '@shared/styles/color.enum';
import { theme } from '@/theme';
import { Spacing } from '@shared/styles/spacing.enum';

export interface Props {
  errorTypeMapping?: Record<string, ValidationTranslationKey>;
  error?: FieldError;
  helperText?: TranslationKey;
  isTextField?: boolean; // can be extended to enum for specific component styling
  margin?: string;
  name: string;
}

const styles = {
  formControl: css`
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing(2)} 0;
    flex: 1;
  `,
  errorHelperTextOtherComponent: css`
    padding: ${theme.spacing(Spacing.Small)} ${theme.spacing(Spacing.Small)} ${theme.spacing(Spacing.Small)} ${theme.spacing(Spacing.Large)};
  `,
  helperTextTextField: css`
    border-radius: 0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px;
    padding: ${theme.spacing(Spacing.Small)} 0px ${theme.spacing(Spacing.Small)} ${theme.spacing(Spacing.Large)};
  `,
  helperTextOtherComponent: css`
    border-radius: ${theme.shape.borderRadius}px;
  `,
  errorHelperText: css`
    margin-top: 0 !important;
    padding: ${theme.spacing(0.5)} ${theme.spacing(2)};
    background: ${Color.Error1};
    color: ${Color.White} !important;
  `,
};

export const FormControl: FC<PropsWithChildren<Props>> = ({ children, name, helperText, isTextField, error, errorTypeMapping, margin }) => {
  const { t } = useTranslation();
  const errorKey = useCallback(
    (fieldError: FieldError) => errorTypeMapping?.[fieldError.type] || (fieldError.type as ValidationTranslationKey),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const id = `ev-${name}`;
  const hasError = Boolean(error);
  const helperTextId = `${id}-helper-text`;

  const className = cx({
    [styles.helperTextTextField]: isTextField,
    [styles.helperTextOtherComponent]: !isTextField,
    [styles.errorHelperTextOtherComponent]: hasError && !isTextField,
    [styles.errorHelperText]: hasError,
  });

  return (
    <div className={styles.formControl} style={{ margin }}>
      {Children.map(children, child => {
        if (isValidElement(child)) {
          return cloneElement(child, { helperTextId } as any);
        }

        return child;
      })}
      {helperText && !error && (
        <FormHelperText id={helperTextId} data-testid={helperTextId}>
          {t(helperText)}
        </FormHelperText>
      )}
      {error && (
        <FormHelperText id={helperTextId} data-testid={helperTextId} error={Boolean(error)} className={className}>
          {t(errorKey(error), error.message!)}
        </FormHelperText>
      )}
    </div>
  );
};
