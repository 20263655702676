export enum OrganisationType {
  Manufacturer = 'Manufacturer',
  Distributor = 'Distributor',
  Installer = 'Installer',
  Utility = 'Utility',
  ChargePointOperator = 'Charge Point Operator',
  FleetOperator = 'Fleet Operator',
}

export interface OrganisationParent {
  id: string;
  name: string;
}

export interface OrganisationApiResponse {
  id: string;
  name: string;
  createdAt: string; // iso date
  organisationTypes: { name: OrganisationType }[];
  parents: OrganisationParent[];
  numberOfUsers?: number;
  numberOfEVSEs?: number;
  numberOfVehicles?: number;
}
