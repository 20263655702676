import { Typography } from '@components/core/Typography/Typography';
import { NoDataContainer, StyledImg } from './NotFound.style';
import { FontSize } from '@shared/styles/font-size.enum';
import chargerPlugImg from '@assets/images/charger-plug.svg';
import { Color } from '@shared/styles/color.enum';

export const NotFound = () => {
  return (
    <NoDataContainer>
      <StyledImg src={chargerPlugImg} width={300} height='auto' alt='charger-plug' loading='lazy' />
      <Typography component='h1' variant='h1' fontSize={FontSize.XXXXXXL} marginBottom={0} align='center' color={Color.Black}>
        404
      </Typography>
      <Typography translationKey='description.something-missing' component='p' variant='h2' align='center' color={Color.Black} />
      <Typography translationKey='description.page-missing' component='p' variant='h6' marginBottom={0.5} maxWidth={250} align='center' />
      <Typography translationKey='description.try-refresh-browser-cache' component='p' variant='h6' marginBottom={0} maxWidth={250} align='center' />
    </NoDataContainer>
  );
};
