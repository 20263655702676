import { styled } from '@mui/material/styles';
import type { Props } from '@components/core/Loader/Loader';

export const LoaderContainer = styled('div')<Props>(({ position }) => ({
  position: position || 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
