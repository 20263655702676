import { DateTime, Settings } from 'luxon';
import { currentLanguage } from '@/i18n';
import type { DateRangeRawValue, DateRangeValue } from '@shared/types/interfaces/date-range-value.interface';
import { getLocalStorageItem, LocalStorageItemKey, LocalStorageItemType } from '@utils/local-storage';
import { getTimeZoneByUniqueId } from '@shared/data/timezones';

Settings.defaultLocale = currentLanguage;

export enum DateFormat {
  DateTime = 'dd LLL yyyy, HH:mm', // 09 Mar 2023, 12:42
  D = 'D', // 2/21/2023
  ddMMMyyyy = 'dd MMM yyyy', // 24 Feb 2023
}

const selectedTimeZoneUniqueId = () => getLocalStorageItem<string>(LocalStorageItemType.Preferences, LocalStorageItemKey.TimeZone);
const getTimeZone = () => {
  const timeZone = selectedTimeZoneUniqueId();

  return timeZone ? getTimeZoneByUniqueId(timeZone) : undefined;
};

export const dateFormatter = (date: string, format: DateFormat) => {
  const timeZone = getTimeZone();

  return DateTime.fromISO(date, { zone: !timeZone ? 'utc' : timeZone?.utc[0] }).toFormat(format);
};

export const isBaseDateAfter = (baseIsoDate: string, compareIsoDate: string) =>
  DateTime.fromISO(baseIsoDate).diff(DateTime.fromISO(compareIsoDate)).milliseconds > 0;

export const isBaseDateBefore = (baseIsoDate: string, compareIsoDate: string) =>
  DateTime.fromISO(baseIsoDate).diff(DateTime.fromISO(compareIsoDate)).milliseconds < 0;

export const mapRawValueToDateRange = (dateRange: DateRangeRawValue): DateRangeValue => ({
  from: dateRange.from ? DateTime.fromISO(dateRange.from) : undefined,
  to: dateRange.to ? DateTime.fromISO(dateRange.to) : undefined,
});

export const getNowDate = () => DateTime.local().toISO()!;
