// don't use alias here to be able to import validation translations in e2e tests
import { PASSWORD_COMPLEXITY_CONFIG } from '../../utils/constants';

export type ValidationTranslationKey =
  | 'validation.required'
  | 'validation.password-confirm'
  | 'validation.email-confirm'
  | 'validation.max-current-format'
  | 'EMAIL_ALREADY_USED'
  | 'EMAIL_ALREADY_INVITED'
  | 'EMPTY_ORGANISATION'
  | 'UNIQUE_VALUE';

const JoiValidationErrorsTranslationEN = {
  // Joi possible errors
  // alternatives.all
  // alternatives.any
  // alternatives.match
  // alternatives.one
  // alternatives.types
  // any.custom
  // any.default
  // any.failover
  // any.invalid
  // any.only
  // any.ref
  'any.required': 'Required',
  // any.unknown
  // array.base
  // array.excludes
  // array.includesRequiredBoth
  // array.includesRequiredKnowns
  // array.includesRequiredUnknowns
  // array.includes
  // array.length
  // array.max
  // array.min
  // array.orderedLength
  // array.sort
  // array.sort.mismatching
  // array.sort.unsupported
  // array.sparse
  // array.unique
  // array.hasKnown
  // array.hasUnknown
  // binary.base
  // binary.length
  // binary.max
  // binary.min
  // boolean.base
  // date.base
  // date.format
  // date.greater
  // date.less
  // date.max
  // date.min
  // date.strict
  // function.arity
  // function.class
  // function.maxArity
  // function.minArity
  'number.base': 'Value must be a number',
  // number.greater
  // number.infinity
  // number.integer
  // number.less
  // number.max
  // number.min
  // number.multiple
  // number.negative
  // number.port
  // number.positive
  // number.precision
  // number.unsafe
  // object.unknown
  // object.and
  // object.assert
  // object.base
  // object.length
  // object.max
  // object.min
  // object.missing
  // object.nand
  // object.pattern.match
  // object.refType
  // object.regex
  // object.rename.multiple
  // object.rename.override
  // object.schema
  // object.instance
  // object.with
  // object.without
  // object.xor
  // object.oxor
  // string.alphanum
  // string.base64
  // string.base
  // string.creditCard
  // string.dataUri
  // string.domain
  'string.email': 'Invalid email',
  'string.empty': 'Required',
  'string.max.512': 'Max length 512 characters',
  'string.max.256': 'Max length 256 characters',
  'string.max.72': 'Max length 72 characters',
  'string.max.36': 'Max length 36 characters',
  'string.max': 'Value too long',
  'number.max': 'Number too large',
  'number.min': 'Number too small',
  // string.guid
  // string.hexAlign
  // string.hex
  // string.hostname
  // string.ipVersion
  // string.ip
  // string.isoDate
  // string.isoDuration
  // string.length
  // string.lowercase
  // string.min
  // string.normalize
  // string.pattern.base
  // string.pattern.name
  // string.pattern.invert.base
  // string.pattern.invert.name
  // string.token
  // string.trim
  // string.uppercase
  // string.uri
  // string.uriCustomScheme
  // string.uriRelativeOnly
  // symbol.base
  // symbol.map
  'passwordComplexity.uppercase': 'Password should contain at least 1 upper-cased letter',
  'passwordComplexity.lowercase': 'Password should contain at least 1 lower-cased letter',
  'passwordComplexity.tooShort': `Password should be at least ${PASSWORD_COMPLEXITY_CONFIG.min} characters long`,
  'passwordComplexity.numeric': 'Password should contain at least 1 number',
  'passwordComplexity.symbol': 'Password should contain at least 1 symbol',
  'passwordComplexity.tooLong': `Password should not be longer than ${PASSWORD_COMPLEXITY_CONFIG.min} characters`,
  invalidFormat: 'Invalid format',
};

export const ValidationTranslationEN: Record<ValidationTranslationKey, string> = {
  ...JoiValidationErrorsTranslationEN,
  'validation.required': 'Required',
  'validation.password-confirm': 'Passwords do not match',
  'validation.email-confirm': 'Emails do not match',
  'validation.max-current-format': 'Max current value should be number from 0 to 999',
  EMAIL_ALREADY_USED: 'This email already exists in our database',
  EMAIL_ALREADY_INVITED: 'This user was already invited',
  EMPTY_ORGANISATION: 'You cannot invite user to existing organisation, if you do not belong to any.',
  UNIQUE_VALUE: 'Must be unique value',
};
