import { createTheme } from '@mui/material';
import { Color } from '@shared/styles/color.enum';
import { FontSize } from '@shared/styles/font-size.enum';
import { FontWeight } from '@shared/styles/font-weight.enum';
import { Spacing } from '@shared/styles/spacing.enum';

export const FONT_FAMILY = 'Avenir, Helvetica, sans serif';
export const FONT_FAMILY_BOLD = `Avenir-Black, Avenir, Helvetica, sans serif`;
export const BUTTON_HEIGHT = 44;
export const INPUT_HEIGHT = 44;
export const INPUT_HEIGHT_SMALL = 38;
export const BORDER_RADIUS = 6;
export const SPACING = 8;

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 2000,
    },
  },
  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          color: Color.Gray7,
          '&:nth-last-of-type(2)': {
            color: Color.Black,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // Custom scrollbar styles
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            width: 6,
            height: 6,
            backgroundColor: Color.Gray0,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            minHeight: 6,
            border: `3px solid ${Color.SeaGreen}`,
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: Color.Gray0,
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: Color.Gray0,
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: Color.Gray0,
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: Color.Gray0,
            borderRadius: 8,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: '2px 12px',
        },
        standardSuccess: {
          background: Color.SuccessBackground,
          color: Color.SeaGreen,
        },
        standardError: {
          background: Color.ErrorBackground,
          color: Color.Error1,
        },
        standardInfo: {
          background: Color.White,
          color: Color.Black,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        containedPrimary: {
          backgroundColor: Color.JungleGreen,
        },
        containedError: {
          ':hover': {
            backgroundColor: Color.ErrorBackground,
          },
        },
        root: {
          textTransform: 'none',
          fontWeight: FontWeight.SemiBold,
          borderRadius: BORDER_RADIUS * 4,
        },
        contained: {
          height: BUTTON_HEIGHT,
          boxShadow: 'none',
        },
        outlined: {
          height: BUTTON_HEIGHT,
        },
        sizeSmall: {
          height: BUTTON_HEIGHT * 0.6818,
        },
        sizeMedium: {
          height: BUTTON_HEIGHT * 0.8636,
        },
        sizeLarge: {
          height: BUTTON_HEIGHT,
        },
        text: {
          ':hover': {
            backgroundColor: 'transparent',
            opacity: 0.75,
          },
        },
        containedInfo: {
          backgroundColor: Color.SuccessBackground,
          color: Color.SeaGreen,
          ':hover': {
            backgroundColor: Color.SuccessBackground,
            color: Color.SeaGreen,
          },
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: 10,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        colorPrimary: {
          backgroundColor: 'transparent',
          color: 'transparent',
        },
        colorSecondary: {
          backgroundColor: 'transparent',
          color: 'transparent',
        },
        root: {
          width: 18,
          height: 18,
          margin: `${SPACING}px ${SPACING}px ${SPACING}px 0px`,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderLeft: '2px solid transparent',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          flexShrink: 0,
          whiteSpace: 'nowrap',
        },
        paper: {
          padding: `${SPACING * Spacing.XXXLarge}px ${SPACING * Spacing.Large}px`,
          overflowX: 'hidden',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: BORDER_RADIUS,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: FontSize.S,
          fontWeight: FontWeight.Default,
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          height: INPUT_HEIGHT,
          '-webkit-text-fill-color': 'unset !important',
        },
        sizeSmall: {
          height: INPUT_HEIGHT_SMALL,
        },
        input: {
          padding: '11px 12px',
        },
        notchedOutline: {
          border: 'none', // hide default border (grayed on hover etc.)
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginBottom: SPACING,
          fontSize: FontSize.XXS,
          fontWeight: FontWeight.SemiBold,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: FontSize.XXS,
          fontWeight: FontWeight.Bold,
          color: Color.White,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
        },
        label: {
          fontSize: FontSize.XS,
          fontWeight: FontWeight.Default,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: { width: '100%' },
        ul: { justifyContent: 'space-between' },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          boxShadow: '0px 20px 23px rgba(14, 43, 46, 0.08)',
          borderRadius: 8,
        },
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: { color: Color.Gray7 },
      },
    },
  },
  spacing: SPACING,
  palette: {
    mode: 'light',
    text: {
      primary: Color.Gray9,
      secondary: Color.Gray9,
      disabled: 'unset',
    },
    primary: {
      main: Color.SeaGreen,
      contrastText: Color.White,
    },
    secondary: {
      main: Color.ElectricGreen,
      contrastText: Color.Gray9,
    },
    success: {
      main: Color.SeaGreen,
      contrastText: Color.White,
    },
    error: {
      main: Color.Error2,
      contrastText: Color.Error1,
    },
    info: {
      main: Color.White,
    },
  },
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  typography: {
    fontFamily: FONT_FAMILY,
    fontWeightRegular: FontWeight.Default,
    fontWeightMedium: FontWeight.SemiBold,
    fontWeightBold: FontWeight.Bold,
    button: {
      textTransform: 'none',
      fontWeight: FontWeight.SemiBold,
      fontStyle: 'normal',
    },
    h1: {
      fontSize: FontSize.XXL,
      lineHeight: 1.3,
    },
    h2: {
      fontSize: FontSize.XL,
    },
    h3: {
      fontSize: FontSize.L,
    },
    h4: {
      fontSize: FontSize.M,
    },
    h5: {
      fontSize: FontSize.S,
    },
    h6: {
      fontSize: FontSize.XS,
    },
    subtitle1: {
      fontSize: FontSize.Default,
    },
    subtitle2: {
      fontSize: FontSize.S,
    },
    body1: {
      fontSize: FontSize.XS,
    },
    body2: {
      fontSize: FontSize.XXS,
    },
    caption: {
      fontSize: FontSize.XXXS,
    },
  },
});
