import { QueryCache, QueryClient } from 'react-query';
import { captureError } from '@shared/errors/capture-error';

export const queryClient: QueryClient = new QueryClient({
  queryCache: new QueryCache(),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: captureError,
      staleTime: 1000 * 60 * 2, // 2 minutes
    },
    mutations: { onError: captureError },
  },
});
