import type { FetchErrorResponse } from '@services/api.service';
import { ApiService } from '@services/api.service';
import { API_ROUTES, ApiQueryKey } from '@shared/constants/api.constants';
import type { B2BUserInfo } from '@services/types/user/user.types';
import { UserApiMapper } from '@services/mappers/user.api.mapper';
import type { B2BUserInfoApiResponse } from '@services/types/user/user.api-response.types';
import { useQuery } from 'react-query';
import { useUserStore } from '@stores/user.store';

export const findB2bUserInfoQuery = async (): Promise<B2BUserInfo> => {
  const result = await ApiService.get<B2BUserInfoApiResponse>(API_ROUTES.FindB2BUserInfo);

  return UserApiMapper.toB2BUserInfo(result.data);
};

export const useFindB2bUserInfo = (enabled = true) => {
  const userStore = useUserStore();

  return useQuery<B2BUserInfo, FetchErrorResponse, B2BUserInfo>([ApiQueryKey.FindB2BUserInfo, userStore.user?.id], () => findB2bUserInfoQuery(), {
    staleTime: 1000 * 60 * 5,
    enabled,
  }); // 5 minutes fresh
};
