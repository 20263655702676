import type { FC, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { RoutePath } from '@shared/constants/routing.constants';
import { useFindB2bUserInfo } from '@services/queries/user/find-b2b-user-info.query';
import { UserPermission } from '@services/types/user/user.api-response.types';
import { useUserStore } from '@stores/user.store';

export interface PrivateRouteRedirectState extends Location {}

interface PrivateRouteProps {
  permissionLevel?: UserPermission;
}

export const PrivateRoute: FC<PropsWithChildren<PrivateRouteProps>> = ({ permissionLevel = UserPermission.Read, children }) => {
  const location = useLocation();
  const { data: b2bUserInfo } = useFindB2bUserInfo();
  const { user } = useUserStore();

  const allowAccess = b2bUserInfo?.permission && b2bUserInfo?.permission <= permissionLevel;

  // once we have data and know the user is not allowed access, redirect them to the login page
  if (!user?.email || (b2bUserInfo && !allowAccess)) {
    return <Navigate to={`${RoutePath.Auth}/${RoutePath.AuthLogin}`} replace state={location} />;
  }

  return <>{children}</>;
};
