export const decodeJwtPayload = <PayloadType extends Record<string, string | number | boolean>>(token: string) => {
  if (!token) {
    return null;
  }

  try {
    const [, base64EncodedPayload] = token.split('.');
    const payload = atob(base64EncodedPayload);

    return JSON.parse(payload) as PayloadType;
  } catch {
    return null;
  }
};
