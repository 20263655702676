import type { FC } from 'react';
import { useEffect } from 'react';
import { get, useController, useFormContext } from 'react-hook-form';
import type { ValidationTranslationKey } from '@i18n/locales/validation';
import type { Props as TextFieldProps } from '@components/core/TextField/TextField';
import { TextField } from '@components/core/TextField/TextField';
import type { Props as FormControlProps } from '@components/forms/Form/FormControl/FormControl';
import { FormControl } from '@components/forms/Form/FormControl/FormControl';
import { useDebounce } from 'use-debounce';
import { DEBOUNCE_DELAY_600MS } from '@shared/constants/function.constants';
import type { UseControllerProps } from 'react-hook-form/dist/types/controller';
import type { FieldError } from 'react-hook-form/dist/types/errors';
import type { TranslationKey } from '@i18n/locales';

type FormTextFieldAsyncValidator = {
  errorName: ValidationTranslationKey;
  isValid?: (value: string) => boolean;
  validate: (value: string) => Promise<boolean>;
};

export interface Props
  extends Omit<TextFieldProps, 'value' | 'helperTextId' | 'marginBottom'>,
    Pick<UseControllerProps, 'defaultValue'>,
    Pick<FormControlProps, 'errorTypeMapping' | 'helperText'> {
  asyncValidator?: FormTextFieldAsyncValidator;
  customError?: TranslationKey | null;
  setCustomError?: (value: null) => void;
  margin?: string;
  inputMargin?: number;
}

export const FormTextField: FC<Props> = ({
  customError,
  setCustomError,
  errorTypeMapping,
  asyncValidator,
  name,
  defaultValue,
  helperText,
  margin,
  inputMargin,
  ...props
}) => {
  const {
    formState: { errors },
    control,
    setError,
    clearErrors,
  } = useFormContext();
  const { field } = useController({ name, control, defaultValue });
  const [debouncedValue] = useDebounce(field.value, DEBOUNCE_DELAY_600MS);
  const error: FieldError = customError ? ({ type: customError } as FieldError) : get(errors, name);
  const hasError = Boolean(error);

  useEffect(() => {
    if (asyncValidator && debouncedValue && (asyncValidator.isValid ? asyncValidator.isValid(debouncedValue) : true)) {
      asyncValidator.validate(debouncedValue).then(isValid => {
        if (!isValid) {
          setError(name, { type: asyncValidator.errorName });
        } else {
          clearErrors(name);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    setCustomError && setCustomError(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  useEffect(() => {
    if (customError) {
      setError(name, { type: customError });
    } else {
      clearErrors(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customError]);

  return (
    <FormControl helperText={helperText} error={error} errorTypeMapping={errorTypeMapping} name={name} margin={margin} isTextField>
      <TextField {...props} {...field} marginBottom={inputMargin} hasError={hasError} />
    </FormControl>
  );
};
