import { create } from 'zustand';
import type { JSX } from 'react';

export interface Dialog {
  id: number;
  name: string;
  content?: JSX.Element;
}

interface DialogStoreState {
  opened: boolean;
  dialog: Dialog | null;
  showDialog: (dialog: Omit<Dialog, 'id'> | null) => void;
  hideDialog: () => void;
}

export const useDialogStore = create<DialogStoreState>(set => ({
  opened: false,
  dialog: null,
  showDialog: dialog => {
    set({ dialog: dialog ? { ...dialog, id: new Date().valueOf() } : null, opened: true });
  },
  hideDialog: () => {
    set({ opened: false });
    setTimeout(() => set({ dialog: null }), 200);
  },
}));
