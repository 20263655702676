import type { SnackbarOrigin } from '@mui/material';
import { Alert, Snackbar } from '@mui/material';
import type { SyntheticEvent } from 'react';
import { useCallback, useState, useEffect } from 'react';
import { useToastStore } from '@stores/toast.store';
import { css } from '@emotion/css';
import { theme } from '@/theme';
import { ToastBody } from './components/ToastBody';
import type { SnackbarCloseReason } from '@mui/material/Snackbar/Snackbar';
import { useMediaQueries } from '@hooks/uesMediaQueries';

const styles = {
  alert: css`
    width: 100%;
    display: flex;
    align-items: center;
    max-height: 200px;
    overflow: hidden;
    ${theme.breakpoints.up('sm')} {
      min-width: 300px;
    }
  `,
};

export const Toast = () => {
  const { toast, showToast } = useToastStore();
  const { isMobile } = useMediaQueries();
  const anchorOrigin: SnackbarOrigin = isMobile ? { vertical: 'bottom', horizontal: 'center' } : { vertical: 'top', horizontal: 'right' };
  const [open, setOpen] = useState(false);

  useEffect(() => {
    toast?.id && setOpen(true);
  }, [toast?.id]);

  const handleClose = useCallback((event: SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    // setTimeout used for smooth transition
    setTimeout(() => showToast(null), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // using toast id as key to keep each new toast on screen for 4000 milliseconds; zIndex > chat widget icon
  return (
    <Snackbar key={toast?.id} anchorOrigin={anchorOrigin} open={open} autoHideDuration={40000} onClose={handleClose} sx={{ zIndex: 2147483601 }}>
      {toast ? (
        <Alert onClose={handleClose} severity={toast.severity} className={styles.alert} data-testid={`ev-toast-${toast.severity}`} icon={toast.icon}>
          <ToastBody toast={toast} />
        </Alert>
      ) : undefined}
    </Snackbar>
  );
};
