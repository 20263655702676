import type { TooltipProps } from '@mui/material/Tooltip';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTooltip } from '@components/core/Tooltip/Tooltip.style';
import type { TranslationKey } from '@i18n/locales';

export interface Props extends Pick<TooltipProps, 'children' | 'className' | 'placement'> {
  color?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  title?: ReactNode;
  titleTranslationKey?: TranslationKey;
  hide?: boolean;
}

export const Tooltip: FC<Props> = ({ children, titleTranslationKey, title, hide, ...props }) => {
  const { t } = useTranslation();

  const Content = (() => {
    switch (true) {
      case title !== undefined: {
        return <>{title}</>;
      }
      case titleTranslationKey !== undefined: {
        return t(titleTranslationKey!);
      }
      default: {
        throw new Error('Invalid tooltip title type');
      }
    }
  })();

  if (hide) {
    return children;
  }

  return (
    <StyledTooltip {...props} arrow title={Content}>
      {children}
    </StyledTooltip>
  );
};
