import type { TooltipTranslationKey } from '@i18n/locales/tooltip';

export type InfoTooltipExternalUrlType =
  | 'tooltip.chargers.smart-charge-enabled'
  | 'tooltip.chargers.charger-lock'
  | 'tooltip.vehicles.vehicle-data'
  | 'tooltip.users.permission';

export const InfoTooltipLearnMoreUrls: Pick<Record<TooltipTranslationKey, string>, InfoTooltipExternalUrlType> = {
  'tooltip.chargers.smart-charge-enabled': 'https://support.ev.energy/en/support/solutions/80000089630',
  'tooltip.chargers.charger-lock': 'https://support.ev.energy/en/support/solutions/articles/80000955422-what-is-the-charger-lock-feature-',
  'tooltip.vehicles.vehicle-data':
    'https://support.ev.energy/en/support/solutions/articles/80000648168-how-does-ev-energy-directly-integrate-with-your-vehicle-',
  'tooltip.users.permission': 'https://support.ev.energy/en/support/solutions/articles/80001062491-what-does-my-permission-level-mean-',
};
