import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import type { PandoButtonProps as ButtonProps } from '@components/core/Button/Button';
import { Button } from '@components/core/Button/Button';

interface Props extends ButtonProps {}

export const FormButton: FC<Props> = props => {
  const { formState } = useFormContext();
  const disabled = props.disabled || (props.type === 'submit' && formState.isSubmitted && !formState.isValid);

  return <Button {...props} disabled={disabled} />;
};
