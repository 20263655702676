import { css } from '@views/Auth/shared/PrivacyPolicy/PrivacyPolicy.style';

export const PrivacyPolicy = () => {
  return (
    <div>
      <style>{css}</style>
      <div className='c16'>
        <p className='c22 title' id='h.204tl1uxvu4d'>
          <span className='c19'>Pando Services Privacy Policy</span>
        </p>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <p className='c28 subtitle' id='h.2v2cut7104wx'>
          <span className='c0'>
            Version 1.1
            <br />
            Date: 13th September 2022
          </span>
        </p>
        <h1 className='c6' id='h.lezopvqrlpa0'>
          <span className='c8'>General</span>
        </h1>
        <p className='c9'>
          <span className='c0'>
            This privacy notice describes how EV DOT ENERGY Limited (&ldquo;ev.energy&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo;) processes your
            personal data related to your use of the &ldquo;Pando&rdquo; platform and related services (jointly &ldquo;Pando Services&rdquo;). In this
            privacy policy, we explain who we are, why and how we collect, store, share and use (together, &quot;process&quot;) your personal data,
            your rights and how to contact us.
          </span>
        </p>
        <h1 className='c6' id='h.9mnzqvw1g8rj'>
          <span className='c8'>ev.energy as the Data Controller</span>
        </h1>
        <p className='c9'>
          <span className='c0'>
            We are the Data Controller when processing personal data related to the provision of the Pando Services to you. We have detailed the
            circumstances in which we are a Data Controller. We&rsquo;ll only use the personal data you have provided for purposes for which we
            collected it, namely, the provision of the Pando platform and any additional services requested.
          </span>
        </p>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <p className='c9'>
          <span className='c0'>
            This list will be updated periodically and therefore may not be exhaustive at the point of viewing it. For an up to date list of
            processing activities for which we are a Data Controller, please contact us at the details below. Where there is any change in our
            processing activities, we will notify the main account holder in writing to inform them of the changes, the purpose and the legal basis
            for processing.
          </span>
        </p>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <p className='c9'>
          <span className='c8'>PERSONAL DATA TYPE</span>
        </p>
        <ul className='c2 lst-kix_6lt3h7pagu6j-0 start'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Contract information</span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>PURPOSE</span>
        </p>
        <ul className='c2 lst-kix_bp7r3i8fxc7b-0 start'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To perform or enter into any contract we have with you</span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>LEGAL BASIS FOR PROCESSING</span>
        </p>
        <ul className='c2 lst-kix_tsajsm1bwvxh-0 start'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Contract</span>
          </li>
        </ul>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <p className='c9'>
          <span className='c8'>PERSONAL DATA TYPE</span>
        </p>
        <ul className='c2 lst-kix_mfbmnagqdfx4-0 start'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Information relating to payments and/or billing</span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>PURPOSE</span>
        </p>
        <ul className='c2 lst-kix_rgww0z44sfp8-0 start'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To administrate yours or your company&#39;s accounts with us.</span>
          </li>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To verify payments and bills.</span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>LEGAL BASIS FOR PROCESSING</span>
        </p>
        <ul className='c2 lst-kix_lxi3gt91oicl-0 start'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Consent</span>
          </li>
        </ul>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <p className='c9'>
          <span className='c8'>PERSONAL DATA TYPE</span>
        </p>
        <ul className='c2 lst-kix_kontqaofes6-0 start'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Information relating to transactions</span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>PURPOSE</span>
        </p>
        <ul className='c2 lst-kix_svdi7m22koi-0 start'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>
              In the event that we sell or buy any business or assets that will provide Pando Services or we are the subject of acquisition by a third
              party.
            </span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>LEGAL BASIS FOR PROCESSING</span>
        </p>
        <ul className='c2 lst-kix_11207x3aqkgi-0 start'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Contract</span>
          </li>
        </ul>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <p className='c9'>
          <span className='c8'>PERSONAL DATA TYPE</span>
        </p>
        <ul className='c2 lst-kix_y4ol7ohcve5x-0 start'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Contact information</span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>PURPOSE</span>
        </p>
        <ul className='c2 lst-kix_q1cf02qjjtp6-0 start'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To provide support for the Pando platform;</span>
          </li>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To comply with any data rights requests you make;</span>
          </li>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To send you promotional or marketing updates (you can opt-out here!).</span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>LEGAL BASIS FOR PROCESSING</span>
        </p>
        <ul className='c2 lst-kix_8wnrtdsazz79-0 start'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Legitimate Interest</span>
          </li>
        </ul>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <p className='c9'>
          <span className='c8'>PERSONAL DATA TYPE</span>
        </p>
        <ul className='c2 lst-kix_y4ol7ohcve5x-0'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Usage Analytics and Statistics</span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>PURPOSE</span>
        </p>
        <ul className='c2 lst-kix_q1cf02qjjtp6-0'>
          <li className='c5 li-bullet-0'>
            <span className='c17'>To help us understand usage and performance of the Pando platform and improve it. You can </span>
            <span className='c17 c21'>
              <a
                className='c23'
                href='https://www.google.com/url?q=https://www.hotjar.com/policies/do-not-track/&amp;sa=D&amp;source=editors&amp;ust=1664803982825275&amp;usg=AOvVaw0VEh35o5n9lmdLQoFnb-Rt'
              >
                opt out
              </a>
            </span>
            <span className='c0'>.</span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>LEGAL BASIS FOR PROCESSING</span>
        </p>
        <ul className='c2 lst-kix_8wnrtdsazz79-0'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Legitimate Interest</span>
          </li>
        </ul>
        <h1 className='c6' id='h.xm60aiwthy5n'>
          <span className='c8'>ev.energy as the Data Processor</span>
        </h1>
        <p className='c9'>
          <span className='c0'>
            We are the Data Processor when processing data related to your use of the Pando Platform. It is your responsibility as the Data Controller
            to comply with and perform your obligations under data protection Law, including with regard to Data Subject rights, data security and
            confidentiality, and ensure you have an appropriate legal basis for the Processing of Personal Data. Ev.energy bears no responsibility for
            ensuring that your use of the Pando platform is in accordance with the relevant laws and regulations
          </span>
        </p>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <p className='c9'>
          <span className='c8'>PROCESSING ACTIVITY</span>
        </p>
        <ul className='c2 lst-kix_y4ol7ohcve5x-0'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Contact information provided by an account administrator</span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>PURPOSE</span>
        </p>
        <ul className='c2 lst-kix_q1cf02qjjtp6-0'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To provide user administration and enrolment.</span>
          </li>
        </ul>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <p className='c9'>
          <span className='c8'>PROCESSING ACTIVITY</span>
        </p>
        <ul className='c2 lst-kix_y4ol7ohcve5x-0'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>End user personal data provided by a Pando business m user</span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>PURPOSE</span>
        </p>
        <ul className='c2 lst-kix_q1cf02qjjtp6-0'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To provide the Pando platform.</span>
          </li>
        </ul>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <p className='c9'>
          <span className='c8'>PROCESSING ACTIVITY</span>
        </p>
        <ul className='c2 lst-kix_y4ol7ohcve5x-0'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Any other personal data provided by a Pando user</span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>PURPOSE</span>
        </p>
        <ul className='c2 lst-kix_q1cf02qjjtp6-0'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To provide the Pando platform;</span>
          </li>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To comply with any lawful requests you make.</span>
          </li>
        </ul>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <p className='c9'>
          <span className='c8'>PROCESSING ACTIVITY</span>
        </p>
        <ul className='c2 lst-kix_y4ol7ohcve5x-0'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Any other personal data provided by a Pando user</span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>PURPOSE</span>
        </p>
        <ul className='c2 lst-kix_q1cf02qjjtp6-0'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To provide the Pando platform;</span>
          </li>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To comply with any lawful requests you make.</span>
          </li>
        </ul>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <p className='c9'>
          <span className='c8'>PROCESSING ACTIVITY</span>
        </p>
        <ul className='c2 lst-kix_y4ol7ohcve5x-0'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Hardware Integration Data</span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>PURPOSE</span>
        </p>
        <ul className='c2 lst-kix_q1cf02qjjtp6-0'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To provide the Pando platform;</span>
          </li>
        </ul>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <p className='c9'>
          <span className='c8'>PROCESSING ACTIVITY</span>
        </p>
        <ul className='c2 lst-kix_y4ol7ohcve5x-0'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Use of the platform to further process end user personal data</span>
          </li>
        </ul>
        <p className='c9'>
          <span className='c8'>PURPOSE</span>
        </p>
        <ul className='c2 lst-kix_y4ol7ohcve5x-0'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To perform services under the contract;</span>
          </li>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To process end user personal data in accordance with your instructions;</span>
          </li>
          <li className='c5 li-bullet-0'>
            <span className='c0'>To provide insights, analytics or statistics based on the aggregation of personal data.</span>
          </li>
        </ul>
        <h1 className='c6' id='h.4ymufyxy4dtx'>
          <span className='c8'>ev.energy and you as Separate Data Controllers</span>
        </h1>
        <p className='c9'>
          <span className='c0'>
            There will be situations where your use of the Pando Platform coincides with the End Customer User&rsquo;s use of the B2C ev.energy mobile
            application. Unless there is prior agreement between the Parties, access to the Pando platform will not give you access to the data
            obtained from the End Customer User for which ev.energy is and will always remain the Data Controller for. In the event an agreement
            between the parties is made for access to the data for which we are a Controller, the Parties will be Controllers of the personal data
            lawfully shared, each using it for the predetermined purposes.{' '}
          </span>
        </p>
        <h1 className='c6' id='h.gc9z8cb0nwr2'>
          <span className='c8'>Data Retention</span>
        </h1>
        <p className='c9'>
          <span className='c0'>
            We will retain data under the Agreement for as long as there is a commercial relationship between the Parties relevant to this Privacy
            Policy. If there is no longer a relevant commercial relationship then we will provide you with the option to export your data or have it
            permanently deleted.
          </span>
        </p>
        <h1 className='c6' id='h.pll1s4fjl87x'>
          <span className='c8'>Subprocessors</span>
        </h1>
        <p className='c9'>
          <span className='c0'>
            This list of subprocessors is updated periodically. If you require an up to date version, please contact us at the details below.
          </span>
        </p>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <table className='c14'>
          <tr className='c24'>
            <td className='c26' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>NAME</span>
              </p>
            </td>
            <td className='c15' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>DATA</span>
              </p>
            </td>
            <td className='c18' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>PURPOSE OF PROCESSING</span>
              </p>
            </td>
            <td className='c27' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>ENTITY COUNTRY/AREA</span>
              </p>
            </td>
          </tr>
          <tr className='c1'>
            <td className='c11' colSpan={1} rowSpan={1}>
              <p className='c7'>
                <span className='c17'>
                  <a
                    className='c23'
                    href='https://www.google.com/url?q=https://aws.amazon.com/privacy/&amp;sa=D&amp;source=editors&amp;ust=1664803982832398&amp;usg=AOvVaw0GBgnmOU5IWyxaLjwBv8Il'
                  >
                    Amazon Web Services
                  </a>
                </span>
              </p>
            </td>
            <td className='c10' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>All Business User data and End Customer data</span>
              </p>
            </td>
            <td className='c13' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>Cloud service provider</span>
              </p>
            </td>
            <td className='c12' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>Republic of Ireland</span>
              </p>
            </td>
          </tr>
          <tr className='c1'>
            <td className='c11' colSpan={1} rowSpan={1}>
              <p className='c7'>
                <span className='c0'>Freshworks</span>
              </p>
            </td>
            <td className='c10' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>
                  The information included by the user reaching out to ev.energy, such as contact information for self other users within their
                  organisation and affected End Customer data
                </span>
              </p>
            </td>
            <td className='c13' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>Customer service platform supporting customer interactions by email or telephone</span>
              </p>
            </td>
            <td className='c12' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>EEA </span>
              </p>
            </td>
          </tr>
          <tr className='c1'>
            <td className='c11' colSpan={1} rowSpan={1}>
              <p className='c7'>
                <span className='c0'>Zoom</span>
              </p>
            </td>
            <td className='c10' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>Business User data and any data shared about third parties by the business user. </span>
              </p>
            </td>
            <td className='c13' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>To enable virtual face-to-face customer support or for pre-contractual</span>
              </p>
            </td>
            <td className='c12' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>USA (DPA with IDTA)</span>
              </p>
            </td>
          </tr>
          <tr className='c1'>
            <td className='c11' colSpan={1} rowSpan={1}>
              <p className='c7'>
                <span className='c0'>Hotjar</span>
              </p>
            </td>
            <td className='c10' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>Business User analytics and usage data, IP address and UUID</span>
              </p>
            </td>
            <td className='c13' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>To understand usage and improve the platform </span>
              </p>
            </td>
            <td className='c12' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>EU</span>
              </p>
            </td>
          </tr>
          <tr className='c1'>
            <td className='c11' colSpan={1} rowSpan={1}>
              <p className='c7'>
                <span className='c0'>Datadog</span>
              </p>
            </td>
            <td className='c10' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>
                  Data pertaining to actions taken on the platform, such as user ID, name, email, end user data, charger serial number,{' '}
                </span>
              </p>
            </td>
            <td className='c13' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>To monitor the health of the Pando platform and efficiently resolve issues</span>
              </p>
            </td>
            <td className='c12' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>USA (DPA with SCCs)</span>
              </p>
            </td>
          </tr>
          <tr className='c1'>
            <td className='c11' colSpan={1} rowSpan={1}>
              <p className='c7'>
                <span className='c0'>Sentry</span>
              </p>
            </td>
            <td className='c10' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>User IDs and personal identifiers for Business Users and End Customer Users</span>
              </p>
            </td>
            <td className='c13' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>To resolve vulnerabilities and errors on the platform</span>
              </p>
            </td>
            <td className='c12' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>USA (DPA with SCCs)</span>
              </p>
            </td>
          </tr>
          <tr className='c1'>
            <td className='c11' colSpan={1} rowSpan={1}>
              <p className='c7'>
                <span className='c0'>Google Workspace</span>
              </p>
            </td>
            <td className='c10' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>All Business User data and End Customer data</span>
              </p>
            </td>
            <td className='c13' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>Internal email, file storage and productivity software provider</span>
              </p>
            </td>
            <td className='c12' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>EU</span>
              </p>
            </td>
          </tr>
          <tr className='c1'>
            <td className='c11' colSpan={1} rowSpan={1}>
              <p className='c7'>
                <span className='c0'>Slack</span>
              </p>
            </td>
            <td className='c10' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>All Business User data and End Customer data</span>
              </p>
            </td>
            <td className='c13' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>Internal instant messaging application</span>
              </p>
            </td>
            <td className='c12' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>EU</span>
              </p>
            </td>
          </tr>
          <tr className='c1'>
            <td className='c11' colSpan={1} rowSpan={1}>
              <p className='c7'>
                <span className='c0'>Codibly</span>
              </p>
            </td>
            <td className='c10' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>All Business User data and End Customer data</span>
              </p>
            </td>
            <td className='c13' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>Software engineering support</span>
              </p>
            </td>
            <td className='c12' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>Poland</span>
              </p>
            </td>
          </tr>
          <tr className='c1'>
            <td className='c11' colSpan={1} rowSpan={1}>
              <p className='c7'>
                <span className='c0'>Braze</span>
              </p>
            </td>
            <td className='c10' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>User IDs, email, names and organisation data for Business End Users</span>
              </p>
            </td>
            <td className='c13' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>CRM tool</span>
              </p>
            </td>
            <td className='c12' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>EU</span>
              </p>
            </td>
          </tr>
          <tr className='c1'>
            <td className='c11' colSpan={1} rowSpan={1}>
              <p className='c7'>
                <span className='c0'>Notion</span>
              </p>
            </td>
            <td className='c10' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>All Business User data and End Customer data</span>
              </p>
            </td>
            <td className='c13' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>Internal wiki for documentation, bug tracking, incident response, project management and communications</span>
              </p>
            </td>
            <td className='c12' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>USA (DPA with SCCs)</span>
              </p>
            </td>
          </tr>
          <tr className='c1'>
            <td className='c11' colSpan={1} rowSpan={1}>
              <p className='c7'>
                <span className='c0'>Atlassian/Jira</span>
              </p>
            </td>
            <td className='c10' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>All Business User data and End Customer data</span>
              </p>
            </td>
            <td className='c13' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>Internal issues tracking and project management tool </span>
              </p>
            </td>
            <td className='c12' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>EU</span>
              </p>
            </td>
          </tr>
          <tr className='c1'>
            <td className='c11' colSpan={1} rowSpan={1}>
              <p className='c7'>
                <span className='c0'>Stripe</span>
              </p>
            </td>
            <td className='c10' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>Name, email, bank account details</span>
              </p>
            </td>
            <td className='c13' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>Payment processing</span>
              </p>
            </td>
            <td className='c12' colSpan={1} rowSpan={1}>
              <p className='c4'>
                <span className='c0'>US (DPA with SCCs)</span>
              </p>
            </td>
          </tr>
        </table>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <h1 className='c6' id='h.zd1lw7oe0sdx'>
          <span className='c8'>Data protection rights</span>
        </h1>
        <p className='c9'>
          <span className='c0'>
            As a data subject, you have rights related to the processing of your personal data. If you would like to exercise any of these please
            contact us at support@ev.energy. Your rights include the following:
          </span>
        </p>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <ul className='c2 lst-kix_gq1x30hx4nyl-0 start'>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Right to Access information collected about you through the Pando Service.</span>
          </li>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Right to rectify or update any information about you that is inaccurate or incomplete.</span>
          </li>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Right to request that we restrict processing of your personal data.</span>
          </li>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Right to delete data about you.</span>
          </li>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Right to withdraw consent that you may have given.</span>
          </li>
          <li className='c5 li-bullet-0'>
            <span className='c0'>
              Right to request that we provide data about you in structured, commonly used format so that it can be transferred to another company.
            </span>
          </li>
          <li className='c5 li-bullet-0'>
            <span className='c0'>Right to file a complaint about the processing of your personal data with a supervisory authority.</span>
          </li>
        </ul>
        <p className='c3'>
          <span className='c0'></span>
        </p>
        <p className='c9'>
          <span className='c17'>Additionally, you have the right to complain to the UK Information Commissioner&#39;s Office (</span>
          <span className='c17 c21'>
            <a
              className='c23'
              href='https://www.google.com/url?q=https://www.ico.org.uk&amp;sa=D&amp;source=editors&amp;ust=1664803982850245&amp;usg=AOvVaw2RrEW4kYKGgU-NcCWInC5E'
            >
              https://www.ico.org.uk
            </a>
          </span>
          <span className='c0'>).</span>
        </p>
        <h1 className='c6' id='h.greh1h7w744k'>
          <span className='c8'>Further information</span>
        </h1>
        <p className='c9'>
          <span className='c0'>
            If you have inquiries regarding this privacy policy and previous versions of it or how we process personal data, please reach out to
            dpo@ev.energy.
          </span>
        </p>
      </div>
    </div>
  );
};
