import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { config } from '@utils/config';
import { HttpStatus } from '@shared/types/enums/http-status.enum';
import { getLocalStorageItem, LocalStorageItemKey, LocalStorageItemType, removeLocalStorageItem, setLocalStorageItem } from '@utils/local-storage';
import { refreshTokenCommand } from '@services/commands/auth/refresh-token.command';
import { Header } from '@shared/types/enums/header.enum';
import { RoutePath } from '@shared/constants/routing.constants';

export declare type FetchErrorResponse = AxiosError<unknown>;

const fetchConfig: AxiosRequestConfig = {
  withCredentials: true,
  baseURL: config.apiUrl.href,
  headers: {
    [Header.ContentType]: 'application/json',
  },
};

export const ApiService: AxiosInstance = axios.create(fetchConfig);

// rejected error handling based on https://www.bezkoder.com/axios-interceptors-refresh-token

const onFulfilled = (response: AxiosResponse) => response;

/**
 * API can throw 401 or 403 when access token is expired
 * @param error
 */
const isAccessError = (error: AxiosError) => error.response?.status === HttpStatus.UNAUTHORIZED || error.response?.status === HttpStatus.FORBIDDEN;

// eslint-disable-next-line sonarjs/cognitive-complexity
const onRejected = async (error: AxiosError) => {
  const refreshToken = getLocalStorageItem<string>(LocalStorageItemType.Auth, LocalStorageItemKey.RefreshToken);
  const originalConfig = error.config as AxiosRequestConfig & { _retry?: boolean };
  const accessError = isAccessError(error);
  const shouldRefreshExpiredAccessToken = accessError && !originalConfig._retry && refreshToken;
  const isRefreshingError = accessError && error.config?.data?.includes('grant_type=refresh_token');
  const isLoginError = accessError && error.config?.data?.includes('grant_type=password');

  if (shouldRefreshExpiredAccessToken) {
    originalConfig._retry = true;

    // return to avoid refreshing loop
    if (isRefreshingError) {
      removeLocalStorageItem(LocalStorageItemType.Auth, LocalStorageItemKey.AccessToken);
      removeLocalStorageItem(LocalStorageItemType.Auth, LocalStorageItemKey.RefreshToken);
      removeLocalStorageItem(LocalStorageItemType.Auth, LocalStorageItemKey.SsoLoginUsed);
      removeLocalStorageItem(LocalStorageItemType.Auth, LocalStorageItemKey.SsoLoginPartner);

      return Promise.reject(error);
    }

    try {
      const response = await refreshTokenCommand({ refreshToken });
      setLocalStorageItem(LocalStorageItemType.Auth, LocalStorageItemKey.AccessToken, response.accessToken);
      setLocalStorageItem(LocalStorageItemType.Auth, LocalStorageItemKey.RefreshToken, response.refreshToken);
      ApiService.defaults.headers.common[Header.Authorization] = `Bearer ${response.accessToken}`;
      if (originalConfig.headers) {
        originalConfig.headers[Header.Authorization] = `Bearer ${response.accessToken}`;
      } else {
        originalConfig.headers = {
          [Header.Authorization]: `Bearer ${response.accessToken}`,
        };
      }

      return ApiService(originalConfig);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  if (accessError && !isLoginError) {
    removeLocalStorageItem(LocalStorageItemType.Auth, LocalStorageItemKey.AccessToken);
    removeLocalStorageItem(LocalStorageItemType.Auth, LocalStorageItemKey.SsoLoginUsed);
    removeLocalStorageItem(LocalStorageItemType.Auth, LocalStorageItemKey.SsoLoginPartner);

    return window.location.replace(`${RoutePath.Auth}/${RoutePath.AuthLogin}`);
  }

  return Promise.reject(error);
};

ApiService.interceptors.response.use(onFulfilled, onRejected);
