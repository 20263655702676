import type { OrganisationParent, OrganisationType } from '@services/types/organisation/organisation.api-response.types';

export type UserApiPermission = 1 | 2 | 3;
export enum UserPermission {
  Admin = '1',
  Write = '2',
  Read = '3',
}

export const mapUserPermission = (value: UserApiPermission): UserPermission => {
  switch (value) {
    case 1:
      return UserPermission.Admin;
    case 2:
      return UserPermission.Write;
    case 3:
      return UserPermission.Read;
  }
};

export const getUserPermissionKeyByValue = (value: UserPermission) => {
  const indexOfS = Object.values(UserPermission).indexOf(value);

  return Object.keys(UserPermission)[indexOfS];
};

export enum UserApiErrorMessage {
  EMAIL_ALREADY_USED = 'This user already exists',
  EMAIL_ALREADY_INVITED = 'User has already been invited',
  ORGANISATION_MISSING = 'permission specified without organisation',
  ORGANISATION_PERMISSION_MISSING = 'organisation specified without permission',
  EMPTY_ORGANISATION = 'You cannot invite user to existing organisation, if you do not belong to any.',
}

export enum PartnerName {
  SunPower = 'SunPower',
}

export interface UserApiResponse {
  id: number;
  username: string;
  first_name?: string | null;
  last_name?: string | null;
  organisation?: string | null;
  is_active: boolean;
  date_joined?: string | null; // iso date
  last_login: string | null; // iso date
  permission: UserApiPermission;
}

export interface UserShortApiResponse {
  email?: string | null;
  username: string;
  first_name?: string | null;
  last_name?: string | null;
  pk: number;
}

export interface B2BUserInfoApiResponse {
  username: string;
  organisation?: string | null;
  permission: UserApiPermission;
  organisationTypes?: { name: OrganisationType }[];
  customerService: boolean;
  parents: OrganisationParent[];
}

export interface PendingUserInvitationApiResponse {
  id: string;
  email: string;
  organisation?: string | null;
  permission?: UserApiPermission | null;
  lastTokenCreatedAt: string | null; // iso date
  createNewOrganisation: boolean;
}
