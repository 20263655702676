import type { Fleet } from '@services/types/fleet/fleet.types';
import type { FetchErrorResponse } from '@services/api.service';
import { ApiService } from '@services/api.service';
import { API_ROUTES } from '@shared/constants/api.constants';
import { FleetApiMapper } from '@services/mappers/fleet.api.mapper';
import type { FleetApiResponse } from '@services/types/fleet/fleet.api-response.types';
import { useQuery } from 'react-query';

const findFleetDetails = async (fleetId: string | undefined) => {
  const result = await ApiService.get<FleetApiResponse>(`${API_ROUTES.FindFleets}${fleetId}`);

  return FleetApiMapper.toFleet(result.data);
};

export const useFindFleetDetails = (fleetId: string | undefined) => {
  return useQuery<Fleet, FetchErrorResponse, Fleet>([fleetId], () => findFleetDetails(fleetId), {
    enabled: Boolean(fleetId),
  });
};
