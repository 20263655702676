import type { AuthTokensApiResponse, UserInvitationTokenInfoApiResponse } from '@services/types/auth/auth.api-response.types';
import type { AuthTokens, UserInvitationTokenInfo } from '@services/types/auth/auth.types';

export class AuthApiMapper {
  static toAuthTokens(apiResponse: AuthTokensApiResponse): AuthTokens {
    return {
      accessToken: apiResponse.access_token,
      expiresIn: apiResponse.expires_in,
      refreshToken: apiResponse.refresh_token,
      scope: apiResponse.scope,
      tokenType: apiResponse.token_type,
    };
  }

  static toUserInvitationTokenInfo(apiResponse: UserInvitationTokenInfoApiResponse): UserInvitationTokenInfo {
    return {
      createNewOrganisation: apiResponse.create_new_organisation,
      organisation: apiResponse.organisation || null,
      isUserActive: apiResponse.is_user_active,
      // if t&c undefined = accepted
      termsAccepted: 't&c_accepted' in apiResponse ? apiResponse['t&c_accepted']! : true,
    };
  }
}
