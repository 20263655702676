import type { CustomDestination } from '@/analytics/avo-utils';
import { AvoEnv } from '@/analytics/avo-utils';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import { config } from '@utils/config';
import { Environment } from '@shared/types/enums/environment.enum';
import { AvoInspectorEnv } from 'avo-inspector';

/** Don't invoke this multiple times as per docs */

export const setupRudderstack = () => {
  rudderClient.load(config.rudderStack.apiKey, config.rudderStack.planeUrl);
};

export const rudderstackDestinationInterface: CustomDestination = {
  // We manually call this from within our Analytics.initialise function.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  make: () => {},
  /** userId may be an empty string to get around some avo codegen types. Rudderstack allows null which is desired for unauthenticated users */
  identify: userId => rudderClient.identify(userId || null, {}, {}),
  logPage: (eventName, eventProperties) => rudderClient.page(eventName, eventProperties),
  logEvent: (eventName, eventProperties) => rudderClient.track(eventName, eventProperties),
  setUserProperties: (userId, userProperties) =>
    rudderClient.identify(
      /** userId may be an empty string to get around some avo codegen types. Rudderstack allows null which is desired for unauthenticated users */
      userId || null,
      userProperties,
      {},
    ),
  unidentify: () => rudderClient.reset(),
  setGroupProperties: (_groupTypeName, groupId, groupProperties) => rudderClient.group(groupId, groupProperties),
  addCurrentUserToGroup: (_groupTypeName, groupId) => rudderClient.group(groupId),
};

export const rudderClient = new RudderAnalytics();

export const SKIP_TRACKING = config.instanceName !== Environment.Production;

export const AVO_ENV_MAPPER = {
  [Environment.Development]: {
    avo: AvoEnv.Dev,
    inspector: AvoInspectorEnv.Dev,
  },
  [Environment.Test]: {
    avo: AvoEnv.Dev,
    inspector: AvoInspectorEnv.Dev,
  },
  [Environment.Staging]: {
    avo: AvoEnv.Staging,
    inspector: AvoInspectorEnv.Staging,
  },
  [Environment.Production]: {
    avo: AvoEnv.Prod,
    inspector: AvoInspectorEnv.Prod,
  },
};
