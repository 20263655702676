import type { DrawerProps } from '@mui/material/Drawer';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { theme } from '@/theme';
import { Color } from '@shared/styles/color.enum';

export const NAVIGATION_DRAWER_WIDTH = 260;

interface StyledDrawerProps extends Pick<DrawerProps, 'open'> {}

export const transition = theme.transitions.create('width', {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.leavingScreen,
});

export const StyledDrawer = styled(MuiDrawer)<StyledDrawerProps>(({ open }) => ({
  transition: transition,
  width: open ? NAVIGATION_DRAWER_WIDTH : `calc(${theme.spacing(11.5)} + 1px)`,
  '& .MuiDrawer-paper': {
    overflow: open ? 'hidden' : 'visible',
    transition: transition,
    width: open ? NAVIGATION_DRAWER_WIDTH : `calc(${theme.spacing(11.5)} + 1px)`,
    backgroundColor: Color.JungleGreen,
    padding: 0,
  },
}));
