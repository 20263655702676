import type { FC, PropsWithChildren } from 'react';
import type { IconButtonProps } from '@mui/material/IconButton';
import MuiIconButton from '@mui/material/IconButton';
import { BTN_TEST_ID_PREFIX } from '@components/core/Button/Button';

interface Props extends Pick<IconButtonProps, 'onClick' | 'className' | 'disabled'> {
  name: string;
}

export const IconButton: FC<PropsWithChildren<Props>> = ({ children, name, className, onClick, disabled }) => {
  const id = `${BTN_TEST_ID_PREFIX}${name}`;

  return (
    <MuiIconButton className={className} onClick={onClick} disabled={disabled} aria-disabled={disabled} aria-label={name} data-testid={id}>
      {children}
    </MuiIconButton>
  );
};
