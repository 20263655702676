import { styled } from '@mui/material/styles';
import { theme } from '@/theme';
import { Color } from '@shared/styles/color.enum';

export const NoDataContainer = styled('div')(() => ({
  background: Color.White,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
}));

export const StyledImg = styled('img')`
  margin-bottom: ${theme.spacing(3)};
`;
