export enum RoutePath {
  Base = '/',
  All = '*',
  TermsAndConditions = 'terms-and-conditions',
  PrivacyPolicy = 'privacy-policy',

  OAuthRedirect = '/oauth',
  OAuthRedirectProvider = '/oauth/:provider',

  // Auth
  Auth = '/auth',
  AuthSignUpInvitation = 'signup',
  AuthSignUp = 'signup/password',
  AuthSignUpConfirmation = 'password-signup',
  AuthLogin = 'login',
  AuthLoginSso = 'login-sso',
  AuthResetPassword = 'reset-password',
  AuthNewPassword = 'new-password/:uid/:token',

  // Dashboard
  Dashboard = 'dashboard/',
  DashboardUsers = 'users',
  DashboardOrganisations = 'organisations',
  DashboardChargers = 'chargers',
  DashboardSites = 'sites',
  DashboardFleets = 'fleets',
  DashboardFleetDetails = 'fleets/:fleetId',
  DashboardChargerDetails = 'chargers/:id',
  DashboardSiteDetails = 'sites/:id',
  DashboardReleaseNotes = 'release-notes',
  DashboardSettingsSection = 'settings/:section',
  DashboardSettingsBase = 'settings',
  DashboardSettingsAllRoutes = 'settings/*',
  DashboardDrivers = 'drivers',
  DashboardSiteDriverDetails = 'sites/:siteId/drivers/:driverId',
  DashboardSettingsGeneral = 'general',

  // Commissioning
  Commissioning = 'commissioning/',
  CommissioningManufacturer = ':manufacturer',

  // Driver activate rfid
  DriverActivateRfid = 'activate-rfid',

  // Docs
  Docs = 'docs/',

  // Charger Testing Platform
  ChargerTestingPlatform = 'testing/',
  ChargerTestingSession = ':id',
}
