import { DateFormat, dateFormatter } from '@utils/date';
import type {
  SiteApiResponse,
  SiteChargingSessionStatisticsApiResponse,
  SiteDetailsApiResponse,
  SiteShareApiResponse,
} from '@services/types/site/site.api-response.types';
import type { Site, SiteChargingSessionStatistics, SiteDetails, SiteShare } from '@services/types/site/site.types';
import { formatCurrency } from '@utils/currency';
import { mapSiteSharePermission } from '@services/types/site/site.api-response.types';
import type { Money } from '@shared/types/interfaces/api-types.interface';

export class SiteApiMapper {
  static toSite(apiResponse: SiteApiResponse): Site {
    return {
      id: apiResponse.id.toString(),
      name: apiResponse.name,
      numberOfEVSEs: apiResponse.numberOfEVSEs?.toString() || '0',
      address: apiResponse.address || null,
      lastChanged: apiResponse.modifiedAt ? dateFormatter(apiResponse.modifiedAt, DateFormat.DateTime) : null,
      organisationContactEmail: apiResponse.organisation.contactEmail || null,
      organisationName: apiResponse.organisation.name,
      organisationId: apiResponse.organisation.id,
    };
  }

  static toSiteDetails(apiResponse: SiteDetailsApiResponse): SiteDetails {
    return {
      ...this.toSite(apiResponse),
    };
  }

  static toSiteChargingSessionsStatistics(apiResponse: SiteChargingSessionStatisticsApiResponse): SiteChargingSessionStatistics {
    return {
      totalRevenue: 'totalRevenue' in apiResponse && apiResponse.totalRevenue != null ? this.getRevenue(apiResponse.totalRevenue) : null,
      totalCount: 'totalCount' in apiResponse && apiResponse.totalCount != null ? apiResponse.totalCount.toString() || null : null,
      totalEnergykWh: 'totalEnergykWh' in apiResponse && apiResponse.totalEnergykWh != null ? `${apiResponse.totalEnergykWh.toFixed(2)} kWh` : null,
    };
  }

  static toSiteShare(apiResponse: SiteShareApiResponse): SiteShare {
    return {
      id: apiResponse.id.toString(),
      createdAt: dateFormatter(apiResponse.invitationDate, DateFormat.DateTime),
      email: apiResponse.email,
      objectId: apiResponse.objectId,
      organisation: apiResponse.invitedOrganisation || null,
      permission: mapSiteSharePermission(apiResponse.permission),
    };
  }

  static getRevenue(revenue: number | Money | null): string | null {
    switch (true) {
      case typeof revenue === 'number': {
        return (revenue as number).toFixed(2);
      }
      case typeof revenue === 'object': {
        return formatCurrency(revenue as Money);
      }
      default: {
        return null;
      }
    }
  }
}
