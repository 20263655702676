import type { TypographyProps } from '@mui/material/Typography';
import MuiTypography from '@mui/material/Typography';
import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import type { TranslationKey } from '@i18n/locales';
import { FontWeight } from '@shared/styles/font-weight.enum';
import { FONT_FAMILY_BOLD, theme } from '@/theme';
import { css, cx } from '@emotion/css';
import type { FontSize } from '@shared/styles/font-size.enum';

export interface Props
  extends Pick<
    TypographyProps,
    | 'id'
    | 'color'
    | 'align'
    | 'variant'
    | 'marginTop'
    | 'marginBottom'
    | 'marginRight'
    | 'marginLeft'
    | 'width'
    | 'whiteSpace'
    | 'overflow'
    | 'textOverflow'
    | 'maxWidth'
    | 'minWidth'
    | 'onClick'
    | 'alignSelf'
    | 'lineHeight'
  > {
  component?: 'span' | 'p' | 'label' | 'li' | 'h1';
  fontWeight?: FontWeight;
  fontSize?: FontSize;
  translationKey?: TranslationKey;
  translationData?: Partial<Record<string, string | number | null>>;
  isTranslationKeyHTML?: boolean; // indicates that translation key should be used as html
}

const styles = {
  clickable: css`
    cursor: pointer;
    user-select: none;
  `,
};

export const Typography: FC<PropsWithChildren<Props>> = ({
  translationKey,
  translationData = {},
  children,
  fontWeight = FontWeight.Default,
  marginBottom = theme.spacing(),
  isTranslationKeyHTML,
  ...props
}) => {
  const { t } = useTranslation();
  const className = cx({
    [styles.clickable]: !!props.onClick,
  });

  return (
    <MuiTypography
      className={className}
      marginBottom={marginBottom}
      fontWeight={fontWeight}
      fontFamily={fontWeight === FontWeight.Black ? FONT_FAMILY_BOLD : undefined}
      dangerouslySetInnerHTML={translationKey && isTranslationKeyHTML ? { __html: t(translationKey, translationData) } : undefined}
      {...props}
    >
      {translationKey && !isTranslationKeyHTML ? t(translationKey, translationData) : children}
    </MuiTypography>
  );
};
