import { useLocalStorage } from '@hooks/useLocalStorage';
import { LocalStorageItemKey, LocalStorageItemType } from '@utils/local-storage';
import { useEffect } from 'react';
import { generateRandomString } from '@utils/string';

export const useOAuthStateLocalStorageData = () => {
  const [state, setState, removeState] = useLocalStorage<string>(LocalStorageItemType.Auth, LocalStorageItemKey.OAuthState, '');

  useEffect(() => {
    if (!state) {
      setState(generateRandomString(10));
    }
  }, [setState, state]);

  return { state, setState, removeState };
};
