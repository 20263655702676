import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSsoLoginPayload } from '@views/Auth/LoginSso/hooks/useSsoLoginPayload';
import { RoutePath } from '@shared/constants/routing.constants';
import { handleAuthTokensResponse } from '@services/commands/auth/login.command';
import { useUserStore } from '@stores/user.store';
import { refreshTokenCommand } from '@services/commands/auth/refresh-token.command';
import { Loader } from '@components/core/Loader/Loader';
import { getApiErrorBody } from '@utils/api';
import { logger } from '@utils/logger';
import { useAuthTokensLocalStorageData } from '@hooks/auth/useAuthTokensLocalStorageData';

export const LoginSso: FC = () => {
  const userStore = useUserStore();
  const { setAccessToken, setRefreshToken, removeRefreshToken } = useAuthTokensLocalStorageData();
  const [hasError, setHasError] = useState(false);
  const payload = useSsoLoginPayload();

  useEffect(() => {
    if (payload && payload.refresh_token) {
      refreshTokenCommand({ refreshToken: payload.refresh_token })
        .then(response => {
          if (!response) {
            setHasError(true);
          } else {
            handleAuthTokensResponse(userStore, setAccessToken, setRefreshToken, removeRefreshToken, true)(response);
          }
        })
        .catch(error => {
          logger.error('Login SSO error', { error: getApiErrorBody(error) });
          setHasError(true);
        });
    } else {
      setHasError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload?.refresh_token]);

  if (!payload || hasError) {
    return <Navigate to={`${RoutePath.Auth}/${RoutePath.AuthLogin}`} replace />;
  }

  return <Loader position='relative' color='secondary' />;
};
