import type { FC } from 'react';
import type { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';
import type { DashboardBreadcrumbsTranslationKey } from '@i18n/locales/dashboard-breadcrumbs';
import { useTranslation } from 'react-i18next';

interface Props extends BreadcrumbComponentProps<'id'> {
  translationKey: DashboardBreadcrumbsTranslationKey;
}

export const BreadcrumbValue: FC<Props> = ({ translationKey, match }) => {
  const { t } = useTranslation();

  return <>{t(translationKey, match.params)}</>;
};
