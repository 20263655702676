import { useEffect } from 'react';
import { withProfiler } from '@sentry/react';
import { useInitSentry } from '@hooks/bootstrap/useInitSentry';
import { App } from './App';
import { useSetupAuth } from '@hooks/bootstrap/useSetupAuth';
import { useInitHotjar } from '@hooks/bootstrap/useInitHotjar';
import { useUserPreferences } from '@hooks/useUserPreferences';
import { AvoClient } from '@/analytics/avo-client';

/**
 * Should be used for app bootstrap
 */
export const AppContainer = withProfiler(() => {
  const [isSentryInitialized, initSentry] = useInitSentry();
  const [isHotjarInitialized, initHotjar] = useInitHotjar();
  const [isSetup, setupAuth] = useSetupAuth();
  useUserPreferences(); // init user time zone
  const isReady = Boolean(isSentryInitialized && isHotjarInitialized && isSetup);

  useEffect(() => {
    if (!isReady) {
      AvoClient.initialize();
      setupAuth().then(initSentry).then(initHotjar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  return <App isReady={isReady} />;
});
