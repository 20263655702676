import type { FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const OutletContainer = styled('div')`
  display: flex;
  overflow: hidden;
  flex: 1;
`;

export const DashboardSettingsSublayout: FC<PropsWithChildren<unknown>> = () => {
  return (
    <OutletContainer>
      <Outlet />
    </OutletContainer>
  );
};
