import type { B2BUserInfo, PendingUserInvitation, User, UserShort } from '@services/types/user/user.types';
import type {
  B2BUserInfoApiResponse,
  PendingUserInvitationApiResponse,
  UserApiResponse,
  UserShortApiResponse,
} from '@services/types/user/user.api-response.types';
import { mapUserPermission, UserPermission } from '@services/types/user/user.api-response.types';
import { DateFormat, dateFormatter } from '@utils/date';
import { buildFullName } from '@utils/string';

export class UserApiMapper {
  static toUserShort(apiResponse: UserShortApiResponse): UserShort {
    return {
      id: apiResponse.pk,
      email: apiResponse.username,
      firstName: apiResponse.first_name || null,
      lastName: apiResponse.last_name || null,
      fullName: buildFullName(apiResponse.first_name, apiResponse.last_name),
    };
  }

  static toB2BUserInfo(apiResponse: B2BUserInfoApiResponse): B2BUserInfo {
    const permission: UserPermission = mapUserPermission(apiResponse.permission);

    return {
      email: apiResponse.username,
      organisation: apiResponse.organisation || null,
      permission,
      isAdmin: apiResponse.permission.toString() === UserPermission.Admin,
      hasWriteAccess: [UserPermission.Admin, UserPermission.Write].includes(apiResponse.permission.toString() as UserPermission),
      hasOrganisation: Boolean(apiResponse.organisation),
      organisationTypes: apiResponse.organisationTypes?.map(({ name }) => name) || [],
      isCustomerService: apiResponse.customerService,
      hasReadOnlyAccess: apiResponse.permission.toString() === UserPermission.Read,
      parents: apiResponse.parents || [],
    };
  }

  static toUser(apiResponse: UserApiResponse): User {
    const permission: UserPermission = mapUserPermission(apiResponse.permission);

    return {
      id: apiResponse.id,
      email: apiResponse.username,
      firstName: apiResponse.first_name || null,
      lastName: apiResponse.last_name || null,
      fullName: buildFullName(apiResponse.first_name, apiResponse.last_name),
      organisation: apiResponse.organisation || null,
      isActive: apiResponse.is_active,
      lastLogin: apiResponse.last_login ? dateFormatter(apiResponse.last_login, DateFormat.DateTime) : null,
      dateJoined: apiResponse.date_joined ? dateFormatter(apiResponse.date_joined, DateFormat.DateTime) : null,
      permission,
    };
  }

  static pendingInvitationToUser(invitation: PendingUserInvitation): User {
    return {
      id: +invitation.id,
      email: invitation.email,
      firstName: null,
      lastName: null,
      fullName: null,
      organisation: invitation.organisation || null,
      isActive: false,
      lastLogin: null,
      dateJoined: invitation.lastTokenCreatedAt ? dateFormatter(invitation.lastTokenCreatedAt, DateFormat.DateTime) : null,
      permission: invitation.permission,
    };
  }

  static toPendingUserInvitation(apiResponse: PendingUserInvitationApiResponse): PendingUserInvitation {
    const permission: UserPermission = apiResponse.permission ? mapUserPermission(apiResponse.permission) : UserPermission.Admin; // if not defined -> creating new organisation as admin

    return {
      id: apiResponse.id,
      email: apiResponse.email,
      lastTokenCreatedAt: apiResponse.lastTokenCreatedAt ? dateFormatter(apiResponse.lastTokenCreatedAt, DateFormat.DateTime) : null,
      organisation: apiResponse.organisation || null,
      permission,
      createNewOrganisation: apiResponse.createNewOrganisation,
    };
  }
}
