import type { FC } from 'react';
import { Suspense } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'; // are only included in bundles when process.env.NODE_ENV === 'development'
import '@utils/config';
import '@utils/logger';
import '@i18n';
import { Routing } from '@components/routing/Routing/Routing';
import { GlobalErrorFallback } from '@components/errors/GlobalErrorFallback';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { theme } from './theme';
import { queryClient } from './query-client';
import { Loader } from '@components/core/Loader/Loader';
import { Color } from '@shared/styles/color.enum';
import { css } from '@emotion/css';
import { ErrorBoundary } from '@sentry/react';
import { config } from '@utils/config';
import { Toast } from '@components/core/Toast/Toast';
import { Dialog } from '@components/core/Dialog/Dialog';
import { routes } from '@components/routing/Routing/Routing.config';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { RightDrawer } from '@components/core/RightDrawer/RightDrawer';

const styles = {
  fallback: css`
    background-color: ${Color.JungleGreen};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `,
};

const Fallback = () => (
  <div className={styles.fallback}>
    <Loader color='secondary' />
  </div>
);

interface Props {
  isReady: boolean;
}

export const App: FC<Props> = ({ isReady }) => {
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary fallback={GlobalErrorFallback} showDialog={config.logging.enabled && config.isStaging}>
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
              {config.isDebug && <ReactQueryDevtools initialIsOpen={false} />}
              <Suspense fallback={<Fallback />}>{isReady ? <Routing routes={routes} /> : <Fallback />}</Suspense>
              <Toast />
              <Dialog />
              <RightDrawer />
            </QueryClientProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
};
