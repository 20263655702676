import { FormTextField } from '@components/forms/Form/FormTextField/FormTextField';
import { Color } from '@shared/styles/color.enum';
import { FormButton } from '@components/forms/Form/FormButton/FormButton';
import { Form } from '@components/forms/Form/Form';
import type { FC } from 'react';
import { useCallback } from 'react';
import Joi from 'joi';
import { passwordSchema } from '@utils/validation-schema';
import { Typography } from '@components/core/Typography/Typography';
import { logger } from '@utils/logger';
import { sharedStyles } from '@shared/styles/shared-classes';
import { cx } from '@emotion/css';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from '@shared/constants/routing.constants';
import { useSetNewPassword } from '@services/commands/auth/set-new-password.command';
import { useToastStore } from '@stores/toast.store';
import { getApiErrorBody } from '@utils/api';
import { Container } from './NewPassword.style';

export type RouteParams = {
  token: string;
  uid: string;
};

export interface FormInputs {
  password: string;
  'password-confirm': string;
}

const formSchema = Joi.object<FormInputs>({
  password: passwordSchema.required(),
  'password-confirm': Joi.string().valid(Joi.ref('password')).required(),
});

export const NewPassword: FC = () => {
  const { token, uid } = useParams<RouteParams>();
  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useSetNewPassword();
  const showToast = useToastStore(state => state.showToast);

  const handleSubmit = useCallback(
    async (data: FormInputs) => {
      try {
        await mutateAsync({ password: data.password, 'password-confirm': data['password-confirm'], token: token!, uid: uid! });
        showToast({ severity: 'success', title: 'toast.success', message: 'toast.password-updated' });
        navigate(`${RoutePath.Auth}/${RoutePath.AuthLogin}`);
      } catch (error) {
        logger.error('Login error', { error: getApiErrorBody(error) });
        showToast({
          severity: 'error',
          title: 'toast.error',
          message: 'toast.password-update-failure',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token],
  );

  return (
    <Container>
      <Typography translationKey='auth-layout.new-password' variant='h1' color={Color.White} align='center' />
      <Typography translationKey='auth-layout.password-requirements' variant='subtitle2' color={Color.White} align='left' />
      <div className={sharedStyles.fullwidth}>
        <Form<FormInputs> onSubmit={handleSubmit} schema={formSchema}>
          <FormTextField name={'password' as keyof FormInputs} label='input.password-new' type='password' paletteMode='dark' />
          <FormTextField
            name={'password-confirm' as keyof FormInputs}
            label='input.password-confirm'
            errorTypeMapping={{ 'any.only': 'validation.password-confirm', 'any.required': 'validation.required' }}
            type='password'
            paletteMode='dark'
          />
          <div className={cx(sharedStyles.flexCentered, sharedStyles.fullwidth)}>
            <FormButton name='submit' type='submit' translationKey='auth-layout.set-up-new-password' fullWidth disabled={isLoading} />
          </div>
        </Form>
      </div>
    </Container>
  );
};
