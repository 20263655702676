import type { FC } from 'react';
import type { RouteObject } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';
import { useFindB2bUserInfo } from '@services/queries/user/find-b2b-user-info.query';
import { useUserStore } from '@stores/user.store';

interface Props {
  routes: RouteObject[];
}

export const Routing: FC<Props> = ({ routes }) => {
  const { user } = useUserStore();
  const isUserLoggedIn = user !== null;
  useFindB2bUserInfo(isUserLoggedIn);

  return useRoutes(routes);
};
