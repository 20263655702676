import type { ComplexityOptions } from 'joi-password-complexity';

export const PASSWORD_COMPLEXITY_CONFIG: ComplexityOptions = {
  max: 72,
  min: 10,
  upperCase: 1,
  lowerCase: 1,
  symbol: 1,
  numeric: 1,
};
