import { useLocalStorage } from '@hooks/useLocalStorage';
import { LocalStorageItemKey, LocalStorageItemType } from '@utils/local-storage';

export const useAuthTokensLocalStorageData = () => {
  const [accessToken, setAccessToken, removeAccessToken] = useLocalStorage<string | null>(
    LocalStorageItemType.Auth,
    LocalStorageItemKey.AccessToken,
    null,
  );
  const [refreshToken, setRefreshToken, removeRefreshToken] = useLocalStorage<string | null>(
    LocalStorageItemType.Auth,
    LocalStorageItemKey.RefreshToken,
    null,
  );

  return { accessToken, setAccessToken, removeAccessToken, refreshToken, setRefreshToken, removeRefreshToken };
};
