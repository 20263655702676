import { styled } from '@mui/material/styles';
import { FontWeight } from '@shared/styles/font-weight.enum';
import { theme } from '@/theme';

export const InfoIconContainer = styled('div')`
  display: inline-flex;
  margin-bottom: 2px;
  &:hover {
    cursor: help;
  }
`;

export const LearnMoreLink = styled('a')`
  color: white;
  text-decoration: none;
  font-weight: ${FontWeight.Bold};
  font-family: Avenir-Black, ${theme.typography.fontFamily};
  &:hover {
    opacity: 0.85;
  }
`;
