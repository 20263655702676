import { ApiService } from '@services/api.service';
import { API_ROUTES } from '@shared/constants/api.constants';
import type { UserShort } from '@services/types/user/user.types';
import { UserApiMapper } from '@services/mappers/user.api.mapper';
import type { UserShortApiResponse } from '@services/types/user/user.api-response.types';

export const findUserShort = async (): Promise<UserShort> => {
  const result = await ApiService.get<UserShortApiResponse>(API_ROUTES.FindUserShort);

  return UserApiMapper.toUserShort(result.data);
};
