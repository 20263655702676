import type { FetchErrorResponse } from '@services/api.service';
import { ApiService } from '@services/api.service';
import { API_ROUTES, ApiMutationKey } from '@shared/constants/api.constants';
import { useMutation } from 'react-query';

interface SetNewPasswordData {
  password: string;
  'password-confirm': string;
  token: string;
  uid: string;
}

interface SetNewPasswordRequestBody {
  new_password1: string;
  new_password2: string;
  uid: string;
  token: string;
}

export const setNewPasswordCommand = async ({ password, 'password-confirm': passwordConfirm, token, uid }: SetNewPasswordData): Promise<void> => {
  await ApiService.post<never, never, SetNewPasswordRequestBody>(API_ROUTES.ResetPasswordConfirm, {
    new_password1: password,
    new_password2: passwordConfirm,
    uid,
    token,
  });
};

export const useSetNewPassword = () =>
  useMutation<void, FetchErrorResponse, SetNewPasswordData>(ApiMutationKey.ResetPassword, (data: SetNewPasswordData) => setNewPasswordCommand(data));
