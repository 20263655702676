import type { AuthTokens } from '@services/types/auth/auth.types';
import type { FetchErrorResponse } from '@services/api.service';
import { ApiService } from '@services/api.service';
import type { AuthTokensApiResponse } from '@services/types/auth/auth.api-response.types';
import type { AxiosResponse } from 'axios';
import { API_ROUTES, ApiMutationKey } from '@shared/constants/api.constants';
import { AuthApiMapper } from '@services/mappers/auth.api.mapper';
import { useMutation } from 'react-query';

export interface OAuthVerificationData {
  code: string;
  state: string;
  provider: 'google-oauth2' | 'apple-id' | 'microsoft-graph';
}

interface OAuthVerificationRequestBody {
  code: string;
  state: string;
}

export const verifyOAuthCommand = async ({ provider, code, state }: OAuthVerificationData): Promise<AuthTokens | null> => {
  const result = await ApiService.post<AuthTokensApiResponse, AxiosResponse<AuthTokensApiResponse>, OAuthVerificationRequestBody>(
    `${API_ROUTES.VerifyOAuth}${provider}/`,
    { code, state },
  );

  return result?.data ? AuthApiMapper.toAuthTokens(result.data) : null;
};

export const useVerifyOAuth = () =>
  useMutation<AuthTokens | null, FetchErrorResponse, OAuthVerificationData>(ApiMutationKey.VerifyOAuth, (data: OAuthVerificationData) =>
    verifyOAuthCommand(data),
  );
