import type { FleetApiResponse } from '@services/types/fleet/fleet.api-response.types';
import type { Fleet } from '@services/types/fleet/fleet.types';
import { DateFormat, dateFormatter } from '@utils/date';

export class FleetApiMapper {
  static toFleet(apiResponse: FleetApiResponse): Fleet {
    return {
      id: apiResponse.id.toString(),
      name: apiResponse.name,
      organisation: apiResponse.organisation || null,
      createdAt: dateFormatter(apiResponse.createdAt, DateFormat.DateTime),
      modifiedAt: dateFormatter(apiResponse.modifiedAt, DateFormat.DateTime),
      numberOfVehicles: apiResponse.numberOfVehicles || 0,
    };
  }
}
