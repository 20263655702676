import { styled } from '@mui/material/styles';
import { theme } from '@/theme';
import type { Color } from '@shared/styles/color.enum';
import type { DrawerProps } from '@mui/material/Drawer';
import MuiDrawer from '@mui/material/Drawer';

interface StyledDrawerProps extends Pick<DrawerProps, 'open'> {
  backgroundColor?: Color;
  width?: string | number;
  isMobile: boolean;
}

const transition = theme.transitions.create('width', {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.leavingScreen,
});

export const StyledDrawer = styled(MuiDrawer)<StyledDrawerProps>(({ backgroundColor, width, isMobile }) => ({
  transition: transition,
  '& .MuiDrawer-paper': {
    transition: transition,
    backgroundColor,
    padding: isMobile ? `${theme.spacing(2)} ${theme.spacing(3)}` : `${theme.spacing(5)} ${theme.spacing(6)}`,
    minWidth: isMobile ? '100%' : '30%',
    maxWidth: isMobile ? '100%' : '50vw',
    width,
  },
}));

export const Header = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
}));
