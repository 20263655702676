import type { ButtonPressedProperties } from '@/analytics/avo-utils';
import { screenViewed } from '@/analytics/avo-utils';
import AvoUtils, { avoInspectorApiKey, buttonPressed } from '@/analytics/avo-utils';
import { AvoInspector } from 'avo-inspector';
import { AVO_ENV_MAPPER, rudderClient, rudderstackDestinationInterface, setupRudderstack, SKIP_TRACKING } from '@/analytics/avo-consts';
import { config } from '@utils/config';
import type { TranslationKey } from '@i18n/locales';
import i18n from '@/i18n';

interface ButtonPressedProps extends Omit<ButtonPressedProperties, 'buttonLabel'> {
  buttonLabelTranslationKey: TranslationKey;
  buttonLabel?: string;
}

const inspector = new AvoInspector({
  apiKey: avoInspectorApiKey,
  env: AVO_ENV_MAPPER[config.instanceName].inspector,
  version: '1.0.0',
  appName: 'web.ev.energy',
});

export class AvoClient {
  private static initialized = false;

  static initialize() {
    if (SKIP_TRACKING) {
      return;
    }
    setupRudderstack();
    rudderClient.ready(() => {
      AvoUtils.initAvo(
        {
          env: AVO_ENV_MAPPER[config.instanceName].avo,
          inspector,
        },
        {
          appName: 'Pando',
          appVersion: '1.0.0',
        },
        null,
        rudderstackDestinationInterface,
      );
      this.initialized = true;
    });
  }

  public static buttonPressed({ buttonName, screenName, buttonLabel, buttonLabelTranslationKey }: ButtonPressedProps) {
    if (this.initialized) {
      buttonPressed({
        buttonName,
        buttonLabel: buttonLabelTranslationKey ? i18n.t(buttonLabelTranslationKey) : buttonLabel || '',
        screenName,
      });
    }
  }

  public static screenViewed(userId?: number) {
    if (this.initialized) {
      const programName = window.location.pathname.split('/')[1];
      screenViewed({
        userId_: String(userId) || '',
        driverProgramName: programName,
        programName,
        screenName: window.location.pathname,
        customDestinationPageName_: window.location.pathname,
      });
    }
  }
}
