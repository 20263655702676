import { theme } from '@/theme';
import { useMediaQuery } from '@mui/material';

export const useMediaQueries = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallDesktop = useMediaQuery('@media (max-width: 1485px)');
  const isMediumDesktop = useMediaQuery('@media (max-width: 1800px)');

  const isNarrow = useMediaQuery('@media (max-height: 800px)');

  return { isMobile, isTablet, isSmallDesktop, isMediumDesktop, isNarrow };
};
