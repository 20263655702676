import { create } from 'zustand';
import type { AlertColor } from '@mui/material/Alert/Alert';
import type { ToastTranslationKey } from '@i18n/locales/toast';
import type { ReactNode, JSX } from 'react';

export interface ToastWithElement {
  id: number;
  element: JSX.Element;
  severity: AlertColor;
  icon?: ReactNode | null;
}

export interface ToastWithMessage {
  id: number;
  message: ToastTranslationKey | string;
  severity: AlertColor;
  title: ToastTranslationKey;
  icon?: ReactNode | null;
}

export type Toast = ToastWithElement | ToastWithMessage;

interface ToastStoreState {
  toast: Toast | null;
  showToast: (toast: Omit<ToastWithElement, 'id'> | Omit<ToastWithMessage, 'id'> | null) => void;
}

export const useToastStore = create<ToastStoreState>(set => ({
  toast: null,
  showToast: toast => set({ toast: toast ? { ...toast, id: new Date().valueOf() } : null }),
}));
