export const css = `
ul.lst-kix_6lt3h7pagu6j-0 {
    list-style-type: none
}

.lst-kix_8wnrtdsazz79-8>li:before {
    content: "\\0025a0  "
}

ul.lst-kix_6lt3h7pagu6j-1 {
    list-style-type: none
}

.lst-kix_8wnrtdsazz79-7>li:before {
    content: "\\0025cb  "
}

ul.lst-kix_6lt3h7pagu6j-8 {
    list-style-type: none
}

ul.lst-kix_6lt3h7pagu6j-6 {
    list-style-type: none
}

.lst-kix_8wnrtdsazz79-5>li:before {
    content: "\\0025a0  "
}

ul.lst-kix_6lt3h7pagu6j-7 {
    list-style-type: none
}

ul.lst-kix_6lt3h7pagu6j-4 {
    list-style-type: none
}

.lst-kix_8wnrtdsazz79-6>li:before {
    content: "\\0025cf  "
}

ul.lst-kix_6lt3h7pagu6j-5 {
    list-style-type: none
}

ul.lst-kix_6lt3h7pagu6j-2 {
    list-style-type: none
}

ul.lst-kix_6lt3h7pagu6j-3 {
    list-style-type: none
}

.lst-kix_8wnrtdsazz79-0>li:before {
    content: "\\0025cf  "
}

.lst-kix_8wnrtdsazz79-1>li:before {
    content: "\\0025cb  "
}

.lst-kix_8wnrtdsazz79-4>li:before {
    content: "\\0025cb  "
}

.lst-kix_8wnrtdsazz79-2>li:before {
    content: "\\0025a0  "
}

.lst-kix_8wnrtdsazz79-3>li:before {
    content: "\\0025cf  "
}

ul.lst-kix_8wnrtdsazz79-8 {
    list-style-type: none
}

ul.lst-kix_8wnrtdsazz79-7 {
    list-style-type: none
}

ul.lst-kix_8wnrtdsazz79-6 {
    list-style-type: none
}

ul.lst-kix_8wnrtdsazz79-5 {
    list-style-type: none
}

ul.lst-kix_8wnrtdsazz79-4 {
    list-style-type: none
}

ul.lst-kix_8wnrtdsazz79-3 {
    list-style-type: none
}

ul.lst-kix_8wnrtdsazz79-2 {
    list-style-type: none
}

ul.lst-kix_8wnrtdsazz79-1 {
    list-style-type: none
}

ul.lst-kix_8wnrtdsazz79-0 {
    list-style-type: none
}

ul.lst-kix_gq1x30hx4nyl-3 {
    list-style-type: none
}

ul.lst-kix_gq1x30hx4nyl-2 {
    list-style-type: none
}

ul.lst-kix_gq1x30hx4nyl-5 {
    list-style-type: none
}

ul.lst-kix_gq1x30hx4nyl-4 {
    list-style-type: none
}

ul.lst-kix_gq1x30hx4nyl-1 {
    list-style-type: none
}

ul.lst-kix_gq1x30hx4nyl-0 {
    list-style-type: none
}

ul.lst-kix_mfbmnagqdfx4-6 {
    list-style-type: none
}

ul.lst-kix_mfbmnagqdfx4-7 {
    list-style-type: none
}

ul.lst-kix_mfbmnagqdfx4-8 {
    list-style-type: none
}

ul.lst-kix_gq1x30hx4nyl-7 {
    list-style-type: none
}

ul.lst-kix_mfbmnagqdfx4-2 {
    list-style-type: none
}

ul.lst-kix_gq1x30hx4nyl-6 {
    list-style-type: none
}

ul.lst-kix_mfbmnagqdfx4-3 {
    list-style-type: none
}

.lst-kix_gq1x30hx4nyl-4>li:before {
    content: "\\0025cb  "
}

ul.lst-kix_mfbmnagqdfx4-4 {
    list-style-type: none
}

ul.lst-kix_gq1x30hx4nyl-8 {
    list-style-type: none
}

ul.lst-kix_mfbmnagqdfx4-5 {
    list-style-type: none
}

.lst-kix_gq1x30hx4nyl-3>li:before {
    content: "\\0025cf  "
}

.lst-kix_gq1x30hx4nyl-1>li:before {
    content: "\\0025cb  "
}

.lst-kix_gq1x30hx4nyl-2>li:before {
    content: "\\0025a0  "
}

.lst-kix_gq1x30hx4nyl-0>li:before {
    content: "\\0025cf  "
}

ul.lst-kix_mfbmnagqdfx4-0 {
    list-style-type: none
}

ul.lst-kix_y4ol7ohcve5x-8 {
    list-style-type: none
}

ul.lst-kix_mfbmnagqdfx4-1 {
    list-style-type: none
}

ul.lst-kix_y4ol7ohcve5x-7 {
    list-style-type: none
}

ul.lst-kix_y4ol7ohcve5x-6 {
    list-style-type: none
}

ul.lst-kix_y4ol7ohcve5x-5 {
    list-style-type: none
}

ul.lst-kix_y4ol7ohcve5x-4 {
    list-style-type: none
}

.lst-kix_11207x3aqkgi-6>li:before {
    content: "\\0025cf  "
}

.lst-kix_11207x3aqkgi-5>li:before {
    content: "\\0025a0  "
}

.lst-kix_11207x3aqkgi-4>li:before {
    content: "\\0025cb  "
}

.lst-kix_11207x3aqkgi-1>li:before {
    content: "\\0025cb  "
}

.lst-kix_11207x3aqkgi-3>li:before {
    content: "\\0025cf  "
}

.lst-kix_11207x3aqkgi-2>li:before {
    content: "\\0025a0  "
}

.lst-kix_bp7r3i8fxc7b-2>li:before {
    content: "\\0025a0  "
}

ul.lst-kix_kontqaofes6-1 {
    list-style-type: none
}

ul.lst-kix_kontqaofes6-0 {
    list-style-type: none
}

.lst-kix_11207x3aqkgi-0>li:before {
    content: "\\0025cf  "
}

.lst-kix_bp7r3i8fxc7b-4>li:before {
    content: "\\0025cb  "
}

.lst-kix_svdi7m22koi-8>li:before {
    content: "\\0025a0  "
}

.lst-kix_svdi7m22koi-2>li:before {
    content: "\\0025a0  "
}

.lst-kix_11207x3aqkgi-7>li:before {
    content: "\\0025cb  "
}

.lst-kix_svdi7m22koi-6>li:before {
    content: "\\0025cf  "
}

.lst-kix_bp7r3i8fxc7b-0>li:before {
    content: "\\0025cf  "
}

.lst-kix_svdi7m22koi-4>li:before {
    content: "\\0025cb  "
}

.lst-kix_lxi3gt91oicl-7>li:before {
    content: "\\0025cb  "
}

.lst-kix_kontqaofes6-0>li:before {
    content: "\\0025cf  "
}

.lst-kix_q1cf02qjjtp6-6>li:before {
    content: "\\0025cf  "
}

.lst-kix_rgww0z44sfp8-5>li:before {
    content: "\\0025a0  "
}

.lst-kix_kontqaofes6-2>li:before {
    content: "\\0025a0  "
}

.lst-kix_kontqaofes6-4>li:before {
    content: "\\0025cb  "
}

.lst-kix_svdi7m22koi-0>li:before {
    content: "\\0025cf  "
}

.lst-kix_lxi3gt91oicl-1>li:before {
    content: "\\0025cb  "
}

.lst-kix_gq1x30hx4nyl-6>li:before {
    content: "\\0025cf  "
}

.lst-kix_q1cf02qjjtp6-8>li:before {
    content: "\\0025a0  "
}

.lst-kix_bp7r3i8fxc7b-8>li:before {
    content: "\\0025a0  "
}

.lst-kix_kontqaofes6-6>li:before {
    content: "\\0025cf  "
}

.lst-kix_kontqaofes6-8>li:before {
    content: "\\0025a0  "
}

.lst-kix_gq1x30hx4nyl-8>li:before {
    content: "\\0025a0  "
}

.lst-kix_lxi3gt91oicl-3>li:before {
    content: "\\0025cf  "
}

.lst-kix_rgww0z44sfp8-7>li:before {
    content: "\\0025cb  "
}

.lst-kix_bp7r3i8fxc7b-6>li:before {
    content: "\\0025cf  "
}

.lst-kix_lxi3gt91oicl-5>li:before {
    content: "\\0025a0  "
}

.lst-kix_6lt3h7pagu6j-1>li:before {
    content: "\\0025cb  "
}

ul.lst-kix_lxi3gt91oicl-0 {
    list-style-type: none
}

ul.lst-kix_lxi3gt91oicl-1 {
    list-style-type: none
}

.lst-kix_rgww0z44sfp8-3>li:before {
    content: "\\0025cf  "
}

.lst-kix_mfbmnagqdfx4-4>li:before {
    content: "\\0025cb  "
}

.lst-kix_mfbmnagqdfx4-6>li:before {
    content: "\\0025cf  "
}

ul.lst-kix_lxi3gt91oicl-2 {
    list-style-type: none
}

.lst-kix_rgww0z44sfp8-1>li:before {
    content: "\\0025cb  "
}

.lst-kix_q1cf02qjjtp6-2>li:before {
    content: "\\0025a0  "
}

.lst-kix_6lt3h7pagu6j-5>li:before {
    content: "\\0025a0  "
}

.lst-kix_mfbmnagqdfx4-8>li:before {
    content: "\\0025a0  "
}

.lst-kix_q1cf02qjjtp6-4>li:before {
    content: "\\0025cb  "
}

.lst-kix_6lt3h7pagu6j-3>li:before {
    content: "\\0025cf  "
}

ul.lst-kix_lxi3gt91oicl-7 {
    list-style-type: none
}

ul.lst-kix_lxi3gt91oicl-8 {
    list-style-type: none
}

.lst-kix_mfbmnagqdfx4-2>li:before {
    content: "\\0025a0  "
}

ul.lst-kix_lxi3gt91oicl-3 {
    list-style-type: none
}

ul.lst-kix_q1cf02qjjtp6-0 {
    list-style-type: none
}

ul.lst-kix_lxi3gt91oicl-4 {
    list-style-type: none
}

ul.lst-kix_lxi3gt91oicl-5 {
    list-style-type: none
}

.lst-kix_q1cf02qjjtp6-0>li:before {
    content: "\\0025cf  "
}

ul.lst-kix_q1cf02qjjtp6-2 {
    list-style-type: none
}

.lst-kix_6lt3h7pagu6j-7>li:before {
    content: "\\0025cb  "
}

ul.lst-kix_lxi3gt91oicl-6 {
    list-style-type: none
}

ul.lst-kix_q1cf02qjjtp6-1 {
    list-style-type: none
}

ul.lst-kix_q1cf02qjjtp6-4 {
    list-style-type: none
}

ul.lst-kix_q1cf02qjjtp6-3 {
    list-style-type: none
}

ul.lst-kix_q1cf02qjjtp6-6 {
    list-style-type: none
}

ul.lst-kix_q1cf02qjjtp6-5 {
    list-style-type: none
}

ul.lst-kix_q1cf02qjjtp6-8 {
    list-style-type: none
}

ul.lst-kix_q1cf02qjjtp6-7 {
    list-style-type: none
}

ul.lst-kix_kontqaofes6-7 {
    list-style-type: none
}

ul.lst-kix_kontqaofes6-6 {
    list-style-type: none
}

.lst-kix_mfbmnagqdfx4-0>li:before {
    content: "\\0025cf  "
}

ul.lst-kix_kontqaofes6-8 {
    list-style-type: none
}

ul.lst-kix_kontqaofes6-3 {
    list-style-type: none
}

ul.lst-kix_kontqaofes6-2 {
    list-style-type: none
}

ul.lst-kix_kontqaofes6-5 {
    list-style-type: none
}

ul.lst-kix_kontqaofes6-4 {
    list-style-type: none
}

.lst-kix_tsajsm1bwvxh-1>li:before {
    content: "\\0025cb  "
}

ul.lst-kix_tsajsm1bwvxh-1 {
    list-style-type: none
}

ul.lst-kix_tsajsm1bwvxh-2 {
    list-style-type: none
}

.lst-kix_tsajsm1bwvxh-0>li:before {
    content: "\\0025cf  "
}

.lst-kix_tsajsm1bwvxh-2>li:before {
    content: "\\0025a0  "
}

ul.lst-kix_tsajsm1bwvxh-0 {
    list-style-type: none
}

ul.lst-kix_tsajsm1bwvxh-5 {
    list-style-type: none
}

ul.lst-kix_tsajsm1bwvxh-6 {
    list-style-type: none
}

ul.lst-kix_tsajsm1bwvxh-3 {
    list-style-type: none
}

ul.lst-kix_tsajsm1bwvxh-4 {
    list-style-type: none
}

ul.lst-kix_11207x3aqkgi-8 {
    list-style-type: none
}

.lst-kix_tsajsm1bwvxh-5>li:before {
    content: "\\0025a0  "
}

ul.lst-kix_rgww0z44sfp8-0 {
    list-style-type: none
}

ul.lst-kix_rgww0z44sfp8-1 {
    list-style-type: none
}

.lst-kix_tsajsm1bwvxh-4>li:before {
    content: "\\0025cb  "
}

ul.lst-kix_tsajsm1bwvxh-7 {
    list-style-type: none
}

ul.lst-kix_rgww0z44sfp8-2 {
    list-style-type: none
}

ul.lst-kix_tsajsm1bwvxh-8 {
    list-style-type: none
}

ul.lst-kix_rgww0z44sfp8-3 {
    list-style-type: none
}

ul.lst-kix_svdi7m22koi-0 {
    list-style-type: none
}

.lst-kix_tsajsm1bwvxh-3>li:before {
    content: "\\0025cf  "
}

ul.lst-kix_rgww0z44sfp8-4 {
    list-style-type: none
}

ul.lst-kix_svdi7m22koi-1 {
    list-style-type: none
}

ul.lst-kix_rgww0z44sfp8-5 {
    list-style-type: none
}

ul.lst-kix_rgww0z44sfp8-6 {
    list-style-type: none
}

ul.lst-kix_svdi7m22koi-4 {
    list-style-type: none
}

ul.lst-kix_svdi7m22koi-5 {
    list-style-type: none
}

ul.lst-kix_svdi7m22koi-2 {
    list-style-type: none
}

ul.lst-kix_svdi7m22koi-3 {
    list-style-type: none
}

ul.lst-kix_svdi7m22koi-8 {
    list-style-type: none
}

ul.lst-kix_svdi7m22koi-6 {
    list-style-type: none
}

ul.lst-kix_svdi7m22koi-7 {
    list-style-type: none
}

ul.lst-kix_11207x3aqkgi-7 {
    list-style-type: none
}

ul.lst-kix_11207x3aqkgi-6 {
    list-style-type: none
}

ul.lst-kix_11207x3aqkgi-5 {
    list-style-type: none
}

ul.lst-kix_11207x3aqkgi-4 {
    list-style-type: none
}

ul.lst-kix_11207x3aqkgi-3 {
    list-style-type: none
}

ul.lst-kix_11207x3aqkgi-2 {
    list-style-type: none
}

ul.lst-kix_11207x3aqkgi-1 {
    list-style-type: none
}

ul.lst-kix_11207x3aqkgi-0 {
    list-style-type: none
}

ul.lst-kix_rgww0z44sfp8-7 {
    list-style-type: none
}

ul.lst-kix_rgww0z44sfp8-8 {
    list-style-type: none
}

.lst-kix_tsajsm1bwvxh-8>li:before {
    content: "\\0025a0  "
}

.lst-kix_tsajsm1bwvxh-6>li:before {
    content: "\\0025cf  "
}

.lst-kix_tsajsm1bwvxh-7>li:before {
    content: "\\0025cb  "
}

ul.lst-kix_bp7r3i8fxc7b-0 {
    list-style-type: none
}

ul.lst-kix_bp7r3i8fxc7b-1 {
    list-style-type: none
}

ul.lst-kix_bp7r3i8fxc7b-2 {
    list-style-type: none
}

ul.lst-kix_bp7r3i8fxc7b-3 {
    list-style-type: none
}

ul.lst-kix_bp7r3i8fxc7b-4 {
    list-style-type: none
}

ul.lst-kix_bp7r3i8fxc7b-5 {
    list-style-type: none
}

ul.lst-kix_bp7r3i8fxc7b-6 {
    list-style-type: none
}

ul.lst-kix_bp7r3i8fxc7b-7 {
    list-style-type: none
}

ul.lst-kix_bp7r3i8fxc7b-8 {
    list-style-type: none
}

.lst-kix_y4ol7ohcve5x-0>li:before {
    content: "\\0025cf  "
}

.lst-kix_y4ol7ohcve5x-7>li:before {
    content: "\\0025cb  "
}

.lst-kix_y4ol7ohcve5x-6>li:before {
    content: "\\0025cf  "
}

.lst-kix_y4ol7ohcve5x-8>li:before {
    content: "\\0025a0  "
}

.lst-kix_y4ol7ohcve5x-2>li:before {
    content: "\\0025a0  "
}

.lst-kix_y4ol7ohcve5x-1>li:before {
    content: "\\0025cb  "
}

.lst-kix_y4ol7ohcve5x-3>li:before {
    content: "\\0025cf  "
}

.lst-kix_y4ol7ohcve5x-4>li:before {
    content: "\\0025cb  "
}

.lst-kix_y4ol7ohcve5x-5>li:before {
    content: "\\0025a0  "
}

.lst-kix_bp7r3i8fxc7b-1>li:before {
    content: "\\0025cb  "
}

.lst-kix_bp7r3i8fxc7b-5>li:before {
    content: "\\0025a0  "
}

.lst-kix_svdi7m22koi-7>li:before {
    content: "\\0025cb  "
}

.lst-kix_bp7r3i8fxc7b-3>li:before {
    content: "\\0025cf  "
}

ul.lst-kix_y4ol7ohcve5x-3 {
    list-style-type: none
}

ul.lst-kix_y4ol7ohcve5x-2 {
    list-style-type: none
}

ul.lst-kix_y4ol7ohcve5x-1 {
    list-style-type: none
}

.lst-kix_svdi7m22koi-1>li:before {
    content: "\\0025cb  "
}

.lst-kix_svdi7m22koi-3>li:before {
    content: "\\0025cf  "
}

ul.lst-kix_y4ol7ohcve5x-0 {
    list-style-type: none
}

.lst-kix_11207x3aqkgi-8>li:before {
    content: "\\0025a0  "
}

.lst-kix_svdi7m22koi-5>li:before {
    content: "\\0025a0  "
}

.lst-kix_q1cf02qjjtp6-7>li:before {
    content: "\\0025cb  "
}

.lst-kix_lxi3gt91oicl-8>li:before {
    content: "\\0025a0  "
}

.lst-kix_lxi3gt91oicl-6>li:before {
    content: "\\0025cf  "
}

.lst-kix_kontqaofes6-1>li:before {
    content: "\\0025cb  "
}

.lst-kix_gq1x30hx4nyl-5>li:before {
    content: "\\0025a0  "
}

.lst-kix_rgww0z44sfp8-4>li:before {
    content: "\\0025cb  "
}

.lst-kix_kontqaofes6-3>li:before {
    content: "\\0025cf  "
}

.lst-kix_gq1x30hx4nyl-7>li:before {
    content: "\\0025cb  "
}

.lst-kix_lxi3gt91oicl-2>li:before {
    content: "\\0025a0  "
}

.lst-kix_kontqaofes6-5>li:before {
    content: "\\0025a0  "
}

.lst-kix_rgww0z44sfp8-6>li:before {
    content: "\\0025cf  "
}

.lst-kix_lxi3gt91oicl-4>li:before {
    content: "\\0025cb  "
}

.lst-kix_rgww0z44sfp8-8>li:before {
    content: "\\0025a0  "
}

.lst-kix_bp7r3i8fxc7b-7>li:before {
    content: "\\0025cb  "
}

.lst-kix_kontqaofes6-7>li:before {
    content: "\\0025cb  "
}

.lst-kix_6lt3h7pagu6j-0>li:before {
    content: "\\0025cf  "
}

.lst-kix_rgww0z44sfp8-2>li:before {
    content: "\\0025a0  "
}

.lst-kix_mfbmnagqdfx4-5>li:before {
    content: "\\0025a0  "
}

.lst-kix_lxi3gt91oicl-0>li:before {
    content: "\\0025cf  "
}

.lst-kix_6lt3h7pagu6j-4>li:before {
    content: "\\0025cb  "
}

.lst-kix_6lt3h7pagu6j-6>li:before {
    content: "\\0025cf  "
}

.lst-kix_q1cf02qjjtp6-3>li:before {
    content: "\\0025cf  "
}

.lst-kix_6lt3h7pagu6j-2>li:before {
    content: "\\0025a0  "
}

.lst-kix_mfbmnagqdfx4-7>li:before {
    content: "\\0025cb  "
}

.lst-kix_q1cf02qjjtp6-5>li:before {
    content: "\\0025a0  "
}

.lst-kix_rgww0z44sfp8-0>li:before {
    content: "\\0025cf  "
}

.lst-kix_mfbmnagqdfx4-1>li:before {
    content: "\\0025cb  "
}

.lst-kix_6lt3h7pagu6j-8>li:before {
    content: "\\0025a0  "
}

.lst-kix_mfbmnagqdfx4-3>li:before {
    content: "\\0025cf  "
}

.lst-kix_q1cf02qjjtp6-1>li:before {
    content: "\\0025cb  "
}

li.li-bullet-0:before {
    margin-left: -18px;
    white-space: nowrap;
    display: inline-block;
    min-width: 18px
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c10 {
    border-right-style: solid;
    padding: 12px 12px 12px 12px;
    border-bottom-color: #e3e8ee;
    border-top-width: 1px;
    border-right-width: 0px;
    border-left-color: #e3e8ee;
    vertical-align: top;
    border-right-color: #e3e8ee;
    border-left-width: 0px;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1px;
    width: 136.8px;
    border-top-color: #e3e8ee;
    border-bottom-style: solid
}

.c18 {
    border-right-style: solid;
    padding: 0px 0px 6px 12px;
    border-bottom-color: #e3e8ee;
    border-top-width: 0px;
    border-right-width: 0px;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0px;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1px;
    width: 133.5px;
    border-top-color: #000000;
    border-bottom-style: solid
}

.c27 {
    border-right-style: solid;
    padding: 0px 0px 6px 12px;
    border-bottom-color: #e3e8ee;
    border-top-width: 0px;
    border-right-width: 0px;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0px;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1px;
    width: 89.2px;
    border-top-color: #000000;
    border-bottom-style: solid
}

.c11 {
    border-right-style: solid;
    padding: 12px 12px 12px 12px;
    border-bottom-color: #e3e8ee;
    border-top-width: 1px;
    border-right-width: 0px;
    border-left-color: #e3e8ee;
    vertical-align: top;
    border-right-color: #e3e8ee;
    border-left-width: 1px;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1px;
    width: 91.8px;
    border-top-color: #e3e8ee;
    border-bottom-style: solid
}

.c12 {
    border-right-style: solid;
    padding: 12px 12px 12px 12px;
    border-bottom-color: #e3e8ee;
    border-top-width: 1px;
    border-right-width: 1px;
    border-left-color: #e3e8ee;
    vertical-align: top;
    border-right-color: #e3e8ee;
    border-left-width: 0px;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1px;
    width: 89.2px;
    border-top-color: #e3e8ee;
    border-bottom-style: solid
}

.c13 {
    border-right-style: solid;
    padding: 12px 12px 12px 12px;
    border-bottom-color: #e3e8ee;
    border-top-width: 1px;
    border-right-width: 0px;
    border-left-color: #e3e8ee;
    vertical-align: top;
    border-right-color: #e3e8ee;
    border-left-width: 0px;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1px;
    width: 133.5px;
    border-top-color: #e3e8ee;
    border-bottom-style: solid
}

.c26 {
    border-right-style: solid;
    padding: 0px 0px 6px 12px;
    border-bottom-color: #e3e8ee;
    border-top-width: 0px;
    border-right-width: 0px;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0px;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1px;
    width: 91.8px;
    border-top-color: #000000;
    border-bottom-style: solid
}

.c15 {
    border-right-style: solid;
    padding: 0px 0px 6px 12px;
    border-bottom-color: #e3e8ee;
    border-top-width: 0px;
    border-right-width: 0px;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0px;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1px;
    width: 136.8px;
    border-top-color: #000000;
    border-bottom-style: solid
}

.c5 {
    margin-left: 36px;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
    line-height: 2.15;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c6 {
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c20 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11px;
    font-family: "Arial";
    font-style: normal
}

.c0 {
    color: #3a4041;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13px;
    font-family: "Avenir";
    font-style: normal
}

.c19 {
    color: #3a4041;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 20px;
    font-family: "Avenir";
    font-style: normal
}

.c3 {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 11px
}

.c28 {
    padding-top: 0px;
    padding-bottom: 16px;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c8 {
    color: #3a4041;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13px;
    font-family: "Avenir";
    font-style: normal
}

.c25 {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11px
}

.c22 {
    padding-top: 0px;
    padding-bottom: 3px;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c4 {
    padding-top: 15px;
    padding-bottom: 0px;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c9 {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c7 {
    padding-top: 15px;
    padding-bottom: 0px;
    line-height: 1.4285714285714286;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c14 {
    width: 85%;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto
}

.c17 {
    font-size: 13px;
    font-family: "Avenir";
    color: #3a4041;
    font-weight: 400
}

.c16 {
    background-color: #ffffff;
}

.c21 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    text-decoration-skip-ink: none
}

.c2 {
    padding: 0;
    margin: 0
}

.c23 {
    color: inherit;
    text-decoration: inherit
}

.c24 {
    height: 17.2px
}

.c1 {
    height: 55.5px
}

.title {
    padding-top: 0px;
    color: #000000;
    font-size: 26px;
    padding-bottom: 3px;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0px;
    color: #666666;
    font-size: 12px;
    padding-bottom: 0px;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

p {
    margin: 0;
}
`;
