import type { FC } from 'react';
import { useRightDrawerStore } from '@stores/right-drawer.store';
import { Header, StyledDrawer } from '@components/core/RightDrawer/RightDrawer.style';
import { CloseIcon } from '@assets/icons';
import { IconButton } from '@components/core/IconButton/IconButton';
import { Typography } from '@components/core/Typography/Typography';
import { useMediaQueries } from '@hooks/uesMediaQueries';

export const RightDrawer: FC = () => {
  const { isMobile } = useMediaQueries();
  const { drawer, opened, hideRightDrawer } = useRightDrawerStore();

  return (
    <StyledDrawer
      variant='temporary'
      anchor='right'
      open={opened}
      onClose={hideRightDrawer}
      data-testid={`ev-right-drawer-${drawer?.name}`}
      isMobile={isMobile}
      width={drawer?.width}
    >
      <Header>
        {drawer?.title && (
          <Typography translationKey={drawer.title} variant='h3' marginBottom={0} whiteSpace='pre-wrap' marginRight={2} maxWidth={500} />
        )}
        <IconButton name='right-drawer-hide' onClick={hideRightDrawer}>
          <CloseIcon />
        </IconButton>
      </Header>
      {drawer?.content}
    </StyledDrawer>
  );
};
