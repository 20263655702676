import { createRoot } from 'react-dom/client';
import { AppContainer } from './AppContainer';
import 'unfonts.css';
import React from 'react';

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <AppContainer />,
  </React.StrictMode>,
);
