import type { Props as TooltipProps } from '@components/core/Tooltip/Tooltip';
import { Tooltip } from '@components/core/Tooltip/Tooltip';
import type { FC } from 'react';
import { forwardRef } from 'react';
import { Color } from '@shared/styles/color.enum';
import { InfoIconContainer, LearnMoreLink } from '@components/core/Tooltip/variants/InfoTooltip/InfoTooltip.style';
import { InfoIcon } from '@assets/icons';
import { useTranslation } from 'react-i18next';
import type { InfoTooltipExternalUrlType } from '@components/core/Tooltip/variants/InfoTooltip/InfoTooltip.config';
import { InfoTooltipLearnMoreUrls } from '@components/core/Tooltip/variants/InfoTooltip/InfoTooltip.config';

export interface Props extends Omit<TooltipProps, 'children'> {
  hasFocus?: boolean;
  externalInfoUrl?: InfoTooltipExternalUrlType;
}

const WrappedIcon = forwardRef<HTMLDivElement, { color: TooltipProps['color']; hasFocus?: boolean }>(({ color, hasFocus, ...props }, ref) => {
  const stroke: Color = (() => {
    if (hasFocus) {
      return Color.ElectricGreen;
    }

    return color === 'secondary' ? Color.SuccessBackground : Color.SeaGreen;
  })();

  return (
    <InfoIconContainer {...props} ref={ref} data-testid='ev-info-tooltip-icon'>
      <InfoIcon stroke={stroke} width={18} height={18} />
    </InfoIconContainer>
  );
});

WrappedIcon.displayName = 'InfoTooltipIcon';

export const InfoTooltip: FC<Props> = ({ title, titleTranslationKey, externalInfoUrl, hasFocus, ...tooltipProps }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      PopperProps={{ 'data-testid': `ev-tooltip-${title}` }}
      title={
        <>
          {titleTranslationKey && <span>{t(titleTranslationKey)}</span>}
          {title}
          {externalInfoUrl && (
            <LearnMoreLink href={InfoTooltipLearnMoreUrls[externalInfoUrl]} rel='noopener noreferrer' target='_blank'>
              {` ${t('description.learn-more')}`}
            </LearnMoreLink>
          )}
        </>
      }
      {...tooltipProps}
    >
      <WrappedIcon color={tooltipProps.color} hasFocus={hasFocus} />
    </Tooltip>
  );
};
