import { UserPermission, useUserPermissions } from '@hooks/auth/useUserPermissions';
import { RoutePath } from '@shared/constants/routing.constants';
import { Navigate } from 'react-router-dom';
import type { NavigationDrawerItemId } from '@components/layouts/DashboardLayout/NavigationDrawer/NavigationDrawer.config';
import { NavigationDrawerTopSections } from '@components/layouts/DashboardLayout/NavigationDrawer/NavigationDrawer.config';

const [navSection] = NavigationDrawerTopSections;
const SECTION_PERMISSION_MAP: Record<NavigationDrawerItemId, UserPermission> = {
  chargers: UserPermission.VIEW_CHARGERS,
  fleets: UserPermission.VIEW_FLEETS,
  guide: UserPermission.VIEW_GUIDE,
  organisations: UserPermission.VIEW_ORGANISATIONS,
  'release-notes': UserPermission.VIEW_RELEASE_NOTES,
  settings: UserPermission.VIEW_SETTINGS,
  sites: UserPermission.VIEW_SITES,
  users: UserPermission.VIEW_USERS,
  vehicles: UserPermission.VIEW_VEHICLES,
};

export const DashboardBase = () => {
  const permissions = useUserPermissions();
  if (!permissions) {
    return null;
  }

  const firstVisibleSection = navSection.items.find(i => permissions[SECTION_PERMISSION_MAP[i.id]]);

  return <Navigate to={firstVisibleSection?.path || RoutePath.DashboardUsers} />;
};
