import { cleanEnv, str, url, bool } from 'envalid';
import { Language } from '@i18n/language.enum';
import { Environment } from '@shared/types/enums/environment.enum';
import { config as dotenvConfig } from 'dotenv';

// FIX - using swc + ts-node needs env variables to be loaded https://stackoverflow.com/questions/76669166/how-to-use-dotenv-with-ts-node-and-swc
const shouldLoadEnvsForSwc = process.env.LOAD_ENVS_FOR_SWC === 'true';
if (shouldLoadEnvsForSwc) {
  dotenvConfig({ path: `${process.cwd()}/.env.development` });
}

interface ProcessEnvVariables {
  npm_package_version: string;
  VITE_API_URL: string;
  VITE_BASE_URL: string;
  VITE_CHARGER_TESTING_PLATFORM_WEBSOCKET_URL: string;
  VITE_AUTH_CLIENT_ID: string;
  VITE_AUTH_CLIENT_SECRET: string;
  VITE_AUTH_SUNPOWER_SSO_URL: string;
  VITE_AUTH_GOOGLE_CLIENT_ID: string;
  VITE_AUTH_GOOGLE_URL: string;
  VITE_AUTH_APPLE_CLIENT_ID: string;
  VITE_AUTH_APPLE_URL: string;
  VITE_AUTH_MICROSOFT_CLIENT_ID: string;
  VITE_AUTH_MICROSOFT_URL: string;
  VITE_INSTANCE_NAME: Environment;
  VITE_DEBUG_ENABLED: boolean;
  VITE_LANGUAGE: Language;
  VITE_LOGGING_ENABLED: boolean;
  VITE_LOGGING_SENTRY_DSN?: string;
  VITE_COVERAGE: boolean;
  VITE_HOTJAR_ENABLED: boolean;
  VITE_HOTJAR_SITE_ID?: string;
  VITE_HOTJAR_VERSION?: string;
  VITE_PANDO_GUIDE_URL: string;
  VITE_RUDDERSTACK_API_KEY: string;
  VITE_RUDDERSTACK_PLANE_URL: string;
}

// object needs to be defined to properly inject env variables in production build
const processEnvs: Record<keyof ProcessEnvVariables, string | undefined> = {
  VITE_API_URL: process.env.VITE_API_URL,
  VITE_BASE_URL: process.env.VITE_BASE_URL,
  VITE_CHARGER_TESTING_PLATFORM_WEBSOCKET_URL: process.env.VITE_CHARGER_TESTING_PLATFORM_WEBSOCKET_URL,
  VITE_AUTH_CLIENT_ID: process.env.VITE_AUTH_CLIENT_ID,
  VITE_AUTH_CLIENT_SECRET: process.env.VITE_AUTH_CLIENT_SECRET,
  VITE_AUTH_SUNPOWER_SSO_URL: process.env.VITE_AUTH_SUNPOWER_SSO_URL,
  VITE_AUTH_GOOGLE_CLIENT_ID: process.env.VITE_AUTH_GOOGLE_CLIENT_ID,
  VITE_AUTH_GOOGLE_URL: process.env.VITE_AUTH_GOOGLE_URL,
  VITE_AUTH_APPLE_CLIENT_ID: process.env.VITE_AUTH_APPLE_CLIENT_ID,
  VITE_AUTH_APPLE_URL: process.env.VITE_AUTH_APPLE_URL,
  VITE_AUTH_MICROSOFT_CLIENT_ID: process.env.VITE_AUTH_MICROSOFT_CLIENT_ID,
  VITE_AUTH_MICROSOFT_URL: process.env.VITE_AUTH_MICROSOFT_URL,
  VITE_INSTANCE_NAME: process.env.VITE_INSTANCE_NAME,
  VITE_DEBUG_ENABLED: process.env.VITE_DEBUG_ENABLED,
  VITE_LANGUAGE: process.env.VITE_LANGUAGE,
  VITE_LOGGING_ENABLED: process.env.VITE_LOGGING_ENABLED,
  VITE_LOGGING_SENTRY_DSN: process.env.VITE_LOGGING_SENTRY_DSN,
  npm_package_version: process.env.npm_package_version,
  VITE_COVERAGE: process.env.VITE_COVERAGE,
  VITE_HOTJAR_ENABLED: process.env.VITE_HOTJAR_ENABLED,
  VITE_HOTJAR_SITE_ID: process.env.VITE_HOTJAR_SITE_ID,
  VITE_HOTJAR_VERSION: process.env.VITE_HOTJAR_VERSION,
  VITE_PANDO_GUIDE_URL: process.env.VITE_PANDO_GUIDE_URL,
  VITE_RUDDERSTACK_API_KEY: process.env.VITE_RUDDERSTACK_API_KEY,
  VITE_RUDDERSTACK_PLANE_URL: process.env.VITE_RUDDERSTACK_PLANE_URL,
};

const envs: ProcessEnvVariables = cleanEnv(processEnvs, {
  VITE_API_URL: url(),
  VITE_BASE_URL: url(),
  VITE_CHARGER_TESTING_PLATFORM_WEBSOCKET_URL: str(),
  VITE_AUTH_CLIENT_ID: str(),
  VITE_AUTH_CLIENT_SECRET: str(),
  VITE_AUTH_SUNPOWER_SSO_URL: str(),
  VITE_AUTH_GOOGLE_CLIENT_ID: str(),
  VITE_AUTH_GOOGLE_URL: url(),
  VITE_AUTH_APPLE_CLIENT_ID: str(),
  VITE_AUTH_APPLE_URL: url(),
  VITE_AUTH_MICROSOFT_CLIENT_ID: str(),
  VITE_AUTH_MICROSOFT_URL: url(),
  VITE_INSTANCE_NAME: str({ choices: Object.values(Environment) }),
  VITE_DEBUG_ENABLED: bool({ default: false }),
  VITE_LANGUAGE: str({ choices: Object.values(Language) }),
  VITE_LOGGING_SENTRY_DSN: url({ default: undefined }),
  VITE_LOGGING_ENABLED: bool({ default: false }),
  npm_package_version: str(),
  VITE_COVERAGE: bool(),
  VITE_HOTJAR_ENABLED: bool(),
  VITE_HOTJAR_SITE_ID: str({ default: undefined }),
  VITE_HOTJAR_VERSION: str({ default: undefined }),
  VITE_PANDO_GUIDE_URL: str(),
  VITE_RUDDERSTACK_API_KEY: str({ default: ' ' }),
  VITE_RUDDERSTACK_PLANE_URL: str({ default: ' ' }),
});

export interface Config {
  analytics: {
    hotjar: {
      enabled: boolean;
      siteId?: number;
      version?: number;
    };
  };
  auth: {
    clientId: string;
    clientSecret: string;
    sunpowerSsoUrl: URL;
    googleClientId: string;
    googleAuthUrl: URL;
    appleClientId: string;
    appleAuthUrl: URL;
    microsoftClientId: string;
    microsoftAuthUrl: URL;
  };
  apiUrl: URL;
  baseUrl: URL;
  chargerTestingPlatformWebsocketUrl: URL;
  instanceName: Environment;
  isDebug: boolean;
  isDev: boolean;
  isStaging: boolean;
  language: Language;
  logging: {
    enabled: boolean;
    sentryDsn?: string;
  };
  rudderStack: {
    apiKey: string;
    planeUrl: string;
  };
  pandoGuideUrl: string;
  version: string;
}

export const config: Config = {
  analytics: {
    hotjar: {
      enabled: envs.VITE_HOTJAR_ENABLED,
      siteId: envs.VITE_HOTJAR_SITE_ID ? +envs.VITE_HOTJAR_SITE_ID : undefined,
      version: envs.VITE_HOTJAR_VERSION ? +envs.VITE_HOTJAR_VERSION : undefined,
    },
  },
  auth: {
    clientId: envs.VITE_AUTH_CLIENT_ID,
    clientSecret: envs.VITE_AUTH_CLIENT_SECRET,
    sunpowerSsoUrl: new URL(envs.VITE_AUTH_SUNPOWER_SSO_URL),
    googleClientId: envs.VITE_AUTH_GOOGLE_CLIENT_ID,
    googleAuthUrl: new URL(envs.VITE_AUTH_GOOGLE_URL),
    appleClientId: envs.VITE_AUTH_APPLE_CLIENT_ID,
    appleAuthUrl: new URL(envs.VITE_AUTH_APPLE_URL),
    microsoftClientId: envs.VITE_AUTH_MICROSOFT_CLIENT_ID,
    microsoftAuthUrl: new URL(envs.VITE_AUTH_MICROSOFT_URL),
  },
  apiUrl: new URL(envs.VITE_API_URL),
  baseUrl: new URL(envs.VITE_BASE_URL),
  chargerTestingPlatformWebsocketUrl: new URL(envs.VITE_CHARGER_TESTING_PLATFORM_WEBSOCKET_URL),
  instanceName: envs.VITE_INSTANCE_NAME,
  isDebug: [Environment.Development, Environment.Test].includes(envs.VITE_INSTANCE_NAME) && envs.VITE_DEBUG_ENABLED,
  isDev: envs.VITE_INSTANCE_NAME === Environment.Development,
  isStaging: envs.VITE_INSTANCE_NAME === Environment.Staging,
  language: envs.VITE_LANGUAGE,
  logging: {
    enabled: envs.VITE_LOGGING_ENABLED,
    sentryDsn: envs.VITE_LOGGING_SENTRY_DSN,
  },
  rudderStack: {
    apiKey: envs.VITE_RUDDERSTACK_API_KEY,
    planeUrl: envs.VITE_RUDDERSTACK_PLANE_URL,
  },
  pandoGuideUrl: envs.VITE_PANDO_GUIDE_URL,
  version: envs.npm_package_version,
};
